import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    loaded: false,
    loading: false,
    survey: [],
  },
  reducers: {
    setSurvey: (state, action) => {
      state.survey = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  setSurvey,
  startLoad,
} = surveySlice.actions;

export const getSurveyAsync = (type) => async (dispatch) => {
  dispatch(startLoad());
  const res = await http.get(`/api/survey/${type}`);
  dispatch(setSurvey(res.data));
};

export const selectLoaded = (state) => state.survey.loaded;

export const selectLoading = (state) => state.survey.loading;

export const selectSurvey = (state) => state.survey.survey;

export default surveySlice.reducer;
