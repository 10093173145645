import { Handle, Position, useStore } from 'reactflow';

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const spaced = (name) => {
  return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

export default function CustomNode({ id, data }) {

  // console.log('data:', data);

  const connectionNodeId = useStore(connectionNodeIdSelector);

  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;
  let type;
  if (isTarget) {
    type = 'Drop here';
  } else if (data?.type) {
    type = spaced(data.type);
  } else {
    type = 'Drag to connect';
  }

  let backgroundColor, borderStyle, color;
  if (isTarget || !data?.type) {
    backgroundColor = '#ffcce3';
    borderStyle = 'dashed';
    color = 'rgba(60, 61, 65, 0.88)';
  } else {
    borderStyle = 'solid';
    color = 'rgba(60, 61, 65, 0.88)';
  }

  return (
    <div className="customNode">
      <div className="nodeDragHandle">
        <svg viewBox="0 0 20 20" width="10">
          <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
        </svg>
      </div>
      <div
        className="customNodeBody"
        style={{ backgroundColor, borderStyle, color }}
      >
        {/* If handles are conditionally rendered and not present initially, you need to update the node internals https://reactflow.dev/docs/api/hooks/use-update-node-internals/ */}
        {/* In this case we don't need to use useUpdateNodeInternals, since !isConnecting is true at the beginning and all handles are rendered initially. */}
        {!isConnecting && (
          <Handle className="customHandle" position={Position.Right} type="source" />
        )}

        <Handle
          className="customHandle"
          position={Position.Left}
          type="target"
          isConnectableStart={false}
        />
        <div className="nodeLabel">{type}</div>
      </div>
    </div>
  );
}
