import React from 'react';
import Icon from '@ant-design/icons';

import accessControl from '../../images/graph/access-control.png';
import approval from '../../images/graph/approval.png';
import blocks from '../../images/graph/blocks.png';
import businessAnalysis from '../../images/graph/business-analysis.png';
import chunk from '../../images/graph/chunk.png';
import compliance from '../../images/graph/compliance.png';
import crisisManagement from '../../images/graph/crisis-management.png';
import document from '../../images/graph/document.png';
import hierarchy from '../../images/graph/hierarchy.png';
import loopingArrows from '../../images/graph/looping-arrows.png';
import organization from '../../images/graph/organization.png';
import qualifications from '../../images/graph/qualifications.png';
import regulatoryCompliance from '../../images/graph/regulatory-compliance.png';
import risk from '../../images/graph/risk.png';
import risk1 from '../../images/graph/risk1.png';
import target from '../../images/graph/target.png';
import taskList from '../../images/graph/task-list.png';
import todoList from '../../images/graph/to-do-list.png';
import userEngagement from '../../images/graph/user-engagement.png';
import webSettings from '../../images/graph/web-settings.png';

export const images = {
  accesControl: {
    label: 'Access Control',
    image: accessControl,
  },
  approval: {
    label: 'Approval',
    image: approval,
  },
  blocks: {
    label: 'Blocks',
    image: blocks,
  },
  businessAnalysis: {
    label: 'Business Analysis',
    image: businessAnalysis,
  },
  chunk: {
    label: 'Chunk',
    image: chunk,
  },
  compliance: {
    label: 'Compliance',
    image: compliance,
  },
  crisisManagement: {
    label: 'Crisis Management',
    image: crisisManagement,
  },
  document: {
    label: 'Document',
    image: document,
  },
  hierarchy: {
    label: 'Hierarchy',
    image: hierarchy,
  },
  loopingArrows: {
    label: 'Looping Arrows',
    image: loopingArrows,
  },
  organization: {
    label: 'Organization',
    image: organization,
  },
  qualifications: {
    label: 'Qualifications',
    image: qualifications,
  },
  regulatoryCompliance: {
    label: 'Regulatory Compliance',
    image: regulatoryCompliance,
  },
  risk1: {
    label: 'Risk 1',
    image: risk,
  },
  risk2: {
    label: 'Risk 2',
    image: risk1,
  },
  target: {
    label: 'Target',
    image: target,
  },
  taskList: {
    label: 'Task List',
    image: taskList,
  },
  todoList: {
    label: 'Todo List',
    image: todoList,
  },
  userEngagement: {
    label: 'User Enagement',
    image: userEngagement,
  },
  webSettings: {
    label: 'Web Settings',
    image: webSettings,
  },
};

export function MyIcon({ type, size = 24 }) {
  const im = images[type];
  if (!im) {
    return null;
  }
  return (
    <Icon
      component={() => <img src={im.image} />}
      style={{ width: size, height: size }}
    />
  );
}

export function MyImage({ type }) {
  const im = images[type];
  if (!im) {
    return null;
  }
  return im.image;
}
