import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const inferenceRunsSlice = createSlice({
  name: 'inferenceRuns',
  initialState: {
    loaded: false,
    loading: false,
    inferenceRuns: {},
  },
  reducers: {
    removeInferenceRuns: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.inferenceRuns[id];
      }
    },
    resetInferenceRuns: (state) => {
      state.inferenceRuns = {};
    },
    setInferenceRuns: (state, action) => {
      for (const run of action.payload.inferenceRuns) {
        state.inferenceRuns[run.id] = run;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeInferenceRuns,
  resetInferenceRuns,
  setInferenceRuns,
  startLoad,
} = inferenceRunsSlice.actions;

export const getInferenceRunsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/inference-runs?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setInferenceRuns({ inferenceRuns: res.data }));
};

export const getInferenceRunAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/inference-runs/${id}`;
  const res = await http.get(url);
  dispatch(setInferenceRuns({ inferenceRuns: [res.data] }));
};

export const createInferenceRunAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/inference-runs';
  const res = await http.post(url, values);
  dispatch(setInferenceRuns({ inferenceRuns: [{ ...res.data, correlationId }] }));
};

export const updateInferenceRunAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/inference-runs/${id}`;
  const res = await http.put(url, values);
  dispatch(setInferenceRuns({ inferenceRuns: [{ ...res.data, correlationId }] }));
};

export const deleteInferenceRunsAsync = (ids) => async (dispatch) => {
  const url = `/api/inference-runs?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeInferenceRuns({ ids }));
};

export const runInferenceAsync = ({
  activity,
  clientId,
  key,
  nodeLabel,
  textField,
  setting,
  workspaceId,
  domain,
}) => async (dispatch) => {
  let url;
  if (activity === 'create-syllabus') {
    url = '/api/activities/create-syllabus';
  } else if (activity === 'extract-best-topic') {
    url = '/api/activities/extract-best-topic';
  } else if (activity === 'extract-business-requirements') {
    url = '/api/activities/extract-business-requirements';
  } else if (activity === 'extract-compliance-arrangements') {
    url = '/api/activities/extract-compliance-arrangements';
  } else if (activity === 'extract-compliance-classifications') {
    url = '/api/activities/extract-compliance-classifications';
  } else if (activity === 'extract-compliance-topics') {
    url = '/api/activities/extract-compliance-topics';
  } else if (activity === 'extract-industries') {
    url = '/api/activities/extract-industries';
  } else if (activity === 'extract-obligations') {
    url = '/api/activities/extract-obligations';
  } else if (activity === 'extract-summaries') {
    url = '/api/activities/summarize';
  } else if (activity === 'extract-thesis-statement') {
    url = '/api/education-activities/extract-thesis-statement';
  } else if (activity === 'extract-topics') {
    url = '/api/activities/extract-topics';
  } else {
    throw new Error(`Invalid activity: ${activity}`);
  }
  await http.post(url, {
    nodeLabel,
    nodeType: nodeLabel,
    textField,
    setting,
    workspaceId,
    domain,
    clientId,
    key,
  });
}

export const selectInferenceRuns = (state) => state.inferenceRuns.inferenceRuns;

export const selectLoaded = (state) => state.inferenceRuns.loaded;

export const selectLoading = (state) => state.inferenceRuns.loading;

export default inferenceRunsSlice.reducer;
