import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Drawer,
  Flex,
  Form,
  Modal,
  Tooltip,
} from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';
import ReactFlow, { Controls, addEdge, useNodesState, useEdgesState, MarkerType } from 'reactflow';
import cloneDeep from 'lodash.clonedeep';

import CustomNode from '../features/ontology/CustomNode';
import FloatingEdge from '../features/ontology/FloatingEdge';
import CustomConnectionLine from '../features/ontology/CustomConnectionLine';

import 'reactflow/dist/style.css';
import '../features/ontology/style.css';

const subFieldLayout = {
  colon: false,
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const connectionLineStyle = {
  strokeWidth: 1,
  stroke: 'black',
};

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

const defaultEdgeOptions = {
  style: { strokeWidth: 1, stroke: 'black' },
  type: 'floating',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: 'black',
  },
};

export default function OntologyViewModal({ ontology, onCancel, open }) {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSchemaEdge, setSelectedSchemaEdge] = useState(null);
  const [selectedSchemaNode, setSelectedSchemaNode] = useState(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const navigate = useNavigate();
  const [nodeForm] = Form.useForm();

  const propsValue = Form.useWatch('properties', nodeForm);

  const deserializeData = (obj) => {
    const clone = cloneDeep(obj);
    const { data, ...rest } = clone;
    if (data.synonyms) {
      data.synonyms = data.synonyms.join(', ');
      if (!data.synonyms.length) {
        data.synonyms = undefined;
      }
    } else {
      data.synonyms = undefined;
    }
    if (data.properties) {
      const properties = [];
      for (let prop of data.properties) {
        if (prop.dataType === 'enum' || prop.dataType === 'list_enum') {
          if (prop.enum) {
            prop.enum = prop.enum.join(', ');
          } else {
            prop.enum = undefined;
          }
        }
        properties.push(prop);
      }
      if (properties.length) {
        data.properties = properties;
      } else {
        data.properties = undefined;
      }
    } else {
      data.properties = undefined;
    }
    return { ...rest, data };
  };

  useEffect(() => {
    if (ontology) {
      setNodes((ontology.nodes || []).map(deserializeData));
      const edges = (ontology.edges || [])
        .map(e => ({ ...e, data: { ...e.data, label: e.data?.type } }));
      setEdges(edges);
    }
  }, [ontology]);

  const handleSchemaNodeClick = (ev, node) => {
    nodeForm.resetFields();
    nodeForm.setFieldsValue(node.data);
    setSelectedSchemaNode(node);
    setDrawerOpen(true);
  };

  const handleSchemaEdgeClick = (ev, edge) => {
    nodeForm.resetFields();
    nodeForm.setFieldsValue(edge.data);
    setSelectedSchemaEdge(edge);
    setDrawerOpen(true);
  }

  const ReadOnlyField = ({ value }) => {
    return (
      <div style={{ fontStyle: 'italic', fontWeight: 600 }}>{value}</div>
    );
  };

  if (!ontology) {
    return null;
  }
  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
      onOk={onCancel}
      open={open}
      title={
        <Flex align="center" gap={8}>
          <div>Inspect Ontology</div>
          <Tooltip title="Edit Ontology">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => navigate(`/ontologies/${ontology.id}`)}
            />
          </Tooltip>
        </Flex>
      }
      width={1040}
    >
      <div style={{ background: '#FBFBFB', height: 600 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultEdgeOptions={defaultEdgeOptions}
          connectionLineComponent={CustomConnectionLine}
          connectionLineStyle={connectionLineStyle}
          onNodeClick={handleSchemaNodeClick}
          onEdgeClick={handleSchemaEdgeClick}
        >
          <Controls />
        </ReactFlow>
        <Drawer
          getContainer={false}
          mask={false}
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
          placement="right"
          style={{ overflowY: 'auto', padding: '10px 15px', borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }}
          title={selectedSchemaEdge ? 'Edge' : 'Node'}
          width={400}
        >
          {selectedSchemaEdge || selectedSchemaNode ?
            <Form
              autoComplete="off"
              form={nodeForm}
              layout="vertical"
            >
              <Form.Item
                label="Type"
                name="type"
              >
                <ReadOnlyField />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
              >
                <ReadOnlyField />
              </Form.Item>
              <Form.Item
                label="Synonyms"
                name="synonyms"
              >
                <ReadOnlyField />
              </Form.Item>
              <Form.Item
                label="Icon"
                name="icon"
              >
                <ReadOnlyField />
              </Form.Item>
              <div
                style={{ fontWeight: 600, marginBottom: 8 }}
              >
                Properties
              </div>
              <Form.Item>
                <Form.List name="properties">
                  {(fields) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item key={field.name}
                          style={{ marginBottom: 0 }}
                        >
                          <Form.Item
                            {...subFieldLayout}
                            label={index === 0 ? 'Property' : ''}
                            name={[field.name, 'property']}
                            style={{ display: 'inline-block', width: 'calc(50% - 20px)', marginBottom: 0 }}
                          >
                            <ReadOnlyField />
                          </Form.Item>
                          <Form.Item
                            {...subFieldLayout}
                            label={index === 0 ? 'Data Type' : ''}
                            name={[field.name, 'dataType']}
                            style={{ display: 'inline-block', width: 'calc(50% - 20px)', marginBottom: 0, marginLeft: 8 }}
                          >
                            <ReadOnlyField />
                          </Form.Item>
                          {propsValue?.[index]?.dataType === 'enum' || propsValue?.[index]?.dataType === 'list_enum' ?
                            <Form.Item
                              name={[field.name, 'enum']}
                              style={{ display: 'inline-block', marginBottom: 0, marginTop: 8, width: 'calc(100% - 32px)' }}
                            >
                              <ReadOnlyField />
                            </Form.Item>
                            : null
                          }
                        </Form.Item>
                      ))}
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Form>
            : null
          }
        </Drawer>
      </div>
    </Modal>
  );
}