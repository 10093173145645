import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Flex, Form, Rate, Segmented, Space, Table, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import NavbarContext from '../../contexts/NavbarContext';
import UserContext from '../../contexts/UserContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  createAssessmentAsync,
  getAssessmentAsync,
  selectAssessments,
  selectLoaded,
  updateAssessmentAsync,
} from '../assessments/assessmentsSlice';
import { getSurveyAsync } from './surveySlice';

const ReadOnlyField = ({ value }) => {
  return (
    <div style={{ fontStyle: 'italic', fontWeight: 600 }}>{value}</div>
  );
};

const getColor = (level) => {
  switch (level) {
    case 'Pillar':
      return '#ccd4da';
    case 'Attribute':
      return '#99a9b5';
    default:
      return '#002A48';
  }
}

const Circle = ({ color }) => {
  return (
    <Flex justify="center">
      <div style={{
        backgroundColor: color,
        width: 20,
        height: 20,
        borderRadius: '50%',
        marginTop: -10,
        position: 'absolute',
      }} />
    </Flex>
  );
};

const Square = () => {
  return (
    <Flex justify="center">
      <div style={{
        border: '1px solid #333',
        width: 20,
        height: 20,
        position: 'absolute',
      }} />
    </Flex>
  );
};

const Bracket = ({ left = false, right = false }) => {
  return (
    <Flex justify="center">
      <div style={{
        fontSize: 20,
        fontWeight: 600,
        marginTop: -17,
        marginLeft: left ? -40 : 40,
        position: 'absolute',
      }}
      >
        {left ? '[' : ']'}
      </div>
    </Flex>
  );
};

export function SurveyAssessment() {

  const [ratings, setRatings] = useState({});

  const assessments = useSelector(selectAssessments);
  const dataSource = useSelector((state) => state.survey.survey);
  const loaded = useSelector(selectLoaded);

  // console.log('dataSource', dataSource);

  const { setNavbarState } = useContext(NavbarContext);
  const { currentUser } = useContext(UserContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const id = location.pathname.match(/\/assessments\/(.*)/)[1];
  const params = new URLSearchParams(location.search);
  const type = params.get('type');
  const isNew = id === 'new';
  const assessment = assessments[id];
  const title = type === 'cost' ? 'PQ Cost: Organizational Due Diligence' : 'PQ5 Leadership Survey';
  const data = assessment?.ratings || {};

  // console.log('assessment', assessment);

  const columns = [
    {
      title: 'Pillar / Attribute / Element',
      dataIndex: 'name',
      key: 'category',
      render: (_, record) => <Flex>{record.name}</Flex>,
    },
    {
      title: '0',
      dataIndex: '0',
      key: '0',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <>
          {['Pillar', 'Attribute'].includes(record.type) && getAverage(record, data) === 0 ?
            <Circle color={getColor(record.type)} />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMin(record, data) === 0 ?
            <Bracket left />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMax(record, data) === 0 ?
            <Bracket right />
            : null
          }
          {record.type === 'Element' && (data[record.key] - 1) === 0 ?
            <Circle color={getColor(record.type)} />
            : null
          }
        </>
      ),
    },
    {
      title: '1',
      dataIndex: '1',
      key: '1',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <>
          {['Pillar', 'Attribute'].includes(record.type) && getAverage(record, data) === 1 ?
            <Circle color={getColor(record.type)} />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMin(record, data) === 1 ?
            <Bracket left />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMax(record, data) === 1 ?
            <Bracket right />
            : null
          }
          {record.type === 'Element' && (data[record.key] - 1) === 1 ?
            <Circle color={getColor(record.type)} />
            : null
          }
        </>
      ),
    },
    {
      title: '2',
      dataIndex: '2',
      key: '2',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <>
          {['Pillar', 'Attribute'].includes(record.type) && getAverage(record, data) === 2 ?
            <Circle color={getColor(record.type)} />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMin(record, data) === 2 ?
            <Bracket left />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMax(record, data) === 2 ?
            <Bracket right />
            : null
          }
          {record.type === 'Element' && (data[record.key] - 1) === 2 ?
            <Circle color={getColor(record.type)} />
            : null
          }
        </>
      ),
    },
    {
      title: '3',
      dataIndex: '3',
      key: '3',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <>
          {['Pillar', 'Attribute'].includes(record.type) && getAverage(record, data) === 3 ?
            <Circle color={getColor(record.type)} />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMin(record, data) === 3 ?
            <Bracket left />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMax(record, data) === 3 ?
            <Bracket right />
            : null
          }
          {record.type === 'Element' && (data[record.key] - 1) === 3 ?
            <Circle color={getColor(record.type)} />
            : null
          }
        </>
      ),
    },
    {
      title: '4',
      dataIndex: '4',
      key: '4',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <>
          {['Pillar', 'Attribute'].includes(record.type) && getAverage(record, data) === 4 ?
            <Circle color={getColor(record.type)} />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMin(record, data) === 4 ?
            <Bracket left />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMax(record, data) === 4 ?
            <Bracket right />
            : null
          }
          {record.type === 'Element' && (data[record.key] - 1) === 4 ?
            <Circle color={getColor(record.type)} />
            : null
          }
        </>
      ),
    },
    {
      title: '5',
      dataIndex: '5',
      key: '5',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <>
          {['Pillar', 'Attribute'].includes(record.type) && getAverage(record, data) === 5 ?
            <Circle color={getColor(record.type)} />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMin(record, data) === 5 ?
            <Bracket left />
            : null
          }
          {['Pillar', 'Attribute'].includes(record.type) && getMax(record, data) === 5 ?
            <Bracket right />
            : null
          }
          {record.type === 'Element' && (data[record.key] - 1) === 5 ?
            <Circle color={getColor(record.type)} />
            : null
          }
        </>
      ),
    },
  ];

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title,
    }));
    dispatch(getSurveyAsync(type));
    if (!isNew) {
      dispatch(getAssessmentAsync(id));
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      respondent: currentUser.email,
    });
  }, [currentUser]);

  useEffect(() => {
    if (assessment) {
      setRatings(assessment.ratings);
    }
  }, [assessment]);

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      onFinish(values, false);
    } catch (err) {
      console.error('Error saving survey');
    }
  };

  const onFinish = (values, submitted = true) => {
    const workspaceId = selectedWorkspace.id;
    const correlationId = uuidv4();
    if (isNew) {
      dispatch(createAssessmentAsync({
        correlationId,
        values: {
          ...values,
          ratings,
          submitted,
          type,
          workspaceId,
        },
      }));
    } else {
      dispatch(updateAssessmentAsync({
        correlationId,
        id,
        values: {
          ...values,
          ratings,
          submitted,
          type,
        },
      }));
    }
    setTimeout(() => {
      navigate('/assessments');
    }, 1000);
  };

  const returnToList = () => {
    navigate('/assessments');
  };

  const getElements = (record) => {
    if (record.children?.length) {
      return record.children.flatMap(getElements);
    }
    return [record];
  };

  const getAverage = (record, data) => {
    const elements = getElements(record);
    const total = elements.reduce((acc, child) => acc + (child.key in data ? (data[child.key] - 1) : 0), 0);
    const count = elements.reduce((acc, child) => acc + (child.key in data ? 1 : 0), 0);
    if (count === 0) {
      return null;
    }
    return Math.ceil(total / (count || 1));
  };

  const getMin = (record, data) => {
    const elements = getElements(record);
    const min = elements.reduce((acc, child) => Math.min(acc, (child.key in data ? (data[child.key] - 1) : 5)), 5);
    const count = elements.reduce((acc, child) => acc + (child.key in data ? 1 : 0), 0);
    if (count === 0) {
      return null;
    }
    return min;
  };

  const getMax = (record, data) => {
    const elements = getElements(record);
    const max = elements.reduce((acc, child) => Math.max(acc, (child.key in data ? (data[child.key] - 1) : 0)), 0);
    const count = elements.reduce((acc, child) => acc + (child.key in data ? 1 : 0), 0);
    if (count === 0) {
      return null;
    }
    return max;
  };

  // console.log('assessment', assessment);

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <div id="assessment-table">
      <div style={{ textAlign: 'right' }}>
        <Button type="link" onClick={returnToList}>Back to List</Button>
      </div>
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        style={{ paddingTop: 0 }}
        onFinish={onFinish}
        initialValues={assessment}
      >
        <Form.Item
          label="Respondent"
          name="respondent"
        >
          <ReadOnlyField />
        </Form.Item>
        <Form.Item
          label="Subject Name"
          name="subjectName"
          rules={[
            { required: true, message: 'Please enter the subject\'s name' },
          ]}
        >
          <ReadOnlyField />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="default" onClick={returnToList}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
}
