import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Space, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  createArtefactAsync,
  getArtefactAsync,
  selectArtefacts,
  selectLoaded,
  updateArtefactAsync,
} from './artefactsSlice';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

export function ArtefactForm() {

  const loaded = useSelector(selectLoaded);
  const artefacts = useSelector(selectArtefacts);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [form] = Form.useForm();

  const correlationIdRef = useRef();
  const taskDoneCallbackRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const id = location.pathname.match(/\/artefacts\/(.*)/)[1];
  const isNew = id === 'new';
  const artefact = artefacts[id];

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Artefact',
    }));
    if (!isNew) {
      dispatch(getArtefactAsync(id));
    }
  }, []);

  useEffect(() => {
    if (correlationIdRef.current) {
      const artefact = Object.values(artefacts).find(a => a.correlationId === correlationIdRef.current);
      if (artefact) {
        if (taskDoneCallbackRef.current) {
          taskDoneCallbackRef.current();
          taskDoneCallbackRef.current = null;
        }
        correlationIdRef.current = null;
      }
    }
  }, [artefacts]);

  useEffect(() => {
    if (location.state && location.state.message) {
      messageApi.info({
        content: location.state.message,
        duration: 5,
      });
    }
  }, [location]);

  const returnToList = () => {
    navigate('/artefacts');
  };

  const onFinish = (values) => {
    const correlationId = uuidv4()
    if (isNew) {
      dispatch(createArtefactAsync({
        correlationId,
        values: { ...values, workspaceId: selectedWorkspace.id },
      }));
    } else {
      dispatch(updateArtefactAsync({ correlationId, id, values }));
    }
    correlationIdRef.current = correlationId;
    taskDoneCallbackRef.current = returnToList;
  };

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <>
      {contextHolder}
      <div style={{ background: '#f5f5f5', padding: '16px 0', height: '100%' }}>
        <Form
          form={form}
          {...layout}
          autoComplete="off"
          onFinish={onFinish}
          initialValues={artefact}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the artefact name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Space>
              <Button type="default" onClick={returnToList}>Cancel</Button>
              <Button type="primary" htmlType="submit">Save</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
