import { useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, DatePicker, Form, Input, Select, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  createUploadAsync,
  getUploadAsync,
  selectLoaded,
  selectUploads,
  updateUploadAsync,
} from './fileUploaderSlice';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const documentTypeOptions = [
  {
    label: 'Act',
    value: 'Act',
  },
  {
    label: 'Standard',
    value: 'Standard',
  },
  {
    label: 'Guideline',
    value: 'Guideline',
  },
  {
    label: 'Related',
    value: 'Related',
  },
];

const regulatorOptions = [
  {
    label: 'ACCC',
    value: 'ACCC',
  },
  {
    label: 'APRA',
    value: 'APRA',
  },
  {
    label: 'ASIC',
    value: 'ASIC',
  },
  {
    label: 'AUSTRAC',
    value: 'AUSTRAC',
  },
  {
    label: 'RBA',
    value: 'RBA',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const typeOptions = [
  {
    label: 'Document',
    value: 'document',
  },
  {
    label: 'AWS S3',
    value: 's3',
  },
  {
    label: 'Google Cloud Storage',
    value: 'gcs',
  },
  {
    label: 'Google Drive',
    value: 'gdrive',
  },
  {
    label: 'Legal Corpus',
    value: 'huggingface',
  },
  {
    label: 'YouTube Channel',
    value: 'youtube',
  },
  {
    label: 'YouTube Video',
    value: 'video',
  },
];

export function UploadForm() {

  const loaded = useSelector(selectLoaded);
  const uploads = useSelector(selectUploads);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [form] = Form.useForm();
  const typeValue = Form.useWatch('type', form);

  const correlationIdRef = useRef();
  const taskDoneCallbackRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.pathname.match(/\/uploads\/(.*)/)[1];
  const isNew = id === 'new';

  const upload = useMemo(() => {
    const upload = uploads[id];
    if (upload) {
      let effectiveDate, endDate;
      if (upload.effectiveDate) {
        effectiveDate = dayjs(upload.effectiveDate);
      }
      if (upload.endDate) {
        endDate = dayjs(upload.endDate);
      }
      return { ...upload, effectiveDate, endDate };
    }
    return null;
  }, [uploads]);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Data Source',
    }));
    if (!isNew) {
      dispatch(getUploadAsync(id));
    }
  }, []);

  useEffect(() => {
    if (correlationIdRef.current) {
      const upload = Object.values(uploads).find(a => a.correlationId === correlationIdRef.current);
      if (upload) {
        if (taskDoneCallbackRef.current) {
          taskDoneCallbackRef.current();
          taskDoneCallbackRef.current = null;
        }
        correlationIdRef.current = null;
      }
    }
  }, [uploads]);

  const returnToList = () => {
    navigate('/uploads');
  }

  const onFinish = (values) => {
    let filename;
    if (values.type === 'gdrive') {
      filename = values.pattern;
    } else if (values.type === 'huggingface') {
      filename = values.path;
    } else if (values.type === 'video') {
      filename = values.youtubeUrl;
    } else if (values.type === 'youtube') {
      filename = values.query;
    }
    let effectiveDate = '', endDate = '';
    if (values.effectiveDate) {
      effectiveDate = values.effectiveDate.format('YYYY-MM-DD');
    }
    if (values.endDate) {
      endDate = values.endDate.format('YYYY-MM-DD');
    }
    const correlationId = uuidv4();
    if (values.type === 'document') {
      if (!isNew) {
        dispatch(updateUploadAsync({
          correlationId,
          id,
          values: {
            documentType: values.documentType,
            regulator: values.regulator,
            effectiveDate,
            endDate,
          },
          partial: true,
        }));
      }
    } else {
      if (values.type === 'huggingface') {
        values = {...values, objectName: values.path};
      }
      if (isNew) {
        dispatch(createUploadAsync({
          correlationId,
          values: {
            ...values,
            filename,
            workspaceId: selectedWorkspace.id,
          },
        }));
      } else {
        dispatch(updateUploadAsync({
          correlationId,
          id,
          values: { ...values, filename },
        }));
      }
    }
    correlationIdRef.current = correlationId;
    taskDoneCallbackRef.current = returnToList;
  };

  const ReadOnlyField = ({ value }) => {
    return (
      <div style={{ fontStyle: 'italic', fontWeight: 600 }}>{value}</div>
    );
  };

  if (!loaded) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Form
        {...layout}
        autoComplete="off"
        form={form}
        onFinish={onFinish}
        initialValues={upload}
      >
        <Form.Item
          label="Source"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select the source.",
            },
          ]}
        >
          {isNew ?
            <Select allowClear
              options={typeOptions}
              optionFilterProp="label"
              placeholder="Select type"
            />
            :
            <ReadOnlyField />
          }
        </Form.Item>
        {typeValue === 'document' ?
          <>
            <Form.Item
              label="Filename"
              name="filename"
            >
              <ReadOnlyField />
            </Form.Item>
            <Form.Item
              label="Document Type"
              name="documentType"
            >
              <Select allowClear
                options={documentTypeOptions}
                optionFilterProp="label"
                placeholder="Select type"
              />
            </Form.Item>
            <Form.Item
              label="Regulator"
              name="regulator"
            >
              <Select allowClear
                options={regulatorOptions}
                optionFilterProp="label"
                placeholder="Select regulator"
              />
            </Form.Item>
          </>
          : null
        }
        {typeValue === 'gdrive' ?
          <>
            <Form.Item
              label="Name"
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Root Folder ID"
              name="rootFolderId"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="File Pattern"
              name="pattern"
            >
              <Input />
            </Form.Item>
          </>
          : null
        }
        {typeValue === 'huggingface' ?
          <>
            <Form.Item
              label="Dataset"
              name="dataset"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Path"
              name="path"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Search Query"
              name="searchQuery"
            >
              <Input />
            </Form.Item>
          </>
          : null
        }
        {typeValue === 'video' ?
          <Form.Item
            label="YouTube URL"
            name="youtubeUrl"
          >
            <Input />
          </Form.Item>
          : null
        }
        {typeValue === 'youtube' ?
          <Form.Item
            label="Channel Search"
            name="query"
          >
            <Input />
          </Form.Item>
          : null
        }
        <Form.Item
          label="Effective Date"
          name="effectiveDate"
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endDate"
        >
          <DatePicker />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
          <Space>
            <Button type="default" onClick={returnToList}>Cancel</Button>
            <Button type="primary" htmlType="submit">Save</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}