import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Table, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { Document, Page, pdfjs } from 'react-pdf';
import { renderAsync } from 'docx-preview';
import axios from 'axios';
import AudioPlayer from 'react-modern-audio-player';
import ReactJson from 'react-json-view';

import SheetJSReactHTML from './SheetJSReactHTML';
import { getExtension } from '../pathUtils';

const { Text } = Typography;

const url = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
);
pdfjs.GlobalWorkerOptions.workerSrc = url.toString();

export const ContentView = ({ audioRef, loading, upload }) => {

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [content, setContent] = useState(null);
  const [ready, setReady] = useState(false);

  const docxPreviewRef = useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { columns, dataSource, ext } = useMemo(() => {
    let ext;
    if (upload) {
      ext = getExtension(upload.filename);
      return { ext };
      // if (ext && upload.content) {
      //   if (ext === 'csv') {
      //     const content = upload.content[0];
      //     const columns = Object.keys(content)
      //       .map((col) => ({
      //         title: col,
      //         dataIndex: col,
      //         ellipsis: content[col].length > 50,
      //       }));
      //     const dataSource = upload.content.map((row, i) => ({ ...row, key: i }));
      //     return { columns, dataSource, ext };
      //   }
      //   return { ext };
      // }
    }
    return {};
  }, [upload]);

  useEffect(() => {
    (async () => {
      if (upload) {
        const ext = getExtension(upload.filename);
        if (ext === 'docx') {
          const res = await axios.get(`/api/uploads/content/${upload.objectName}`, {
            responseType: 'blob',
          });
          renderAsync(res.data, docxPreviewRef.current);
        } else if (ext === 'json') {
          const res = await axios.get(`/api/uploads/content/${upload.objectName}`, {
            responseType: 'json',
          });
          setContent(res.data);
        }
        setReady(true);
      }
    })();
  }, []);

  // if (!upload?.content) {
  //   return (
  //     <div>Loading...</div>
  //   );
  // }

  if (ext === 'txt') {
    return (
      <Text>
        {upload.content.split('\n').map((line, i, arr) => {
          if (i === arr.length - 1) {
            return (
              <p key={upload.id + '-' + i}>{line}...</p>
            );
          }
          return (
            <p key={upload.id + '-' + i}>{line}</p>
          );
        })}
      </Text>
    );
  }

  if (ext === 'csv') {
    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={{ hideOnSinglePage: true }}
      />
    );
  }

  if (ext === 'pdf') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: 8, marginRight: 16 }}>
            <Button
              disabled={pageNumber === numPages}
              icon={<RightOutlined />}
              onClick={() => setPageNumber(cur => cur + 1)}
              size="small"
            />
            <Button
              disabled={pageNumber === 1}
              icon={<LeftOutlined />}
              onClick={() => setPageNumber(cur => cur - 1)}
              size="small"
            />
          </div>
          <Document
            file={`/api/uploads/content/${upload.objectName}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </div>
      </div>
    );
  }

  if (ext === 'xlsx') {
    return (
      <div className="table">
        <SheetJSReactHTML
          url={`/api/uploads/content/${upload.objectName}`}
          style={{ fontSize: '0.9em' }}
        />
      </div>
    )
  }

  if (ext === 'docx') {
    return (
      <div ref={docxPreviewRef}></div>
    )
  }

  if (ext === 'pdf' || ext === 'docx') {
    return (
      <ReactMarkdown className="markdown">{toMarkdown(upload.content.data)}</ReactMarkdown>
    );
  }

  if (ext === 'json') {
    if (content) {
      return (
        <ReactJson src={content} />
      );
    } else {
      return (
        <div>Loading</div>
      );
    }
  }

  if (ext === 'mp3') {
    if (ready) {
      return (
        <AudioPlayer
          audioRef={audioRef}
          playList={
            [
              {
                id: 1,
                src: `/api/uploads/content/${upload.objectName}`,
                name: upload.filename,
              }
            ]
          }
          activeUI={{
            all: true,
            progress: "waveform",
          }}
          rootContainerProps={{
            colorScheme: 'light',
          }}
        />
      );
    }
  }

  return null;
};

const toMarkdown = (data) => {
  const md = data.structured_content.reduce((a, el) => {
    switch (el.type) {
      case 'heading':
        a.headingLevel += 1;
        a.content += '#'.repeat(a.headingLevel) + ' ' + el.text + '\n\n';
        break;

      case 'text':
        a.content += el.text + '\n\n';
        a.headingLevel = 0
        break;

      case 'list':
        const prefix = el.subtype === 'ordered' ? '1. ' : '- ';
        a.content += el.heading + '\n\n';
        el.items.forEach((li) => {
          a.content += prefix + ' ' + li + '\n';
        });
        a.content += '\n';
        a.headingLevel = 0
        break;

      default:
    }
    return a;
  }, { content: '', headingLevel: 0 });
  return md.content;
};
