import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Segmented,
  Select,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tag,
  Typography,
  Upload,
  message,
} from 'antd';
import {
  CloseOutlined,
  EditOutlined,
  LinkedinOutlined,
  LoadingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  PlusOutlined,
  SettingOutlined,
  TeamOutlined,
  TwitterOutlined,
  UserOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { md5 } from 'js-md5';
import { v4 as uuidv4 } from 'uuid';
import useLocalStorageState from 'use-local-storage-state';

import { Chat } from '../../components/Chat2';
import { ContentView } from '../../components/ContentView';
import YoutubeUploadModal from '../../components/YoutubeUploadModal';
import NavbarContext from '../../contexts/NavbarContext';
import UserContext from '../../contexts/UserContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import { getExtension } from '../../pathUtils';
import {
  getQuizAsync,
  getTutorResponseAsync,
  selectLoaded,
  selectLoading,
  selectMessages,
  selectQuestions,
  setMessages,
} from '../chat/chatSlice';
import {
  getOntologiesAsync,
  selectOntologies,
} from '../ontology/ontologiesSlice';
import {
  getSettingsAsync,
  selectSettings,
} from '../settings/settingsSlice';
import {
  applyExtractVideoActivityAsync,
  createUploadAsync,
  fileUploadAsync,
  getUploadForObjectNameAsync,
  getUploadsAsync,
  selectUploading,
  selectUploads,
} from '../uploader/fileUploaderSlice';
import {
  selectCurrentUser,
} from '../users/usersSlice';
import {
  createChatSessionAsync,
  deleteChatSessionsAsync,
  getChatSessionsAsync,
  selectChatSessions,
  selectLoaded as selectChatSessionsLoaded,
  selectLoading as selectChatSessionsLoading,
  updateChatSessionAsync,
} from './chatSessionsSlice';
import {
  deleteNoteAsync,
  findImageAsync,
  getNotesAsync,
  getVideosAsync,
  processSourceContentAsync,
  saveNoteAsync,
  selectImage,
  selectLoading as selectResultLoading,
  selectNotesLoading,
  selectNotes,
  selectResult,
  selectVideos,
} from './tutorSlice';

import PQLogo from '../../images/pq_logo.svg';

const { Content, Sider } = Layout;
const { TextArea } = Input;

const API_HOST = process.env.REACT_APP_API_HOST;

const CATEGORY_MAPPINGS_KEY = 'category_mappings';

const L1_CATEGORIES_KEY = 'level1_categories';

export function Tutor() {

  const [action, setAction] = useState(null);
  const [domain, setDomain] = useState(null);
  const [menuAction, setMenuAction] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [collapsed, setCollapsed] = useLocalStorageState('tutor-sidebar-collapsed', { defaultValue: true });
  const [notesCollapsed, setNotesCollapsed] = useLocalStorageState('tutor-sidebar-notes-collapsed', { defaultValue: true });
  const [peopleCollapsed, setPeopleCollapsed] = useLocalStorageState('tutor-sidebar-people-collapsed', { defaultValue: true });
  const [profilesCollapsed, setProfilesCollapsed] = useLocalStorageState('tutor-sidebar-profiles-collapsed', { defaultValue: true });
  const [sessionCollapsed, setSessionCollapsed] = useLocalStorageState('tutor-sidebar-session-collapsed', { defaultValue: true });
  const [settingsCollapsed, setSettingsCollapsed] = useLocalStorageState('tutor-sidebar-settings-collapsed', { defaultValue: true });
  const [correct, setCorrect] = useState([]);
  const [correlationId, setCorrelationId] = useState(null);
  const [current, setCurrent] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openQuiz, setOpenQuiz] = useState(false);
  const [openYoutubeForm, setOpenYoutubeForm] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedSessionRowKeys, setSelectedSessionRowKeys] = useState([]);
  const [selectedPersonRowKeys, setSelectedPersonRowKeys] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [upload, setUpload] = useState(null);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [currentSpeakers, setCurrentSpeakers] = useState([]);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [level1Category, setLevel1Category] = useState(null);
  const [level2Category, setLevel2Category] = useState(null);
  const [skip, setSkip] = useState(0);
  const [sourceTypes, setSourceTypes] = useLocalStorageState('tutor-source-types', { defaultValue: ['document', 'video'] });
  const [speakerSkip, setSpeakerSkip] = useState(0);
  const [newTopic, setNewTopic] = useState(false);

  const chatSessionsLoaded = useSelector(selectChatSessionsLoaded);
  const chatSessionsLoading = useSelector(selectChatSessionsLoading);
  const chatSessions = useSelector(selectChatSessions);
  const image = useSelector(selectImage);
  const loaded = useSelector(selectLoaded);
  const loading = useSelector(selectLoading);
  const messages = useSelector(selectMessages);
  const notesLoading = useSelector(selectNotesLoading);
  const notes = useSelector(selectNotes);
  const ontologies = useSelector(selectOntologies);
  const questions = useSelector(selectQuestions);
  const resultLoading = useSelector(selectResultLoading);
  const result = useSelector(selectResult);
  const settings = useSelector(selectSettings);
  const uploading = useSelector(selectUploading);
  const uploads = useSelector(selectUploads);
  const videos = useSelector(selectVideos);
  const currentUsr = useSelector(selectCurrentUser);

  const breadcrumbs = [level1Category, level2Category]
    .filter(v => v)
    .map(title => ({ title }));

  const { setNavbarState } = useContext(NavbarContext);
  const { currentUser } = useContext(UserContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const isAdmin = useMemo(() => (currentUsr?.roles || []).includes('admin'), [currentUsr]);

  const dispatch = useDispatch();

  const [settingsForm] = Form.useForm();
  const selectable = Form.useWatch('selectable', settingsForm);

  const clientIdRef = useRef(null);
  const firstSessionRef = useRef(true);
  const correlationIdRef = useRef();
  const taskDoneCallbackRef = useRef();

  const ext = useMemo(() => {
    if (!upload) return null;
    getExtension(upload.filename);
  }, [upload]);

  const extractVideo = (upload) => {
    console.log('Extracting video...');
    dispatch(applyExtractVideoActivityAsync({
      youtubeUrl: upload.youtubeUrl,
      workspaceId: selectedWorkspace.id,
      domain,
      clientId: clientIdRef.current,
      key: upload.id,
    }));
  };

  const onMessage = (ev) => {
    const recv = JSON.parse(ev.data);
    console.log('recv:', recv);
    if (recv.status === 'done') {
      const workspaceId = selectedWorkspace.id;
      const key = recv.data?.key;
      if (key) {
        // can't get latest uploads in closure, just reload both
        dispatch(getVideosAsync({ workspaceId }));
        dispatch(getUploadsAsync({ workspaceId }));
      }
    }
  };

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: !isAdmin ?
        <img
          src={PQLogo}
          style={{ width: 45, height: 27 }}
          alt={process.env.REACT_APP_NAME}
        />
        : null,
    }));
    dispatch(setMessages({ messages: [] }));
    const clientId = uuidv4();
    const protocol = process.env.REACT_APP_SECURE_WEBSOCKETS === 'true' ? 'wss' : 'ws';
    const ws = new WebSocket(`${protocol}://${API_HOST}/api/websockets/${clientId}`);
    ws.onmessage = onMessage;
    clientIdRef.current = clientId;

    return () => {
      console.log("Closing connection");
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (correlationIdRef.current) {
      const upload = Object.values(uploads).find(u => u.correlationId === correlationIdRef.current);
      if (upload) {
        if (taskDoneCallbackRef.current) {
          taskDoneCallbackRef.current(upload);
          taskDoneCallbackRef.current = null;
        }
        correlationIdRef.current = null;
      }
    }
  }, [uploads]);

  useEffect(() => {
    if (selectedWorkspace && Object.keys(ontologies).length) {
      let ontology = selectedWorkspace.defaultOntology;
      if (!ontology) {
        ontology = Object.values(ontologies)[0].id;
      }
      const domain = ontologies[ontology].domain;
      setDomain(domain);
    }
  }, [ontologies, selectedWorkspace]);

  useEffect(() => {
    if (selectedObject) {
      const upload = uploads.find(u => u.objectName === selectedObject);
      if (upload) {
        setUpload(upload);
        setIsModalOpen(true);
        setSelectedObject(null);
      }
    }
  }, [uploads]);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getOntologiesAsync({ workspaceId }));
      dispatch(getUploadsAsync({ workspaceId }));
      dispatch(getSettingsAsync({
        keys: [L1_CATEGORIES_KEY, CATEGORY_MAPPINGS_KEY],
        workspaceId,
      }));
      dispatch(getVideosAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace && currentUser?.email) {
      dispatch(getChatSessionsAsync({
        workspaceId: selectedWorkspace.id,
        type: 'tutor',
        username: currentUser.email,
      }));
      dispatch(getNotesAsync({
        workspaceId: selectedWorkspace.id,
        username: currentUser.email,
      }));
    }
  }, [selectedWorkspace, currentUser])

  useEffect(() => {
    if (messages.length === 0) {
      if (firstSessionRef.current) {
        const message = {
          key: uuidv4(),
          role: 'assistant',
          content: [
            {
              key: uuidv4(),
              suggested_next_queries: [
                {
                  short_label: 'Take quiz',
                  query: 'quiz',
                },
                {
                  short_label: 'No thanks',
                  query: 'continue',
                },
              ],
              hideUserMessage: true,
            },
          ],
          isQuiz: true,
        };
        dispatch(setMessages({ messages: [...messages, message] }));
      } else {
        const setting = Object.values(settings).find(s => s.key === L1_CATEGORIES_KEY);
        if (setting) {
          const message = {
            key: uuidv4(),
            role: 'assistant',
            level1Category: true,
            content: [
              {
                key: uuidv4(),
                suggested_next_queries: setting.value.map(c => ({
                  short_label: c,
                  query: c,
                })),
                hideUserMessage: true,
              },
            ],
          };
          dispatch(setMessages({ messages: [...messages, message] }));
        }
      }
    }
  }, [settings, messages]);

  useEffect(() => {
    if (questions.length) {
      setOpenQuiz(true);
    }
  }, [questions]);

  useEffect(() => {
    if (action && result) {
      const vids = videos.filter(v => selectedRowKeys.includes(v.id));
      const docs = Object.values(uploads).filter(u => selectedRowKeys.includes(u.id));
      const video_names = vids.map(v => v.name);
      const file_names = docs.map(u => u.filename);
      const names = [...video_names, ...file_names].join(', ');
      const act = action.replace(/[_-]/g, ' ');
      const ms = [
        {
          key: uuidv4(),
          role: 'user',
          content: `${act} using the following sources: ${names}`,
        },
        {
          key: uuidv4(),
          role: 'assistant',
          content: [
            {
              key: uuidv4(),
              content: result[action],
              action,
            },
          ],
        },
      ];
      dispatch(setMessages({ messages: [...messages, ...ms] }));
      setAction(null);
    }
  }, [result]);

  useEffect(() => {
    if (menuAction && result) {
      const last = messages[messages.length - 1];
      const message = {
        ...last,
        content: [
          {
            ...last.content[0],
            [menuAction]: result[menuAction],
          },
        ],
      };
      const newMessages = [...messages.slice(0, -1), message];
      dispatch(setMessages({ messages: newMessages }));
      setMenuAction(null);
    }
  }, [result]);

  useEffect(() => {
    if (correlationId && chatSessions) {
      const session = Object.values(chatSessions).find(s => s.correlationId === correlationId);
      if (session) {
        setSelectedSession(session);
        setCorrelationId(null);
      }
    }
  }, [chatSessions, correlationId]);

  useEffect(() => {
    if (chatSessionsLoaded) {
      const sessions = Object.values(chatSessions);
      if (sessions.length) {
        const lastSession = sessions.find(s => s.name === 'last session');
        if (lastSession) {
          setLevel1Category(lastSession.level1Category);
          setLevel2Category(lastSession.level2Category);
          dispatch(setMessages({ messages: lastSession.messages.filter(m => m).map(formatMessage) }));
          setSelectedSession(lastSession);
          setNewTopic(true);
        }
      }
    }
  }, [chatSessionsLoaded]);

  useEffect(() => {
    if (!sessionCollapsed) {
      setCollapsed(true);
      setSettingsCollapsed(true);
      setPeopleCollapsed(true);
      setProfilesCollapsed(true);
      setNotesCollapsed(true);
    }
  }, [sessionCollapsed]);

  useEffect(() => {
    if (!settingsCollapsed) {
      setCollapsed(true);
      setSessionCollapsed(true);
      setPeopleCollapsed(true);
      setProfilesCollapsed(true);
      setNotesCollapsed(true);
    }
  }, [settingsCollapsed]);

  useEffect(() => {
    if (!collapsed) {
      setSessionCollapsed(true);
      setSettingsCollapsed(true);
      setPeopleCollapsed(true);
      setProfilesCollapsed(true);
      setNotesCollapsed(true);
    }
  }, [collapsed]);

  useEffect(() => {
    if (!peopleCollapsed) {
      setSessionCollapsed(true);
      setSettingsCollapsed(true);
      setCollapsed(true);
      setProfilesCollapsed(true);
      setNotesCollapsed(true);
    }
  }, [peopleCollapsed]);

  useEffect(() => {
    if (!profilesCollapsed) {
      setSessionCollapsed(true);
      setSettingsCollapsed(true);
      setCollapsed(true);
      setPeopleCollapsed(true);
      setNotesCollapsed(true);
    }
  }, [profilesCollapsed]);

  useEffect(() => {
    if (!notesCollapsed) {
      setSessionCollapsed(true);
      setSettingsCollapsed(true);
      setCollapsed(true);
      setProfilesCollapsed(true);
      setPeopleCollapsed(true);
    }
  }, [notesCollapsed]);

  useEffect(() => {
    const speakers = [];
    if (messages?.length) {
      const last = messages[messages.length - 1];
      const hits = last.content[0].search_hits;
      if (hits?.length) {
        for (const h of hits) {
          const speaker = h.citation?.speaker;
          if (speaker && speaker !== 'Unknown') {
            speakers.push({ speaker });
          }
        }
      }
    }
    setCurrentSpeakers(speakers);
  }, [messages]);

  const collapseAll = () => {
    setSessionCollapsed(true);
    setSettingsCollapsed(true);
    setCollapsed(true);
    setPeopleCollapsed(true);
    setNotesCollapsed(true);
    setProfilesCollapsed(true);
  };

  const handleAnswer = (i, j, a) => {
    let correct = false;
    if (questions[i].correct_answer_index === j) {
      correct = true;
      setScore(cur => cur + 1);
    }
    setAnswers(cur => [...cur, [questions[i].question, a, correct]]);
    setCorrect(cur => [...cur, correct]);
    setCurrent(cur => cur + 1);
  };

  const handleChatSubmit = (values, skipQuiz) => {
    const { messages } = values;
    let l1cat = level1Category;
    let l2cat = level2Category;
    let content = messages[messages.length - 1].content;
    const x = firstSessionRef.current ? 0 : 2;
    if (messages.length === (2 - x)) {
      if (content === 'quiz' && !skipQuiz) {
        handleGetQuiz(3);
        dispatch(setMessages({ messages }));
      } else {
        const setting = Object.values(settings).find(s => s.key === L1_CATEGORIES_KEY);
        if (setting) {
          const message = {
            key: uuidv4(),
            role: 'assistant',
            level1Category: true,
            content: [
              {
                key: uuidv4(),
                suggested_next_queries: setting.value.map(c => ({
                  short_label: c,
                  query: c,
                })),
                hideUserMessage: true,
              },
            ],
          };
          dispatch(setMessages({ messages: [...messages, message] }));
        }
      }
    } else if (messages.length === (4 - x)) {
      const setting = Object.values(settings).find(s => s.key === CATEGORY_MAPPINGS_KEY);
      const l2Categories = Object.entries(setting.value).reduce((a, [k, v]) => {
        if (v.includes(content)) {
          a.push(k);
        }
        return a;
      }, []);
      const message = {
        key: uuidv4(),
        role: 'assistant',
        level2Category: true,
        content: [
          {
            key: uuidv4(),
            suggested_next_queries: l2Categories.map(c => ({
              short_label: c,
              query: c,
            })),
            hideUserMessage: true,
          },
        ],
      };
      dispatch(setMessages({ messages: [...messages, message] }));
      setLevel1Category(content);
      l1cat = content;
    } else {
      let createSyllabus = false;
      if (messages.length === (6 - x)) {
        setLevel2Category(content);
        l2cat = content;
        createSyllabus = true;
      }
      if (answers.length) {
        content = [
          'Your quiz results are:',
          answers.map(([question, answer, result]) => {
            return `Question: ${question} Answer: ${answer} Result: ${result ? 'correct' : 'incorrect'}`;
          }).join('\n'),
          'In the response, focus on topics related to questions with incorrect answers.',
        ].join('\n\n');
        setAnswers([]);
        setScore(0);
      }
      const scope = settingsForm.getFieldValue('searchScope') || 'Video';
      const style = settingsForm.getFieldValue('conversationStyle') || 'tell';
      const personality = settingsForm.getFieldValue('personality');
      const speakers = [];
      if (selectedSpeakers) {
        speakers.push(...selectedSpeakers);
      }
      if (activeSpeaker) {
        speakers.push(activeSpeaker);
      }
      let args = {
        domain: selectedWorkspace.key,
        includeTypes: [scope],
        messages,
        q: content,
        workspaceId: selectedWorkspace.id,
        selectedSpeakers: speakers.length ? speakers : undefined,
        createSyllabus,
        style,
        level1Category: l1cat,
        level2Category: l2cat,
        personality,
      };
      dispatch(getTutorResponseAsync(args));
      // dispatch(findImageAsync({ q: content }));
    }
  };

  const handleGetQuiz = (maxNumberQuestions = 3) => {
    dispatch(getQuizAsync({
      messages,
      minNumberQuestions: 1,
      maxNumberQuestions,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const handleSourceAction = (action, params) => {
    const selectedVideos = videos
      .filter(v => selectedRowKeys.includes(v.id))
      .map(v => v.video_id);

    const objectNames = Object.values(uploads)
      .filter(v => selectedRowKeys.includes(v.id))
      .map(v => v.objectName);

    dispatch(processSourceContentAsync({
      ...(params || {}),
      action,
      objectNames,
      selectedVideos,
      workspaceId: selectedWorkspace.id,
    }));
    setAction(action);
  };

  const handleCreateFlashcards = (selectedVideos) => {
    const action = 'create_flash_cards';
    dispatch(processSourceContentAsync({
      numberCards: 3,
      action,
      workspaceId: selectedWorkspace.id,
      selectedVideos,
    }));
    setMenuAction(action);
  };

  const handleCreateMashup = (selectedVideos) => {
    const action = 'create_mashup';
    dispatch(processSourceContentAsync({
      action,
      workspaceId: selectedWorkspace.id,
      selectedVideos,
    }));
    setMenuAction(action);
  };

  const handleCreateOutline = (selectedVideos) => {
    const action = 'create_outline';
    dispatch(processSourceContentAsync({
      action,
      workspaceId: selectedWorkspace.id,
      selectedVideos,
    }));
    setMenuAction(action);
  };

  const handleCreateStudyNote = (selectedVideos) => {
    const action = 'create_study_note';
    dispatch(processSourceContentAsync({
      action,
      workspaceId: selectedWorkspace.id,
      selectedVideos,
    }));
    setMenuAction(action);
  };

  const handleCreateSummary = (selectedVideos) => {
    const action = 'create_summary';
    dispatch(processSourceContentAsync({
      action,
      workspaceId: selectedWorkspace.id,
      selectedVideos,
    }));
    setMenuAction(action);
  };

  const handleShowPeople = (selectedVideos, selectedPeople, skip) => {
    const action = 'show_people';
    dispatch(processSourceContentAsync({
      action,
      workspaceId: selectedWorkspace.id,
      selectedVideos,
      selectedPeople,
      skip,
    }));
    setMenuAction(action);
  };

  const handleShowPodcasts = () => {
    const last = messages[messages.length - 1];
    const message = {
      ...last,
      content: [
        {
          ...last.content[0],
          show_podcasts: true,
        },
      ],
    };
    const newMessages = [...messages.slice(0, -1), message];
    dispatch(setMessages({ messages: newMessages }));
  };

  const handleReset = () => {
    firstSessionRef.current = false;
    dispatch(setMessages({ messages: [] }));
    setNewTopic(true);
  };

  const handleSaveNote = ({ name, text, messages }) => {
    const node = {
      id: md5('Note:' + name.toLowerCase()),
      type: 'Note',
      inferred: false,
      name,
      metadata: [
        {
          key: 'text',
          data_type: 'STRING',
          value: text,
        },
        {
          key: 'author',
          data_type: 'STRING',
          value: currentUser.username,
        },
      ],
    };
    dispatch(saveNoteAsync({
      node,
      workspaceId: selectedWorkspace.id,
      messages,
    }));
  };

  const handleNotesDelete = (keys) => {
    for (const id of keys) {
      dispatch(deleteNoteAsync({
        id,
        workspaceId: selectedWorkspace.id,
      }));
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setUpload(null);
  };

  const onCancelQuiz = () => {
    setOpenQuiz(false);
    setCorrect([]);
    setCurrent(0);
    if (messages.length === 2) {
      handleChatSubmit({ messages }, true);
    }
  };

  const handleAddResource = ({ key }) => {
    if (key === 'youtube') {
      setOpenYoutubeForm(true);
    }
  };

  const handleDelete = () => {
    dispatch(deleteChatSessionsAsync({
      ids: selectedSessionRowKeys,
    }));
  };

  const handleSave = (messages) => {
    const workspaceId = selectedWorkspace.id;
    if (selectedSession && selectedSession.name !== 'last session') {
      dispatch(updateChatSessionAsync({
        id: selectedSession.id,
        values: { messages, workspaceId },
      }));
    } else {
      const correlationId = uuidv4();
      dispatch(createChatSessionAsync({
        correlationId,
        values: { messages, type: 'tutor', workspaceId },
      }));
      setCorrelationId(correlationId);
    }
  };

  const handleYoutubeUpload = ({ values }) => {
    const workspaceId = selectedWorkspace.id;
    const youtubeUrl = values.youtubeUrl;
    const correlationId = uuidv4();
    dispatch(createUploadAsync({
      correlationId,
      values: {
        ...values,
        type: 'video',
        filename: youtubeUrl,
        workspaceId,
      },
    }));
    correlationIdRef.current = correlationId;
    taskDoneCallbackRef.current = extractVideo;
    setOpenYoutubeForm(false);
  };

  const openSession = (id) => {
    const session = chatSessions[id];
    dispatch(setMessages({ messages: session.messages.map(formatMessage) }));
    setSelectedSession(session);
  };

  const showContent = (objectName) => {
    dispatch(getUploadForObjectNameAsync({
      objectName,
      workspaceId: selectedWorkspace.id,
    }));
    setSelectedObject(objectName);
  };

  const usePersonas = () => {
    const selectedPeople = selectedPersonRowKeys.reduce((a, id) => {
      const vid = videos.find(v => v.id === id);
      if (vid) {
        const speaker = vid.speaker;
        if (speaker && speaker !== 'Unknown') {
          a[speaker] = 1;
        }
      }
      return a;
    }, {});
    setSelectedSpeakers(Object.keys(selectedPeople));
  };

  const clearPersonas = () => {
    setSelectedSpeakers([]);
  };

  const askSpeaker = (speaker) => {
    setActiveSpeaker(cur => cur === speaker ? null : speaker);
  };

  const steps = questions.map((q, i) => ({
    content: (
      <>
        <Typography.Paragraph style={{ fontSize: '24px', paddingTop: 100 }}>
          {q.question}
        </Typography.Paragraph>
        <Space style={{ paddingBottom: 100 }} wrap>
          {q.possible_answers.map((a, j) => (
            <Button key={'ans-' + j} onClick={() => handleAnswer(i, j, a)}>
              {a}
            </Button>
          ))}
        </Space>
        <div style={{ fontSize: '24px' }}>
          Score: {score} / {questions.length}
        </div>
      </>
    ),
  }));

  const items = steps.map((s, i) => ({
    key: 'q' + i,
    icon: (typeof correct[i] !== 'undefined' && !correct[i]) ? <CloseOutlined /> : undefined,
  }));

  const columns = [
    {
      title: 'Sources',
      dataIndex: 'name',
      width: '100%',
      render: (_, { name, processing }) => {
        if (processing) {
          return (
            <Badge dot>
              <Typography.Text
                className="text-secondary"
                ellipsis={{ tooltip: name }}
                style={{ fontSize: '14px', height: '18px', width: 184 }}
              >
                {name}
              </Typography.Text>
            </Badge>
          );
        } else {
          return (
            <Typography.Text
              ellipsis={{ tooltip: name }}
              style={{ fontSize: '14px', width: 184 }}
            >
              {name}
            </Typography.Text>
          );
        }
      },
    },
  ];

  const data = useMemo(() => {
    if (!videos && !uploads) return [];
    const videosList = (videos || [])
      .map(v => ({
        key: v.id,
        name: v.name,
        last_modified: v.last_modified,
        type: 'video',
        processing: v.processing,
      }));
    const documentsList = Object.values(uploads || {})
      .filter(v => ['document'].includes(v.type))
      .map(v => ({
        key: v.id,
        name: v.filename,
        last_modified: v.last_modified,
        type: v.type,
        processing: v.processing,
      }));
    const list = [...videosList, ...documentsList].filter(s => sourceTypes.includes(s.type));
    list.sort((a, b) => a.last_modified > b.last_modified ? -1 : 1);
    return list;
  }, [sourceTypes, uploads, videos]);

  const notesData = useMemo(() => {
    if (!notes) return [];
    const list = notes.map(n => ({
      key: n.id,
      name: n.name,
      last_modified: n.last_modified,
      text: n.text,
    }));
    list.sort((a, b) => a.last_modified > b.last_modified ? -1 : 1);
    return list;
  }, [notes]);

  const peopleData = useMemo(() => {
    if (!videos) return [];
    const list = videos.map(v => ({
      key: v.id,
      name: v.name,
      speaker: v.speaker,
      thumbnail: v.thumbnail,
      last_modified: v.last_modified,
    }));
    list.sort((a, b) => a.speaker < b.speaker ? -1 : 1);

    // deduplicate
    const people = {};
    for (const person of list) {
      if (person.speaker && person.speaker !== 'Unknown' && !people[person.speaker]) {
        people[person.speaker] = person;
      }
    }

    return Object.values(people);
  }, [videos]);

  const peopleColumns = [
    {
      title: 'People',
      dataIndex: 'speaker',
      width: '100%',
      render: (_, { key, name, speaker, thumbnail }) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <div className="speaker" style={{ fontSize: '1em', minWidth: 100 }}>
              {speaker}
            </div>
            <div style={{ fontSize: '0.85em', minWidth: 82 }}>
              <Image src={thumbnail} preview={false} />
            </div>
          </div>
          <div style={{ fontSize: '13px' }}>
            {name}
          </div>
        </div>
      ),
    },
  ];

  const sessionColumns = [
    {
      title: 'Sessions',
      dataIndex: 'name',
      width: '100%',
      render: (_, { key, name }) => (
        <Link onClick={() => openSession(key)}
          style={{ color: selectedSession?.id === key ? '#177ddc' : 'inherit' }}
        >
          {name}
        </Link>
      ),
    },
  ];

  const sessionData = useMemo(() => {
    const list = Object.values(chatSessions).map(s => ({
      key: s.id,
      name: s.name || s.id,
    }));
    list.sort((a, b) => a.modified > b.modified ? -1 : 1);
    return list;
  }, [chatSessions]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.processing,
      name: record.name,
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;
  const hasSessionsSelected = selectedSessionRowKeys.length > 0;
  const hasPersonsSelected = selectedPersonRowKeys.length > 0;
  const newTopicOffset = newTopic ? 4 : 6;
  const acceptFreeTextInput = messages.length > newTopicOffset;

  const onSelectSessionChange = (newSelectedRowKeys) => {
    setSelectedSessionRowKeys(newSelectedRowKeys);
  };

  const sessionRowSelection = {
    selectedSessionRowKeys,
    onChange: onSelectSessionChange,
  };

  const onSelectPersonChange = (newSelectedRowKeys) => {
    setSelectedPersonRowKeys(newSelectedRowKeys);
  };

  const personRowSelection = {
    selectedPersonRowKeys,
    onChange: onSelectPersonChange,
  };

  const NotesList = ({ data, loading, onDelete, onSave, onSelect }) => {

    const [selected, setSelected] = useState({});

    const hasSelected = Object.values(selected).some(x => x);
    const nSelected = Object.values(selected).filter(x => x).length;
    const selectedKeys = Object.entries(selected).filter(([_, v]) => v).map(([k, _]) => k);

    const handleDelete = () => {
      onDelete(selectedKeys);
      setSelected({});
    };

    const handleSave = () => {
      onSave(selectedKeys);
      setSelected({});
    };

    const handleSelect = (key) => (ev) => {
      ev.stopPropagation();
      const newSelected = {
        ...selected,
        [key]: !selected[key],
      };
      setSelected(newSelected);
      if (onSelect) {
        const keys = Object.entries(newSelected).filter(([_, v]) => v).map(([k, _]) => k);
        onSelect(keys);
      }
    };

    const Spinner = () => (
      <div className="single-image" style={{ marginTop: 24 }}>
        <div style={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
          <Spin />
        </div>
      </div>
    );

    const NoData = () => {
      return (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )
    };

    return (
      <>
        {(loading || data.length > 0) ?
          <div className="body-section expanded"
            style={{ display: 'contents', overflowY: 'auto', maxHeight: 'calc(100vh - 340px)' }}
          >
            <div id="imagesContent"
              className="image-section"
              style={{
                position: 'relative',
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <Space className="notes" direction="vertical" size={24}>
                {data.map(n => (
                  <div
                    className={'single-image' + (selected[n.key] ? ' selected' : '')} key={n.key}
                    data-id={n.key}
                  >
                    <Card
                    >
                      <Card.Meta
                        title={n.name}
                        description={n.text}
                      />
                    </Card>
                    <span
                      className={'select-box' + (selected[n.key] ? ' icon-s-Ok2_32' : ' icon-s-UnselectedCheck_32')}
                      onClick={handleSelect(n.key)}
                    ></span><span className="select-icon-background"></span>
                  </div>
                ))}
              </Space>
              {loading ?
                <Spinner />
                : null
              }
            </div>
          </div>
          :
          <NoData />
        }
        <Space direction="horizontal" style={{ marginTop: 16 }} className="notes-actions">
          {onSave ?
            <Button type="primary" size="small" style={{ fontSize: '12px' }}
              disabled={!hasSelected}
              onClick={handleSave}
            >
              Save
            </Button>
            : null
          }
          {onDelete ?
            <Button danger type="primary" size="small" style={{ fontSize: '12px' }}
              disabled={!hasSelected}
              onClick={handleDelete}
            >
              Delete notes
            </Button>
            : null
          }
        </Space>
      </>
    );
  };

  const NoteCard = ({ onChange }) => {

    const [nameEditMode, setNameEditMode] = useState(false);
    const [textEditMode, setTextEditMode] = useState(false);
    const [name, setName] = useState('New note');
    const [text, setText] = useState('ABC');

    const handleSave = () => {
      if (typeof onChange === 'function') {
        onChange({ name, text });
      }
    };

    return (
      <Flex vertical gap="small">
        <Card
        >
          <Card.Meta
            title={nameEditMode ?
              <Input
                onChange={e => setName(e.currentTarget.value)}
                value={name}
                onPressEnter={() => setNameEditMode(false)}
                onBlur={() => setNameEditMode(false)}
                onFocus={(ev) => ev.target.select()}
              />
              :
              <span
                onClick={() => setNameEditMode(true)}
                style={{ cursor: 'pointer' }}
              >
                {name}
              </span>
            }
            description={textEditMode ?
              <TextArea
                autoSize={{ minRows: 2, maxRows: 8 }}
                onChange={e => setText(e.currentTarget.value)}
                value={text}
                // onPressEnter={() => setTextEditMode(false)}
                onBlur={() => setTextEditMode(false)}
                onFocus={(ev) => ev.target.select()}
              />

              :
              <span
                onClick={() => setTextEditMode(true)}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </span>
            }
          />
        </Card>
        <Flex gap="small">
          <Button type="default" size="small"
            onClick={() => {
              setName('New note');
              setText('ABC');
            }}
          >
            Cancel
          </Button>
          <Button type="primary" size="small"
            onClick={handleSave}
          >
            Add
          </Button>
        </Flex>
      </Flex>
    );
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      dispatch(fileUploadAsync(info.file, false, selectedWorkspace.id, domain, clientIdRef.current, true));
    }
  };

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {uploading ? 'Uploading...' : 'Upload document'}
      </div>
    </div>
  );

  if (!selectedWorkspace) {
    return (
      <div style={{ marginTop: 40 }}>
        <a href="/tutor">Click here to continue</a>
      </div>
    )
  }
  return (
    <>
      <Modal
        open={isModalOpen}
        title="Content Preview"
        width={ext === 'pdf' ? 643 : '70%'}
        styles={{
          body: { height: 500, overflowY: 'auto' },
        }}
        onCancel={onCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <ContentView upload={upload} />
      </Modal>
      <Modal
        onCancel={onCancelQuiz}
        onOk={onCancelQuiz}
        open={openQuiz}
        title="Quiz"
        width={643}
      >
        <Steps current={current} items={items} />
        {steps[current]?.content}
        {steps.length === current ?
          <div style={{
            display: 'flex',
            fontSize: '24px',
            justifyContent: 'center',
            padding: '100px 0',
          }}>
            Total Score: {score}
          </div>
          : null
        }
      </Modal>
      <YoutubeUploadModal
        open={openYoutubeForm}
        onCancel={() => setOpenYoutubeForm(false)}
        onSubmit={handleYoutubeUpload}
      />
      <Layout id="tutor">
        <Sider
          collapsible
          collapsed={peopleCollapsed}
          collapsedWidth={0}
          trigger={null}
          style={{
            marginRight: peopleCollapsed ? 0 : 10,
          }}
          width={250}
          theme="light"
        >
          <Table
            rowSelection={personRowSelection}
            columns={peopleColumns}
            dataSource={peopleData}
            loading={loading}
            pagination={{
              pageSize: 4,
              showLessItems: true,
              simple: true,
              size: 'small',
            }}
          />
          <div style={{ padding: 16 }}>
            <Space wrap>
              <Button
                disabled={!hasPersonsSelected}
                onClick={usePersonas}
                size="small"
              >
                Use personas
              </Button>
              <Button
                onClick={clearPersonas}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
          {selectedSpeakers?.length ?
            <div style={{ padding: 16, display: 'flex', flexDirection: 'column', gap: 8 }}>
              <div>Selected personas</div>
              <Space wrap gap={0}>
                {selectedSpeakers.map(s => (
                  <Tag style={{ marginRight: 0 }}>{s}</Tag>
                ))}
              </Space>
            </div>
            : null
          }
        </Sider>
        <Sider
          collapsible
          collapsed={collapsed}
          collapsedWidth={0}
          trigger={null}
          style={{
            marginRight: collapsed ? 0 : 10,
          }}
          width={250}
          theme="light"
        >
          <Flex vertical gap={8}>
            <Select
              allowClear
              mode="multiple"
              options={[
                {
                  label: 'Document',
                  value: 'document',
                },
                {
                  label: 'Video',
                  value: 'video',
                },
              ]}
              onChange={setSourceTypes}
              size="small"
              style={{ width: '100%' }}
              value={sourceTypes}
            />
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              loading={uploading}
              pagination={{
                pageSize: 8,
                showLessItems: true,
                showSizeChanger: false,
                simple: true,
                size: 'small',
                position: ['bottomLeft'],
              }}
            />
            <Space wrap>
              <Button
                disabled={!hasSelected || !acceptFreeTextInput}
                onClick={() => handleSourceAction('create_summary')}
                size="small"
              >
                Summarize
              </Button>
              <Button
                disabled={!hasSelected || !acceptFreeTextInput}
                onClick={() => handleSourceAction('create_outline')}
                size="small"
              >
                Create outline
              </Button>
              <Button
                disabled={!hasSelected || !acceptFreeTextInput}
                onClick={() => handleSourceAction('create_study_note')}
                size="small"
              >
                Create study note
              </Button>
              <Button
                disabled={!hasSelected || !acceptFreeTextInput}
                onClick={() => handleSourceAction('create_flash_cards', { numberCards: 3 })}
                size="small"
              >
                Create flash cards
              </Button>
            </Space>
            <div style={{ marginTop: 8, textAlign: 'center' }}>
              <Upload
                name="upload"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {uploadButton}
              </Upload>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Dropdown arrow
                menu={{
                  items: [
                    {
                      icon: <YoutubeOutlined />,
                      key: 'youtube',
                      label: <NavLink>YouTube</NavLink>,
                    },
                  ],
                  onClick: handleAddResource,
                }}
                placement="bottom"
                trigger="click"
              >
                <Button style={{ width: 100 }}>Add resource</Button>
              </Dropdown>
            </div>
          </Flex>
        </Sider>
        <Sider
          collapsible
          collapsed={sessionCollapsed}
          collapsedWidth={0}
          trigger={null}
          style={{
            marginRight: sessionCollapsed ? 0 : 10,
          }}
          width={250}
          theme="light"
        >
          <Table
            rowSelection={sessionRowSelection}
            columns={sessionColumns}
            dataSource={sessionData}
            loading={chatSessionsLoading}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 4,
              showLessItems: true,
              simple: true,
              size: 'small',
            }}
          />
          <Space wrap style={{ marginTop: 8 }}>
            <Button type="primary" danger
              disabled={!hasSessionsSelected}
              onClick={handleDelete}
              size="small"
            >
              Delete
            </Button>
          </Space>
        </Sider>
        <Sider
          collapsible
          collapsed={settingsCollapsed}
          collapsedWidth={0}
          trigger={null}
          style={{
            marginRight: settingsCollapsed ? 0 : 10,
          }}
          width={250}
          theme="light"
        >
          <div style={{ padding: '16px 8px' }}>
            <div style={{ fontWeight: 600, marginBottom: 16, marginTop: 2, color: 'rgba(60, 61, 65, 0.88)', paddingLeft: 24 }}>
              Settings
            </div>
            <Form
              form={settingsForm}
              autoComplete="off"
              layout="vertical"
              style={{ padding: 24 }}
            >
              <Form.Item
                label="Search scope"
                name="searchScope"
                defaultValue="Chunk"
              >
                <Segmented
                  size="small"
                  style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                  options={[
                    {
                      label: 'Chunks',
                      value: 'Chunk',
                    },
                    {
                      label: 'Videos',
                      value: 'Video',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Conversation Style"
                name="conversationStyle"
                defaultValue="tell-me"
              >
                <Segmented
                  size="small"
                  style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                  options={[
                    {
                      label: 'Tell me',
                      value: 'tell',
                    },
                    {
                      label: 'Teach me',
                      value: 'teach',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Personality"
                name="personality"
              >
                <Select
                  size="small"
                  options={[
                    {
                      label: 'Barack Obama',
                      value: 'Barack Obama',
                    },
                    {
                      label: 'Donald J Trump',
                      value: 'Donald J Trump',
                    },
                    {
                      label: 'Simon Sinek',
                      value: 'Simon Sinek',
                    },
                    {
                      label: 'Steve Jobs',
                      value: 'Steve Jobs',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Selectable Conversations"
                name="selectable"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Form>
          </div>
        </Sider>
        <Sider
          collapsible
          collapsed={profilesCollapsed}
          collapsedWidth={0}
          trigger={null}
          style={{
            marginRight: profilesCollapsed ? 0 : 10,
          }}
          width={250}
          theme="light"
        >
          <div style={{ padding: '16px 8px' }}>
            <div style={{ fontWeight: 600, marginBottom: 16, marginTop: 2 }}>
              Speaker Profiles
            </div>
            <Space direction="vertical" size={24}>
              {currentSpeakers.map(p => (
                <Space direction="vertical" size={8}>
                  <div className="speaker">
                    {p.speaker}
                  </div>
                  <Space size="small">
                    <Link><LinkedinOutlined /></Link>
                    <Link><TwitterOutlined /></Link>
                    <Link>Substack</Link>
                  </Space>
                  <div style={{ marginTop: 5 }}>
                    <Button
                      type={activeSpeaker === p.speaker ? 'primary' : 'default'}
                      onClick={() => askSpeaker(p.speaker)}
                      size="small"
                    >
                      Ask me a question
                    </Button>
                  </div>
                </Space>
              ))}
              {!currentSpeakers.length ?
                <div style={{ width: 234 }}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
                : null
              }
              <Button
                disabled={!currentSpeakers.length}
                onClick={() => setActiveSpeaker(null)}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
        </Sider>
        <Sider
          collapsible
          collapsed={notesCollapsed}
          collapsedWidth={0}
          trigger={null}
          style={{
            marginRight: profilesCollapsed ? 0 : 10,
          }}
          width={250}
          theme="light"
        >
          <div style={{ padding: '16px 8px' }}>
            <div style={{ fontWeight: 600, marginBottom: 16, marginTop: 2 }}>
              Notes
            </div>
            <Space className="notes" direction="vertical" size={24}>
              <NoteCard onChange={handleSaveNote} />
              <NotesList
                data={notesData}
                loading={notesLoading}
                onSelect={() => { }}
                onDelete={handleNotesDelete}
              />
            </Space>
          </div>
        </Sider>
        <Content>
          <div className="tutor-content" style={{ display: 'flex' }}>
            <div className="submenu" style={{ minWidth: 100, marginTop: 11 }}>
              {isAdmin ?
                <>
                  <Button
                    type="text"
                    icon={<TeamOutlined />}
                    onClick={() => setPeopleCollapsed(cur => !cur)}
                  >
                    People
                  </Button>
                  <Button
                    type="text"
                    icon={<YoutubeOutlined />}
                    onClick={() => setCollapsed(cur => !cur)}
                  >
                    Sources
                  </Button>
                  <Button
                    type="text"
                    icon={<SettingOutlined />}
                    onClick={() => setSettingsCollapsed(cur => !cur)}
                  >
                    Settings
                  </Button>
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => setNotesCollapsed(cur => !cur)}
                  >
                    Notes
                  </Button>
                </>
                : null
              }
              <Button
                type="text"
                icon={<MessageOutlined />}
                onClick={() => setSessionCollapsed(cur => !cur)}
              >
                Sessions
              </Button>
              {/* <Button
                type="text"
                icon={<UserOutlined />}
                onClick={() => setProfilesCollapsed(cur => !cur)}
              >
                Profiles
              </Button> */}
              <Button
                type="text"
                icon={<MenuFoldOutlined />}
                onClick={collapseAll}
              >
                Close
              </Button>
            </div>
            <Chat
              acceptFreeTextInput={acceptFreeTextInput}
              enableActions={true}
              loading={loading}
              messages={messages}
              onSubmit={handleChatSubmit}
              placeholder={messages?.length ? 'Question...' : 'What can we help you with today...'}
              onGetQuiz={() => handleGetQuiz(3)}
              onReset={handleReset}
              onSave={handleSave}
              onShowCitation={showContent}
              score={score}
              user={currentUser}
              image={image}
              onCreateFlashCards={handleCreateFlashcards}
              onCreateMashup={handleCreateMashup}
              onCreateOutline={handleCreateOutline}
              onCreateStudyNote={handleCreateStudyNote}
              onCreateSummary={handleCreateSummary}
              onShowPeople={handleShowPeople}
              onShowPodcasts={handleShowPodcasts}
              selectable={selectable}
              selectMultiple={true}
              onSelected={(keys) => { console.log('keys:', keys); }}
              onCreateNote={handleSaveNote}
              isAdmin={isAdmin}
              breadcrumbs={breadcrumbs}
              firstSession={firstSessionRef.current}
              skip={skip}
              setSkip={setSkip}
              speakerSkip={speakerSkip}
              setSpeakerSkip={setSpeakerSkip}
            />
            {/* <div className="submenu" style={{ minWidth: 100, marginTop: 11 }}>
              <div style={{ marginLeft: -8 }}>
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={collapseAll}
                  style={{
                    fontSize: '14px',
                    width: 32,
                    height: 32,
                  }}
                />
                <span>Close all</span>
              </div>
            </div> */}
          </div>
        </Content>
      </Layout>
    </>
  )
}

const beforeUpload = (file) => {
  console.log('file:', file);

  const isCSV = file.type === 'text/csv';

  const isText = file.type === 'text/plain';

  const isZip = file.type === 'application/zip';

  const isPdf = file.type === 'application/pdf';

  const isWord = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const isJSON = file.type === 'application/json';

  if (!(isCSV || isText || isZip || isPdf || isWord || isExcel || isJSON)) {
    message.error('You may only upload a CSV, Text or Zip file.');
  }

  const isLt2M = file.size / 1024 / 1024 < 1000;

  if (!isLt2M) {
    message.error('File must be smaller than 1GB.');
  }

  return (isCSV || isText || isZip || isPdf || isWord || isExcel || isJSON) && isLt2M;
};

// https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 20);
};

const formatMessage = (m) => {
  if (m.role === 'assistant') {
    if (Array.isArray(m.content)) {
      return {
        ...m,
        key: uuidv4(),
        content: m.content.map(msg => {
          if (typeof msg === 'string') {
            return {
              content: msg,
              key: uuidv4(),
            }
          } else {
            return {
              ...msg,
              key: uuidv4(),
            }
          }
        }),
      };
    }
  }
  return {
    ...m,
    key: uuidv4(),
  };
};
