import React from 'react';
import { Button, Tooltip } from 'antd';

export default function Toolbar({ options, size = "middle" }) {

  return (
    <div className="toolbar">
      {options.map(o =>
        <Tooltip title={o.title}>
          <Button disabled={o.disabled} icon={o.icon} onClick={o.onClick} size={size} />
        </Tooltip>
      )}
    </div>
  )
}