import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Empty, Form, Input, Modal, Space, Switch, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import EditorJS from '@editorjs/editorjs';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ToolInput } from '../../components/ToolInput';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  createSectionAsync,
  getAssignmentReviewAsync,
  getSectionAsync,
  runToolAsync,
  selectSections,
  selectLoaded,
  selectResult,
  selectRunning,
  setResult,
  updateSectionAsync,
} from './sectionsSlice';

import { EDITOR_JS_TOOLS } from './tools';

const { TextArea } = Input;

const jsonToTextOpts = {
  color: true,  // Whether to apply colors to the output or not
  spacing: true,  // Whether to include spacing after colons or not
  seperator: ':',  // seperator. Default ':',
  squareBracketsForArray: false,  // Whether to use square brackets for arrays or not
  doubleQuotesForKeys: false,  // Whether to use double quotes for object keys or not
  doubleQuotesForValues: false,  // Whether to use double quotes for string values or not
  displayArrayAsList: true,
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

function generateUID() {
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

export function SectionForm() {

  const [open, setOpen] = useState(false);
  const [ready, setReady] = useState(false);

  const loaded = useSelector(selectLoaded);
  const running = useSelector(selectRunning);
  const sections = useSelector(selectSections);
  const result = useSelector(selectResult);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const instance = useRef(null);
  const state = useRef(null);

  const [form] = Form.useForm();
  const toolValue = Form.useWatch('tool', form);

  const correlationIdRef = useRef();
  const taskDoneCallbackRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const id = location.pathname.match(/\/sections\/(.*)/)[1];
  const isNew = id === 'new';
  const section = sections[id];

  const handleChange = async (api, event) => {
    if (Array.isArray(event) && event.length === 3) {
      if (event[2].type === 'block-changed') {
        const index = event[1].detail.index;
        const block = api.blocks.getBlockByIndex(index);
        const value = await block.save();
        const text = value.data.text;
        const i = text.indexOf('<mark class="cdx-marker">');
        if (i !== -1) {
          const j = text.indexOf('</mark>');
          state.current = { api, block, index, text, i, j };
        }
      }
    }
  };

  const isPrintable = (key) => key && (key.length || key === 'Unidentified');

  const handleKeydown = async (ev) => {
    if (state.current) {
      ev.stopImmediatePropagation();
      let { api, block, index, text, i, j } = state.current;
      state.current = null;
      if (!/\s|&nbsp;$/.test(text)) {
        text += ' ';
      }
      let caretpos = 0;
      if (ev.code === 'Tab') {
        text = text.slice(0, i) + text.slice(i + 25, j) + text.slice(j + 7);
        caretpos = i + (j - i - 25);
      } else if (isPrintable(ev.key)) {
        text = text.slice(0, i) + ev.key + text.slice(j + 7);
        caretpos = i + 1;
      }
      await api.blocks.update(block.id, { text });
      api.caret.setToBlock(index, null, caretpos);
    }
  };

  useEffect(() => {
    console.log('Creating new editor instance');
    setTimeout(() => {
      const editor = new EditorJS({
        onReady: () => {
          instance.current = editor;
          setReady(true);
        },
        onChange: handleChange,
        tools: EDITOR_JS_TOOLS,
        tunes: ['autocomplete'],
        autofocus: true,
      });
      // window.addEventListener('keydown', handleKeydown, { capture: true });
    }, 1000);

    return () => {
      console.log('Editor is unmounting');
      if (instance) {
        instance.current.destroy();
        instance.current = null;
      }
      // window.removeEventListener('keydown', handleKeydown, { capture: true });
    };
  }, []);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Section',
    }));
    if (!isNew) {
      dispatch(getSectionAsync(id));
    }
  }, []);

  useEffect(() => {
    if (correlationIdRef.current) {
      const section = Object.values(sections).find(a => a.correlationId === correlationIdRef.current);
      if (section) {
        if (taskDoneCallbackRef.current) {
          taskDoneCallbackRef.current();
          taskDoneCallbackRef.current = null;
        }
        correlationIdRef.current = null;
      }
    }
  }, [sections]);

  useEffect(() => {
    if (location.state && location.state.message) {
      messageApi.info({
        content: location.state.message,
        duration: 5,
      });
    }
  }, [location]);

  useEffect(() => {
    if (section && ready) {
      const contentVersions = section.content_versions;
      if (contentVersions?.length) {
        contentVersions.sort((a, b) => a.created > b.created ? -1 : 1);
        const { content } = contentVersions[0];
        let data;
        if (Array.isArray(content)) {
          data = {
            time: Date.now(),
            blocks: content.map(text => ({
              id: generateUID(),
              type: 'paragraph',
              data: { text },
            })),
            version: '2.8.1',
          };
        } else {
          data = {
            time: Date.now(),
            // blocks: [
            //   {
            //     id: generateUID(),
            //     type: 'paragraph',
            //     data: { text: content },
            //   }
            // ],
            blocks: content.split('\n').map(text => ({
              id: generateUID(),
              type: 'paragraph',
              data: { text },
            })),
            version: '2.8.1',
          };
        }
        instance.current.render(data);
      }
      if (section.result) {
        dispatch(setResult({ result: section.result }));
      }
    }
  }, [section, ready]);

  const returnToList = () => {
    navigate('/sections');
  };

  const handleRun = () => {
    // dispatch(getAssignmentReviewAsync({ section }));
    dispatch(runToolAsync({
      section,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const onFinish = async (values) => {
    const editorState = await instance.current.save();
    console.log('editorState:', editorState);
    const content = editorState.blocks.map(b => b.data.text).join('\n');
    const content_versions = [
      {
        content,
        created: new Date(),
      },
    ];
    const correlationId = uuidv4()
    if (isNew) {
      dispatch(createSectionAsync({
        correlationId,
        values: {
          ...values,
          content_versions,
          result,
          workspaceId: selectedWorkspace.id,
        },
      }));
    } else {
      dispatch(updateSectionAsync({
        correlationId,
        id,
        values: { ...values, content_versions, result },
      }));
    }
    correlationIdRef.current = correlationId;
    taskDoneCallbackRef.current = returnToList;
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleOpenReport = () => {
    setOpen(true);
  };

  const resultContent = useMemo(() => {
    if (result) {
      return result
        .reduce((a, r) => {
          a.push('# ' + r.title, r.content, '\n');
          return a;
        }, [])
        .join('\n');
    }
    return '';
  }, [result]);

  console.log('resultContent:', resultContent);

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <>
      {contextHolder}
      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        open={open}
        okText="OK"
        title="Report"
        width={'75%'}
      >
        <Markdown
          className="markdown"
          remarkPlugins={[remarkGfm]}
        >
          {resultContent}
        </Markdown>
      </Modal>
      <div style={{ background: '#f5f5f5', padding: '16px 0', height: '100%' }}>
        <Form
          form={form}
          {...layout}
          autoComplete="off"
          onFinish={onFinish}
          initialValues={section}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the section name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
          </Form.Item>
          <Form.Item
            label="Select Tool"
            name="tool"
          >
            <ToolInput allowedTools={['generate_report', 'summarize', 'suggest_learning_objectives', 'extract_ideas']} />
          </Form.Item>
          {toolValue === 'suggest_courses' || toolValue === 'suggest_learning_objectives' || toolValue === 'write_course_chapter' ?
            <>
              <Form.Item
                label="Query"
                name="query"
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
              </Form.Item>
              <Form.Item
                label="Target Var"
                name="targetVar"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Repeating"
                name="repeating"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </>
            : null
          }
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Space>
              <Button type="default" onClick={returnToList}>Cancel</Button>
              <Button type="primary" htmlType="submit">Save</Button>
              <Button type="default" loading={running} onClick={handleRun}>Run</Button>
              <Button
                type="primary"
                disabled={!result}
                loading={running}
                onClick={handleOpenReport}
              >
                Open Report
              </Button>
            </Space>
          </Form.Item>
          <div
            style={{
              color: section?.content_versions?.length ? 'inherit' : 'rgba(60, 61, 65, 0.45)',
              fontWeight: section?.content_versions?.length ? 600 : 400,
            }}
          >
            Content
          </div>
          {!section?.content_versions?.length ?
            <div style={{ width: 250 }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
            : null
          }
          <Form.Item>
            <div id="editorjs" style={{ padding: '24px 16px 24px 54px' }}></div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
