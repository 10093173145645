import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space, Transfer, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  getOntologiesAsync,
  selectLoading as selectOntologiesLoading,
  selectOntologies,
} from '../ontology/ontologiesSlice';

import {
  createMetricSetAsync,
  getMetricSetAsync,
  selectMetricSets,
  selectLoaded,
  updateMetricSetAsync,
} from './metricSetsSlice';
import {
  getDomainMetricsAsync,
  resetMetrics,
  selectMetrics,
} from './metricsSlice'

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

function TransferInput({ dataSource, onChange, value }) {

  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    onChange(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <Transfer
      dataSource={dataSource}
      titles={['Available', 'Selected']}
      targetKeys={value}
      selectedKeys={selectedKeys}
      onChange={handleChange}
      onSelectChange={onSelectChange}
      render={(item) => item.title}
    />
  );
}

export function MetricSetForm() {

  const loaded = useSelector(selectLoaded);
  const metricSets = useSelector(selectMetricSets);
  const metrics = useSelector(selectMetrics);
  const ontologiesLoading = useSelector(selectOntologiesLoading);
  const ontologies = useSelector(selectOntologies);

  const [form] = Form.useForm();
  const domainValue = Form.useWatch('domain', form);

  const correlationIdRef = useRef();
  const taskDoneCallbackRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  const id = location.pathname.match(/\/metric-sets\/(.*)/)[1];
  const isNew = id === 'new';
  const metricSet = metricSets[id];

  const domainOptions = useMemo(() => {
    const list = Object.values(ontologies).map(o => ({
      label: o.domain,
      value: o.id,
    }));
    list.sort((a, b) => a.label < b.label ? -1 : 1);
    return list;
  }, [ontologies]);

  const metricOptions = useMemo(() => {
    const list = Object.values(metrics).map(m => ({
      key: m.id,
      title: m.name,
      description: m.description,
    }));
    list.sort((a, b) => a.title < b.title ? -1 : 1);
    return list;
  }, [metrics]);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Metric Set',
    }));
    dispatch(resetMetrics());
    if (!isNew) {
      dispatch(getMetricSetAsync(id));
    }
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getOntologiesAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (domainValue && selectedWorkspace) {
      dispatch(getDomainMetricsAsync({
        domainValue,
        workspaceId: selectedWorkspace.id,
      }));
    }
  }, [domainValue, selectedWorkspace]);

  useEffect(() => {
    if (correlationIdRef.current) {
      const metricSet = Object.values(metricSets).find(a => a.correlationId === correlationIdRef.current);
      if (metricSet) {
        if (taskDoneCallbackRef.current) {
          taskDoneCallbackRef.current();
          taskDoneCallbackRef.current = null;
        }
        correlationIdRef.current = null;
      }
    }
  }, [metricSets]);

  useEffect(() => {
    if (location.state && location.state.message) {
      messageApi.info({
        content: location.state.message,
        duration: 5,
      });
    }
  }, [location]);

  const returnToList = () => {
    navigate('/metric-sets');
  };

  const onFinish = (values) => {
    const correlationId = uuidv4();
    if (isNew) {
      dispatch(createMetricSetAsync({
        correlationId,
        values: { ...values, workspaceId: selectedWorkspace.id },
      }));
    } else {
      dispatch(updateMetricSetAsync({ correlationId, id, values }));
    }
    correlationIdRef.current = correlationId;
    taskDoneCallbackRef.current = returnToList;
  };

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <div style={{ marginTop: 20 }}>
          <Form
            form={form}
            {...layout}
            autoComplete="off"
            onFinish={onFinish}
            initialValues={metricSet}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter a metric name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
            >
              <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
            </Form.Item>
            <Form.Item
              label="Domain"
              name="domain"
              rules={[
                {
                  required: true,
                  message: "Please select the domain",
                },
              ]}
            >
              <Select allowClear
                loading={ontologiesLoading}
                options={domainOptions}
                optionFilterProp="label"
                placeholder="Select domain"
              />
            </Form.Item>
            <Form.Item
              label="Metrics"
              name="metrics"
              rules={[
                {
                  required: true,
                  message: "Please select one or more metrics",
                },
              ]}
            >
              <TransferInput dataSource={metricOptions} />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
              <Space>
                <Button type="default" onClick={returnToList}>Cancel</Button>
                <Button type="primary" htmlType="submit">Save</Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
