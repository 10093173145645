import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { EditableTable } from '../../components/EditableTable';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  getDataSource,
  getDefaultColumns,
} from '../../utils/graphUtils';

import {
  getNodeOptionsAsync,
  getNodesMetadataAsync,
  runGraphQuery,
  selectGraphs,
  selectNodeOptions,
  selectNodesMetadata,
} from '../graph/graphSlice';

export function DefinedView() {

  const [value, setValue] = useState({});

  // console.log('value:', value);

  const graphs = useSelector(selectGraphs);
  const nodeOptions = useSelector(selectNodeOptions);
  const nodesMetadata = useSelector(selectNodesMetadata);

  const dispatch = useDispatch();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);
  const correlationIdRef = useRef(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const selectedTypes = params.get('types')?.split(',');
  console.log('selectedTypes:', selectedTypes);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: params.get('title'),
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const correlationId = uuidv4();
      const workspaceId = selectedWorkspace.id;
      const domain = params.get('domain');
      dispatch(getNodeOptionsAsync({ workspaceId }));
      dispatch(getNodesMetadataAsync({ workspaceId, domain }));
      dispatch(runGraphQuery({
        correlationId,
        domain,
        selectedTypes,
        workspaceId,
      }));
      correlationIdRef.current = correlationId;
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    const graph = graphs[correlationIdRef.current];
    if (graph) {
      const dataSource = getDataSource(graph);
      const defaultColumns = getDefaultColumns(graph);
      setValue(cur => ({
        ...cur,
        dataSource,
        defaultColumns,
      }));
    }
  }, [graphs]);

  if (!value.defaultColumns) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <div style={{ height: 'calc(100vh - 168px)', overflow: 'auto' }}>
      <EditableTable
        dataSource={value.dataSource}
        defaultColumns={value.defaultColumns}
        fixed={true}
        tableType="cell-list"
        tableView="nodes"
        nodesMetadata={nodesMetadata}
        nodeOptions={nodeOptions}
      />
    </div>
  );
}