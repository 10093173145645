import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Descriptions,
  Drawer,
  Flex,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Popover,
  Segmented,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import {
  ApartmentOutlined,
  DeleteColumnOutlined,
  DeleteOutlined,
  DeleteRowOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  ExpandOutlined,
  FormOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MergeOutlined,
  MinusOutlined,
  NodeIndexOutlined,
  PlusOutlined,
  SubnodeOutlined,
} from '@ant-design/icons';
import { GraphCanvas, RadialMenu, useSelection } from 'reagraph';
import YouTube from 'react-youtube';
import isEmpty from 'lodash.isempty';
import lowerCase from 'lodash.lowercase';
import useLocalStorageState from 'use-local-storage-state';

import AddEdgeModal from '../components/AddEdgeModal';
import AddNodeInstanceModal from '../components/AddNodeInstanceModal';
import NodeInstanceForm from '../components/NodeInstanceForm';
import OntologyViewModal from '../components/OntologyViewModal';
import Toolbar from '../components/Toolbar';
import { MyIcon } from '../features/ontology/icons';

import { CypherQueryBuilder } from './CypherQueryBuilder';
import { EditableTable } from './EditableTable';
import MergeNodesModal from './MergeNodesModal';

const { Search, TextArea } = Input;
const { Content, Sider } = Layout;

const FEATURE_FLAGS = {
  'tableContent': 0,
};

const theme = {
  canvas: { background: '#FBFBFB' },
  node: {
    fill: '#7CA0AB',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.2,
    label: {
      color: '#2A6475',
      stroke: '#fff',
      activeColor: '#1DE9AC'
    },
    subLabel: {
      color: '#ddd',
      stroke: 'transparent',
      activeColor: '#1DE9AC'
    }
  },
  lasso: {
    border: '1px solid #55aaff',
    background: 'rgba(75, 160, 255, 0.1)'
  },
  ring: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  edge: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475',
      activeColor: '#1DE9AC',
      fontSize: 6
    }
  },
  arrow: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  cluster: {
    stroke: '#D8E6EA',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475'
    }
  }
};

const opts = {
  height: '112.5',
  width: '200',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    rel: 0,
  },
};

export function GraphView2({
  graphLoading,
  nodeOptions,
  nodesMetadata,
  relationshipsMetadata,
  onAddColumn,
  onChange,
  onSaveProperty,
  value,
  onCreateEdge,
  onCreateSession,
  onDeleteEdge,
  onDeleteNode,
  onDeleteSession,
  onExpandNode,
  onMergeNodes,
  onReset,
  onSaveEdge,
  onSaveNode,
  onSelectSession,
  ontologiesLoading,
  ontologyOptions,
  selectedOntology,
  selectedSession,
  sessionsLoading,
  sessionData,
  nodeType,
  title,
}) {
  /*
    onChange:
      fetchType: oneof 'search' | 'query' | 'cypher'
      layout: oneof 'graph' | 'table'
      searchQuery: search string (antd select)
      query: state: { currentState: List[StateObject], tags: List[str] }, queries: List[str] (CypherQueryBuilder) filters: List[str], params: Dict[str, Any] (EditableTable)
      cypherQuery: cypher string
      tableView: oneof 'nodes' | 'edges'
      selectAll: boolean
      selectedTypes: Dict[node type str, boolean]
      page: int
      pageSize: int
      tableType: oneof 'cell-list' | 'adjacency-matrix'
      tableParams: {
        pagination: {
          current: int,
          pageSize: int,
          hideOnSinglePage: boolean,
          position: List[str] e.g. ['topLeft'],
          size: str e.g. 'small',
        },
        filters: {},
      }
      nodeStyle: oneof 'color' | 'icons'
  */

  const colorMap = (value.legend || []).reduce((a, n) => {
    a[n[0]] = n[1];
    return a;
  }, {});

  const [activeEdge, setActiveEdge] = useState(null);
  const [addEdgeModalOpen, setAddEdgeModalOpen] = useState(false);
  const [addNodeInstanceModalOpen, setAddNodeInstanceModalOpen] = useState(false);
  const [allowedEdgeTypes, setAllowedEdgeTypes] = useState(null);
  const [anchorNode, setAnchorNode] = useState({ type: null });
  const [collapsed, setCollapsed] = useState([]);
  const [editing, setEditing] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [mergeNodeType, setMergeNodeType] = useState(null);
  const [mergeNodesModalOpen, setMergeNodesModalOpen] = useState(false);
  const [newNode, setNewNode] = useState(null);
  const [ontologyOpen, setOntologyOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectAllState, setSelectAllState] = useState(1);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedSessionRowKeys, setSelectedSessionRowKeys] = useState([]);
  const [sessionsCollapsed, setSessionsCollapsed] = useLocalStorageState('graph-sessions-collapsed', { default: true });

  const cypherInput = useRef();
  const graphRef = useRef();
  const nodeRef = useRef(new Map());

  const [nodeForm] = Form.useForm();
  const [sessionForm] = Form.useForm();

  const handleSelectSession = (id) => {
    onSelectSession(id);

    // TODO - not working
    // setSelectedSessionRowKeys([]);
  };

  const sessionColumns = [
    {
      title: 'Select Scene',
      dataIndex: 'name',
      width: '100%',
      render: (_, { key, name }) => (
        <Link onClick={() => handleSelectSession(key)}
          style={{ color: selectedSession?.id === key ? '#177ddc' : 'inherit' }}
        >
          {name}
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (newNode) {
      const { name, type } = newNode;
      const match = value.graph.nodes.find(n => n.type === type && n.label === name);
      if (match) {
        setNewNode(null);
        setTimeout(() => {
          graphRef.current?.fitNodesInView();
        }, 1000);
      }
    }
  }, [value.graph]);

  useEffect(() => {
    setSearchText(value.searchQuery);
  }, [value.searchQuery]);

  useEffect(() => {
    if (nodeType) {
      setSessionsCollapsed(true);
    }
  }, [nodeType]);

  const { selections, clearSelections, actives, onNodeClick, onCanvasClick } = useSelection({
    ...value.graph,
    ref: graphRef,
    type: 'multiModifier',
    pathSelectionType: 'all',
  });

  const myactives = [...actives];
  if (activeEdge) {
    myactives.push(activeEdge);
  }

  const validateMergeNodes = () => {
    let valid = false;
    if (selections.length < 2) {
      return { valid, message: 'You must select at least two nodes' };
    }
    const types = {};
    for (const id of selections) {
      const node = value.graph.nodes.find(n => n.id === id);
      types[node.type] = true;
    }
    const keys = Object.keys(types);
    valid = keys.length === 1;
    if (valid) {
      return { nodeType: keys[0], valid };
    }
    return { valid, message: 'The selected nodes must be the same type' };
  };

  const validateRelatedNodes = () => {
    if (selections.length !== 2) {
      return { valid: false, message: 'Select two nodes only' };
    }
    const nodes = value.graph.nodes;
    const node1 = nodes.find(n => n.id === selections[0]);
    const node2 = nodes.find(n => n.id === selections[1]);
    let edges = nodesMetadata[node1.type].edges;
    for (let k in edges) {
      if (edges[k] === node2.type) {
        return {
          relType: k,
          source: {
            id: node1.id,
            type: node1.type,
          },
          target: {
            id: node2.id,
            type: node2.type,
          },
          valid: true,
        };
      }
    }
    edges = nodesMetadata[node2.type].edges;
    for (let k in edges) {
      if (edges[k] === node1.type) {
        return {
          relType: k,
          source: {
            id: node2.id,
            type: node2.type,
          },
          target: {
            id: node1.id,
            type: node1.type,
          },
          valid: true,
        };
      }
    }
    return { valid: false, message: 'There is no defined relationship between these two nodes in the ontology' };
  };

  const validateExistingEdge = () => {
    if (selections.length !== 2) {
      return { valid: false, message: 'Select two nodes only' };
    }
    const { edges, nodes } = value.graph;
    const node1 = nodes.find(n => n.id === selections[0]);
    const node2 = nodes.find(n => n.id === selections[1]);
    const nodeIds = [node1.id, node2.id];
    const existingEdges = edges.filter(e => {
      return nodeIds.includes(e.source) || nodeIds.includes(e.target);
    });
    if (!existingEdges.length) {
      return { valid: false, message: 'There are no existing edges to delete' };
    }
    return { valid: true, existingEdges };
  };

  const handleMergeNodes = ({ values }) => {
    onMergeNodes({
      nodeType: mergeNodeType,
      nodeIds: selections,
      target: values.mergeNode,
    });
    setMergeNodesModalOpen(false);
    setMergeNodeType(null);
    clearSelections();
  };

  const selectedNodeOptions = useMemo(() => {
    if (selections.length) {
      return selections
        .map(id => value.graph.nodes.find(n => n.id === id))
        .filter(v => v)
        .map(n => ({
          label: n.label,
          value: n.id,
        }));
    }
    return [];
  }, [selections]);

  const handleAddEdge = () => {
    const { relType, source, target, valid, message } = validateRelatedNodes();
    if (!valid) {
      alert(message);
      return;
    }
    // I'm assuming that two nodes can have only one relationship 
    // in one direction
    onCreateEdge({ relType, source, target });
    clearSelections();
  };

  const handleAddNodeInstance = ({ data }) => {
    setAnchorNode(data);
    setAddNodeInstanceModalOpen(true);
  };

  const handleAddEdgeModalCancel = () => {
    setAddEdgeModalOpen(false);
    setAllowedEdgeTypes(null);
  };

  const handleAddEdgeSubmit = (data) => {
    onSaveEdge(data.values);
    setAddEdgeModalOpen(false);
    setAllowedEdgeTypes(null);
  };

  const handleAddNodeInstanceModalCancel = () => {
    setAddNodeInstanceModalOpen(false);
    setAnchorNode({ type: null });
  };

  const handleAddNodeInstanceSubmit = (values) => {
    setAddNodeInstanceModalOpen(false);
    setAnchorNode({ type: null });
    setNewNode(values.values);
    onSaveNode(values);
  };

  const handleEditNodeInstance = ({ data }) => {
    setTimeout(() => {
      setSelectedNodeId(data.id);
      setEditing(true);
    }, 200);
  };

  const handleCanvasClick = (ev) => {
    setSelectedNodeId(null);
    onCanvasClick(ev);
  };

  const handleDeleteNodeInstance = (data) => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          Deleting {data.type} : {data.label}
        </div>
      ),
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        onDeleteNode(data);
      },
    });
  };

  const handleDeleteEdge = () => {
    const { valid, message, existingEdges } = validateExistingEdge();
    if (!valid) {
      alert(message);
      return;
    }
    if (existingEdges.length === 1) {
      const { label, source, target } = existingEdges[0];
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            Deleting {label}
          </div>
        ),
        cancelText: 'No',
        okText: 'Yes',
        onOk: () => {
          onDeleteEdge({ relType: label, source, target });
        },
      });
    } else {
      // select which
    }
  };

  const handleContract = ({ data }) => {
    const nodeId = data.id;
    if (!collapsed.includes(nodeId)) {
      setCollapsed([...collapsed, nodeId]);
    }
  };

  const handleCancelCreateSession = () => {
    sessionForm.resetFields();
  };

  const handleCreateSession = (values) => {
    onCreateSession(values);
    sessionForm.resetFields();
  };

  const handleDeleteSession = () => {
    onDeleteSession(selectedSessionRowKeys);

    // TODO - not working
    // setSelectedSessionRowKeys([]);
  };

  const handleExpand = ({ data }) => {
    const nodeId = data.id;
    if (!expanded.includes(nodeId)) {
      onExpandNode({ nodeId: data.id, nodeType: data.type });
      setExpanded([...expanded, nodeId]);
    }
    setCollapsed(collapsed.filter(id => id !== nodeId));
  };

  const handleMerge = () => {
    const { nodeType, valid, message } = validateMergeNodes();
    if (!valid) {
      alert(message);
      return;
    }
    setMergeNodeType(nodeType);
    setMergeNodesModalOpen(true);
  };

  const handleNodeDoubleClick = (node) => {
    if (selectedNodeId === node.id) {
      setSelectedNodeId(null);
      clearSelections();
    } else {
      setSelectedNodeId(node.id);
    }
  };

  const handleSave = (values) => {
    // console.log('values:', values);
  };

  const handleSaveNode = async () => {
    try {
      const values = await nodeForm.validateFields();
      onSaveNode({ values: { ...values, id: selectedNode.id } });
      onDrawerClose();
    } catch (err) {
      console.error('Error saving node');
    }
  };

  const handleSearchChange = (ev) => {
    setSearchText(ev.target.value);
  };

  const handleSelectAllChange = (ev) => {
    const { checked } = ev.target;
    setSelectAllState(checked ? 1 : -1);
    onChange({ selectAll: checked });
  };

  const handleTypeSelection = (label, checked) => {
    setSelectAllState(0);
    onChange({ selectedTypes: { ...value.selectedTypes, [label]: checked } })
  };

  const onDrawerClose = () => {
    setSelectedNodeId(null);
    clearSelections();
    setEditing(false);
  };

  const onReady = (ev) => {
    // setPlayer(ev.target);
  };

  const selectedNode = useMemo(() => {
    if (selectedNodeId && value?.graph) {
      const { graph } = value;
      const nodeMap = graph.nodes.reduce((a, node) => {
        a[node.id] = node;
        return a;
      }, {});
      const node = nodeMap[selectedNodeId];
      if (node) {
        const properties = Object.entries(node.data || {})
          .filter(([k, v]) => k !== 'id' && typeof v !== 'undefined')
          .map(([k, v]) => {
            if (typeof v === 'boolean') {
              return [k, v ? 'true' : 'false'];
            }
            return [k, v];
          });

        properties.sort((a, b) => a[0] < b[0] ? -1 : 1);
        const nodeAsSourceEdges = [];
        const nodeAsTargetEdges = [];
        for (const edge of graph.edges) {
          if (edge.source === node.id) {
            const targetNode = nodeMap[edge.target];
            nodeAsSourceEdges.push(({
              type: edge.label,
              id: edge.target,
              target: targetNode.type,
              name: targetNode.label,
              targetData: nodeMap[edge.target].data,
            }));
          }
          if (edge.target === node.id) {
            const sourceNode = nodeMap[edge.source];
            nodeAsTargetEdges.push({
              type: edge.label,
              id: edge.source,
              source: sourceNode.type,
              name: sourceNode.label,
              sourceData: nodeMap[edge.source].data,
            });
          }
        }
        return {
          id: node.id,
          type: node.type,
          label: node.label,
          properties,
          nodeAsSourceEdges,
          nodeAsTargetEdges,
        };
      }
    }
    return null;
  }, [selectedNodeId]);

  const getValue = (n) => {
    if (!n) {
      return {};
    }
    return {
      ...(n.properties || []).reduce((a, [k, v]) => {
        a[k] = v;
        return a;
      }, {}),
      id: n.id,
      name: n.label,
      type: n.type,
    }
  };

  const hasSessionsSelected = selectedSessionRowKeys.length > 0;

  const onSelectSessionChange = (newSelectedRowKeys) => {
    setSelectedSessionRowKeys(newSelectedRowKeys);
  };

  const sessionRowSelection = {
    selectedSessionRowKeys,
    onChange: onSelectSessionChange,
  };

  // Remember, `collapsedNodeIds` only collapses outgoing relationships
  // and connected nodes not connected to anything else

  return (
    <>
      <AddNodeInstanceModal
        onCancel={handleAddNodeInstanceModalCancel}
        onSubmit={handleAddNodeInstanceSubmit}
        open={addNodeInstanceModalOpen}
        schema={nodesMetadata}
        anchorNode={anchorNode}
      />
      <AddEdgeModal
        onCancel={handleAddEdgeModalCancel}
        onSubmit={handleAddEdgeSubmit}
        open={addEdgeModalOpen}
        schema={relationshipsMetadata}
        allowedEdgeTypes={allowedEdgeTypes}
      />
      <MergeNodesModal
        open={mergeNodesModalOpen}
        nodeOptions={selectedNodeOptions}
        onCancel={() => setMergeNodesModalOpen(false)}
        onSubmit={handleMergeNodes}
      />
      <OntologyViewModal
        ontology={selectedOntology}
        onCancel={() => setOntologyOpen(false)}
        open={ontologyOpen}
      />
      <Layout>
        <Sider
          collapsible
          collapsed={sessionsCollapsed}
          collapsedWidth={0}
          trigger={null}
          style={{ background: '#FBFBFB' }}
          width={250}
          theme="light"
        >
          <div style={{ width: 234 }}>
            <div style={{ fontWeight: 600, marginBottom: 18, marginTop: 2, color: 'rgba(60, 61, 65, 0.88)' }}>
              Save Scene
            </div>
            <Form
              autoComplete="off"
              form={sessionForm}
              onFinish={handleCreateSession}
            >
              <Form.Item
                name="name"
                style={{ marginBottom: 8 }}
              >
                <Input placeholder="Enter scene name" />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button size="small" type="default"
                    onClick={handleCancelCreateSession}
                  >
                    Cancel
                  </Button>
                  <Button size="small" type="primary" htmlType="submit"
                    disabled={!value.graph?.nodes.length}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            <Table
              rowSelection={sessionRowSelection}
              columns={sessionColumns}
              dataSource={sessionData}
              loading={sessionsLoading}
              pagination={{
                pageSize: 8,
                showLessItems: true,
                simple: true,
                size: 'small',
              }}
              style={{ marginBottom: 8 }}
            />
            <Space wrap>
              <Button
                danger
                disabled={!hasSessionsSelected}
                onClick={handleDeleteSession}
                size="small"
                type="primary"
              >
                Delete
              </Button>
              <Button
                disabled={selectedSessionRowKeys.length !== 1}
                onClick={() => handleSelectSession(selectedSessionRowKeys[0])}
                size="small"
                type="primary"
              >
                Show
              </Button>
            </Space>
          </div>
        </Sider>
        <Content>
          <Flex vertical gap={16} style={{ height: 'calc(100vh - 118px)', width: '100%' }}>
            {!nodeType ?
              <>
                <Flex align="center" gap={8}>
                  <Tooltip title="Scenes">
                    <Button
                      size="small"
                      type="text"
                      icon={sessionsCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                      onClick={() => setSessionsCollapsed(cur => !cur)}
                    />
                  </Tooltip>
                  <Segmented
                    onChange={(value) => onChange({ fetchType: value })}
                    value={value.fetchType}
                    size="small"
                    style={{ background: 'rgba(0, 0, 0, 0.25)', width: 276 }}
                    defaultValue={'search'}
                    options={[
                      {
                        label: 'Search',
                        value: 'search',
                      },
                      {
                        label: 'Query Builder',
                        value: 'query',
                      },
                      {
                        label: 'Cypher Query',
                        value: 'cypher',
                      },
                    ]}
                  />
                  <Button
                    size="small"
                    onClick={onReset}
                    style={{ width: 109 }}
                  >
                    Reset Scene
                  </Button>
                  <div style={{ flex: 1 }}></div>
                  <Select
                    allowClear
                    loading={ontologiesLoading}
                    onChange={value => onChange({ ontology: value })}
                    options={ontologyOptions}
                    placeholder="Select ontology"
                    size="small"
                    style={{ width: 183 }}
                    value={value.ontology}
                  />
                  <Tooltip title="Inspect Ontology">
                    <Button
                      size="small"
                      type="default"
                      icon={<ApartmentOutlined />}
                      disabled={!value.ontology}
                      onClick={() => setOntologyOpen(true)}
                    />
                  </Tooltip>
                  <Segmented
                    onChange={(value) => onChange({ layout: value })}
                    value={value.layout}
                    size="small"
                    style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                    options={[
                      {
                        label: 'Graph',
                        value: 'graph',
                      },
                      {
                        label: 'Table',
                        value: 'table',
                      },
                    ]}
                  />
                </Flex>
                <Flex gap={8} style={{ minHeight: 88 }}>
                  {value.fetchType === 'search' ?
                    <>
                      <Search allowClear
                        value={searchText}
                        onChange={handleSearchChange}
                        onSearch={(value) => onChange({ searchQuery: value })}
                        placeholder="Enter natural language query..."
                        style={{ width: 425 }}
                      />
                      <Tooltip title="Include chunks in search scope">
                        <Flex gap={8} style={{ marginTop: 5 }}>
                          <Switch
                            checked={value.searchChunks}
                            onChange={(value) => onChange({ searchChunks: value })}
                          />
                          <div style={{ lineHeight: '22px' }}>Chunks</div>
                        </Flex>
                      </Tooltip>
                    </>
                    : null
                  }
                  {value.fetchType === 'query' && !isEmpty(nodesMetadata) ?
                    <CypherQueryBuilder
                      nodesMetadata={nodesMetadata}
                      relationshipsMetadata={relationshipsMetadata}
                      onQuery={(value) => onChange({ query: value })}
                      state={value.query?.state}
                    />
                    : null
                  }
                  {value.fetchType === 'cypher' ?
                    <Flex vertical gap={8}>
                      <TextArea
                        ref={cypherInput}
                        autoSize={{ minRows: 1, maxRows: 14 }}
                        placeholder="Enter cypher query..."
                        style={{ borderRadius: 16, width: 500 }}
                      />
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => onChange({ cypherQuery: cypherInput.current.resizableTextArea.textArea.value })}
                      >
                        Query
                      </Button>
                    </Flex>
                    : null
                  }
                  <div style={{ flex: 1 }}></div>
                  {value.layout === 'graph' ?
                    <>
                      <Toolbar
                        options={[
                          {
                            icon: <PlusOutlined />,
                            title: 'Add Node',
                            onClick: () => handleAddNodeInstance({ data: { type: null } }),
                          },
                          {
                            disabled: selections.length !== 1,
                            icon: <FormOutlined />,
                            title: 'Edit Node',
                            onClick: () => handleEditNodeInstance({ data: { id: selections[0] } }),
                          },
                          {
                            disabled: selections.length !== 1,
                            icon: <DeleteOutlined />,
                            title: 'Delete Node',
                            onClick: handleDeleteEdge,
                          },
                          {
                            disabled: selections.length !== 2,
                            icon: <SubnodeOutlined />,
                            title: 'Add Edge',
                            onClick: () => handleAddEdge({ data: { type: null } }),
                          },
                          {
                            disabled: !activeEdge,
                            icon: <EditOutlined />,
                            title: 'Edit Edge',
                            onClick: () => handleAddEdge({ data: { type: null } }),
                          },
                          {
                            disabled: !activeEdge,
                            icon: <DeleteRowOutlined />,
                            title: 'Delete Edge',
                            onClick: handleDeleteEdge,
                          },
                          {
                            disabled: !(selections.length > 1),
                            icon: <MergeOutlined />,
                            title: 'Merge Nodes',
                            onClick: handleMerge,
                          },
                        ]}
                      />
                      <Popover
                        trigger="click"
                        content={
                          <Flex vertical gap={8}>
                            <Segmented
                              onChange={(value) => onChange({ labelType: value })}
                              value={value.labelType}
                              style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                              options={[
                                {
                                  label: 'Node label',
                                  value: 'auto',
                                },
                                {
                                  label: 'Plus Edge',
                                  value: 'all',
                                },
                              ]}
                            />
                            <Segmented
                              onChange={(value) => onChange({ nodeStyle: value })}
                              value={value.nodeStyle}
                              style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                              options={[
                                {
                                  label: 'Color',
                                  value: 'color',
                                },
                                {
                                  label: 'Icon',
                                  value: 'icon',
                                },
                              ]}
                            />
                          </Flex>
                        }
                        style={{ width: 200 }}
                      >
                        <Button>Styling</Button>
                      </Popover>
                    </>
                    :
                    FEATURE_FLAGS['tableContent'] ?
                      <Segmented
                        onChange={(value) => onChange({ tableView: value })}
                        value={value.tableView}
                        style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                        options={[
                          {
                            label: 'Nodes',
                            value: 'nodes',
                          },
                          {
                            label: 'Relationships',
                            value: 'edges',
                          },
                        ]}
                      />
                      : null
                  }
                </Flex>
              </>
              : null
            }
            {value.layout === 'graph' ?
              <>
                {!value.ontology ?
                  <div style={{ paddingTop: 26, paddingLeft: 9, fontWeight: 500 }}>
                    Select an ontology to view the graph
                  </div>
                  : null
                }
                {value.ontology && graphLoading ?
                  <div style={{ paddingTop: 26, paddingLeft: 9, fontWeight: 500 }}>
                    Loading data...
                  </div>
                  : null
                }
                {value.ontology ?
                  <>
                    <div style={{ flex: 1, position: 'relative' }}>
                      <div className="graph-legend">
                        <fieldset>
                          <legend>Legend</legend>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <label></label>
                            <div className="color-circle" style={{ backgroundColor: '#FBFBFB' }} />
                            <Checkbox
                              checked={selectAllState === 1}
                              indeterminate={selectAllState == 0}
                              onChange={handleSelectAllChange}
                            />
                          </div>
                          {value.legend?.map(([label, color, icon]) => (
                            <div key={label} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                              <label>{label}</label>
                              {value.nodeStyle === 'icon' && icon ?
                                <div className="color-circle"><MyIcon type={icon} /></div>
                                :
                                <div className="color-circle" style={{ backgroundColor: color }} />
                              }
                              <Checkbox
                                checked={value.selectedTypes?.[label]}
                                onChange={(ev) => handleTypeSelection(label, ev.target.checked)}
                              />
                            </div>
                          ))}
                        </fieldset>
                      </div>
                      {value.graph?.nodes?.length ?
                        <>
                          <GraphCanvas
                            draggable
                            {...value.graph}
                            ref={graphRef}
                            collapsedNodeIds={collapsed}
                            selections={selections}
                            actives={myactives}
                            onCanvasClick={ev => {
                              setActiveEdge(null);
                              handleCanvasClick(ev);
                            }}
                            onEdgeClick={edge => {
                              setActiveEdge(edge.id);
                            }}
                            onNodeClick={ev => {
                              setActiveEdge(null);
                              onNodeClick(ev);
                            }}
                            onNodeDoubleClick={handleNodeDoubleClick}
                            theme={theme}
                            labelType={value.labelType}
                            edgeLabelPosition="inline"
                            layoutOverrides={{
                              getNodePosition: id => {
                                return nodeRef.current.get(id)?.position;
                              }
                            }}
                            onNodeDragged={node => {
                              nodeRef.current.set(node.id, node);
                            }}
                            contextMenu={({ data, onClose }) => (
                              <RadialMenu
                                onClose={onClose}
                                items={[
                                  {
                                    label: 'Add',
                                    onClick: () => {
                                      handleAddNodeInstance(data);
                                      onClose();
                                    },
                                  },
                                  {
                                    label: 'Edit',
                                    onClick: () => {
                                      handleEditNodeInstance(data);
                                      onClose();
                                    }
                                  },
                                  {
                                    // disabled: expanded.includes(data.data.id) && !collapsed.includes(data.data.id),
                                    label: 'Expand',
                                    onClick: () => {
                                      handleExpand(data);
                                      onClose();
                                    }
                                  },
                                  {
                                    label: 'Delete',
                                    onClick: () => {
                                      handleDeleteNodeInstance(data);
                                      onClose();
                                    }
                                  },
                                  {
                                    // disabled: collapsed.includes(data.data.id),
                                    label: 'Contract',
                                    onClick: () => {
                                      handleContract(data);
                                      onClose();
                                    }
                                  },
                                ]}
                              />
                            )}
                          />
                          <div style={{ position: 'absolute', bottom: 0, left: 0 }}>
                            <Toolbar size="small"
                              options={[
                                {
                                  icon: <PlusOutlined />,
                                  title: 'Zoom in',
                                  onClick: () => graphRef.current?.zoomIn(),
                                },
                                {
                                  icon: <MinusOutlined />,
                                  title: 'Zoom out',
                                  onClick: () => graphRef.current?.zoomOut(),
                                },
                                {
                                  icon: <ExpandOutlined />,
                                  title: 'Fit in view',
                                  onClick: () => graphRef.current?.fitNodesInView(),
                                },
                              ]}
                            />
                          </div>
                        </>
                        : null
                      }
                    </div>
                    <Flex justify="end">
                      <Pagination
                        hideOnSinglePage
                        pageSize={value.pageSize}
                        page={value.page}
                        total={value.total}
                        onChange={(page, pageSize) => onChange({ page, pageSize })}
                        size="small"
                        pageSizeOptions={[100, 500, 1000, 10000]}
                        defaultPageSize={1000}
                      />
                    </Flex>
                  </>
                  : null
                }
              </>
              : null
            }
            {value.layout === 'table' && value.dataSource ?
              <EditableTable
                onAddColumn={onAddColumn}
                onSaveProperty={onSaveProperty}
                // onAdd={handleAdd}
                // onDelete={handleDelete}
                onSave={handleSave}
                nodeOptions={nodeOptions}
                nodesMetadata={nodesMetadata}
                onChange={onChange}
                colorMap={colorMap}
                nodeType={nodeType}
                title={title}
                value={value}
              />
              : null
            }
          </Flex>
        </Content>
      </Layout>
      <Drawer
        closable={true}
        onClose={onDrawerClose}
        open={!!selectedNodeId}
        placement="right"
        title={
          <Flex align="center" justify="space-between">
            <div>Properties</div>
            <Button
              disabled={editing || !nodesMetadata[selectedNode?.type]}
              type="text"
              icon={<EditOutlined />}
              onClick={() => setEditing(true)}
            />
          </Flex>
        }
        width={500}
      >
        {editing ?
          <>
            <NodeInstanceForm
              form={nodeForm}
              readonlyFields={{ type: true }}
              schema={nodesMetadata}
              initialValues={getValue(selectedNode)}
            />
            <div style={{ textAlign: 'right' }}>
              <Space>
                <Button type="default" onClick={onDrawerClose}>Cancel</Button>
                <Button type="primary" onClick={handleSaveNode}>Save</Button>
              </Space>
            </div>
          </>
          :
          <>
            <Descriptions bordered column={1} size="small">
              <Descriptions.Item key={'__id'}
                label={'id'}
                labelStyle={{ width: 130 }}
              >
                {selectedNode?.id}
              </Descriptions.Item>
              <Descriptions.Item key={'__type'}
                label={'type'}
                labelStyle={{ width: 130 }}
              >
                {selectedNode?.type}
              </Descriptions.Item>
              <Descriptions.Item key={'__label'}
                label={'label'}
                labelStyle={{ width: 130 }}
                style={{ borderBottom: '2px solid #ccc' }}
              >
                {selectedNode?.label}
              </Descriptions.Item>
              {selectedNode?.properties
                .filter(([key, _]) => !['document', 'chunk'].includes(key))
                .map(([key, val]) =>
                  <Descriptions.Item key={key}
                    label={lowerCase(key)}
                    labelStyle={{ width: 130 }}
                  >
                    {Array.isArray(val) ? val.join(', ') : val}
                    {key === 'video_id' && val ?
                      <div style={{ marginTop: 8 }}>
                        <YouTube
                          onReady={onReady}
                          opts={opts}
                          videoId={val}
                        />
                      </div>
                      : null
                    }
                  </Descriptions.Item>
                )
              }
            </Descriptions>
            {selectedNode?.nodeAsTargetEdges?.length ?
              <>
                <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                  Incoming relationships
                </div>
                <Descriptions bordered column={1} size="small">
                  {selectedNode?.nodeAsTargetEdges?.map((rel) => {
                    const r = { ...rel, ...(rel.sourceData || {}) };
                    delete r.sourceData;
                    return Object.entries(r).map(([key, val], i) =>
                      <Descriptions.Item key={key}
                        label={lowerCase(key)}
                        labelStyle={{ width: 130 }}
                        style={{ borderBottom: i === (Object.keys(r).length - 1) ? '2px solid #ccc' : 'inherit' }}
                      >
                        {val}
                      </Descriptions.Item>
                    )
                  })}
                </Descriptions>
              </>
              : null
            }
            {selectedNode?.nodeAsSourceEdges?.length ?
              <>
                <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                  Outgoing relationships
                </div>
                <Descriptions bordered column={1} size="small">
                  {selectedNode?.nodeAsSourceEdges?.map((rel) => {
                    const r = { ...rel, ...(rel.targetData || {}) };
                    delete r.targetData;
                    return Object.entries(r).map(([key, val], i) =>
                      <Descriptions.Item key={key}
                        label={lowerCase(key)}
                        labelStyle={{ width: 130 }}
                        style={{ borderBottom: i === Object.keys(r).length - 1 ? '2px solid #ccc' : 'inherit' }}
                      >
                        {val}
                      </Descriptions.Item>
                    )
                  })}
                </Descriptions>
              </>
              : null
            }
          </>
        }
      </Drawer>
    </>
  );
}