import { Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import { ReactFlowProvider } from 'reactflow';
import useLocalStorageState from 'use-local-storage-state';
import isEmpty from 'lodash.isempty';

import ErrorMessage from './components/ErrorMessage';
import { AuthProvider } from './contexts/AuthContext';
import NavbarContext from './contexts/NavbarContext';
import UserContext from './contexts/UserContext';
import WorkspaceContext from './contexts/WorkspaceContext';
import {
  getCurrentUserAsync,
  selectCurrentUser,
  selectAuthStatusChecked,
} from './features/users/usersSlice';
import {
  getWorkspacesAsync,
  selectWorkspaces,
} from './features/workspaces/workspacesSlice';

import router from './router';
import { setToken } from './http';

import './App.css';

const APP = process.env.REACT_APP_NAME === 'Compliance Copilot' ? 'compliancecopilot' : 'pqacademy';

if (APP === 'compliancecopilot') {
  import('./App-cc1.css');
} else if (APP === 'pqacademy') {
  import('./App-pq.css');
}

const { defaultAlgorithm, darkAlgorithm } = theme;

const DEFAULT_WORKSPACE_ID = process.env.REACT_APP_DEFAULT_WORKSPACE_ID;

let darkThemeValues, lightThemeValues;
if (APP === 'compliancecopilot') {
  // darkThemeValues = {
  //   "colorPrimary": "#009c9c",
  //   "colorSuccess": "#178804",
  //   "colorError": "#e9020b",
  //   "colorWarning": "#f5d500",
  //   "colorInfo": "#3c3d41",
  //   "colorTextBase": "#fff",
  //   "colorPrimaryActive": "#005a61",
  //   "colorPrimaryBg": "#f4f4f4",
  //   "colorSuccessBg": "#fff",
  //   "colorSuccessBorder": "#d8d8d9",
  //   "colorWarningBg": "#fff",
  //   "colorWarningBorder": "#d8d8d9",
  //   "colorErrorBg": "#fff",
  //   "colorErrorBorder": "#d8d8d9",
  //   "colorInfoBg": "#fff",
  //   "colorInfoBorder": "#d8d8d9",
  //   "colorBorder": "#d8d8d9",
  //   "fontSizeHeading1": 64,
  //   "fontSizeHeading2": 36,
  //   "fontSizeHeading3": 28,
  //   "fontSizeHeading4": 22,
  //   "lineHeightHeading1": 1,
  //   "lineHeightHeading2": 1.11,
  //   "lineHeightHeading3": 1.14,
  //   "lineHeightHeading4": 1.27,
  //   "borderRadius": 16,
  //   "borderRadiusLG": 16,
  //   "borderRadiusOuter": 16,
  //   "borderRadiusSM": 16,
  //   "borderRadiusXS": 16,
  //   "wireframe": false,
  //   "fontFamily": "'RubrikRegular', 'Segoe UI', 'Helvetica Neue', 'Noto Sans', sans-serif",
  //   "colorLink": "#007b85",
  //   "colorLinkActive": "#005a61",
  //   "colorLinkHover": "#009c9c",
  //   "boxShadow": "none",
  //   "boxShadowSecondary": "none",
  //   "boxShadowTertiary": "none",
  //   "colorTextDisabled": "#6d6e71",
  // };

  // lightThemeValues = {
  //   "colorPrimary": "#007b85",
  //   "colorSuccess": "#178804",
  //   "colorError": "#e9020b",
  //   "colorWarning": "#f5d500",
  //   "colorInfo": "#3c3d41",
  //   "colorTextBase": "#3c3d41",
  //   "colorPrimaryActive": "#005a61",
  //   "colorPrimaryBg": "#f4f4f4",
  //   "colorSuccessBg": "#fff",
  //   "colorSuccessBorder": "#d8d8d9",
  //   "colorWarningBg": "#fff",
  //   "colorWarningBorder": "#d8d8d9",
  //   "colorErrorBg": "#fff",
  //   "colorErrorBorder": "#d8d8d9",
  //   "colorInfoBg": "#fff",
  //   "colorInfoBorder": "#d8d8d9",
  //   "colorBorder": "#d8d8d9",
  //   "fontSizeHeading1": 64,
  //   "fontSizeHeading2": 36,
  //   "fontSizeHeading3": 28,
  //   "fontSizeHeading4": 22,
  //   "lineHeightHeading1": 1,
  //   "lineHeightHeading2": 1.11,
  //   "lineHeightHeading3": 1.14,
  //   "lineHeightHeading4": 1.27,
  //   "borderRadius": 16,
  //   "borderRadiusLG": 16,
  //   "borderRadiusOuter": 16,
  //   "borderRadiusSM": 16,
  //   "borderRadiusXS": 16,
  //   "wireframe": false,
  //   "fontFamily": "'RubrikRegular', 'Segoe UI', 'Helvetica Neue', 'Noto Sans', sans-serif",
  //   "colorLink": "#007b85",
  //   "colorLinkActive": "#005a61",
  //   "colorLinkHover": "#009c9c",
  //   "boxShadow": "none",
  //   "boxShadowSecondary": "none",
  //   "boxShadowTertiary": "none",
  // };
  darkThemeValues = {
    "colorPrimary": "#009c9c",
    "colorSuccess": "#178804",
    "colorError": "#e9020b",
    "colorWarning": "#f5d500",
    "colorInfo": "#3c3d41",
    "colorTextBase": "#fff",
    "colorPrimaryActive": "#005a61",
    "colorPrimaryBg": "#f4f4f4",
    "colorSuccessBg": "#fff",
    "colorSuccessBorder": "#d8d8d9",
    "colorWarningBg": "#fff",
    "colorWarningBorder": "#d8d8d9",
    "colorErrorBg": "#fff",
    "colorErrorBorder": "#d8d8d9",
    "colorInfoBg": "#fff",
    "colorInfoBorder": "#d8d8d9",
    "colorBorder": "#d8d8d9",
    "fontSizeHeading1": 64,
    "fontSizeHeading2": 36,
    "fontSizeHeading3": 28,
    "fontSizeHeading4": 22,
    "lineHeightHeading1": 1,
    "lineHeightHeading2": 1.11,
    "lineHeightHeading3": 1.14,
    "lineHeightHeading4": 1.27,
    "borderRadius": 0,
    "borderRadiusLG": 0,
    "borderRadiusOuter": 0,
    "borderRadiusSM": 0,
    "borderRadiusXS": 0,
    "wireframe": false,
    // "fontFamily": "'RubrikRegular', 'Segoe UI', 'Helvetica Neue', 'Noto Sans', sans-serif",
    // "fontFamily": "Mabrypro, sans-serif",
    "fontFamily": "'Inter', sans-serif",
    "colorLink": "#007b85",
    "colorLinkActive": "#005a61",
    "colorLinkHover": "#009c9c",
    "boxShadow": "none",
    "boxShadowSecondary": "none",
    "boxShadowTertiary": "none",
    "colorTextDisabled": "#6d6e71",
  };

  lightThemeValues = {
    "colorPrimary": "#7d51da",
    "colorSuccess": "#178804",
    "colorError": "#e9020b",
    "colorWarning": "#f5d500",
    "colorInfo": "#3c3d41",
    "colorTextBase": "#3c3d41",
    "colorPrimaryActive": "#005a61",
    "colorPrimaryBg": "#f4f4f4",
    "colorSuccessBg": "#fff",
    "colorSuccessBorder": "#d8d8d9",
    "colorWarningBg": "#fff",
    "colorWarningBorder": "#d8d8d9",
    "colorErrorBg": "#fff",
    "colorErrorBorder": "#d8d8d9",
    "colorInfoBg": "#fff",
    "colorInfoBorder": "#d8d8d9",
    "colorBorder": "#d8d8d9",
    "fontSizeHeading1": 64,
    "fontSizeHeading2": 36,
    "fontSizeHeading3": 28,
    "fontSizeHeading4": 22,
    "lineHeightHeading1": 1,
    "lineHeightHeading2": 1.11,
    "lineHeightHeading3": 1.14,
    "lineHeightHeading4": 1.27,
    "borderRadius": 0,
    "borderRadiusLG": 0,
    "borderRadiusOuter": 0,
    "borderRadiusSM": 0,
    "borderRadiusXS": 0,
    "wireframe": false,
    // "fontFamily": "'RubrikRegular', 'Segoe UI', 'Helvetica Neue', 'Noto Sans', sans-serif",
    // "fontFamily": "Mabrypro, sans-serif",
    "fontFamily": "'Inter', sans-serif",
    "colorLink": "#7d51da",
    "colorLinkActive": "#005a61",
    "colorLinkHover": "#009c9c",
    "boxShadow": "none",
    "boxShadowSecondary": "none",
    "boxShadowTertiary": "none",
  };
} else {
  darkThemeValues = {
    "colorPrimary": "#009c9c",
    "colorSuccess": "#178804",
    "colorError": "#e9020b",
    "colorWarning": "#f5d500",
    "colorInfo": "#3c3d41",
    "colorTextBase": "#fff",
    "colorPrimaryActive": "#005a61",
    "colorPrimaryBg": "#f4f4f4",
    "colorSuccessBg": "#fff",
    "colorSuccessBorder": "#d8d8d9",
    "colorWarningBg": "#fff",
    "colorWarningBorder": "#d8d8d9",
    "colorErrorBg": "#fff",
    "colorErrorBorder": "#d8d8d9",
    "colorInfoBg": "#fff",
    "colorInfoBorder": "#d8d8d9",
    "colorBorder": "#d8d8d9",
    "fontSizeHeading1": 64,
    "fontSizeHeading2": 36,
    "fontSizeHeading3": 28,
    "fontSizeHeading4": 22,
    "lineHeightHeading1": 1,
    "lineHeightHeading2": 1.11,
    "lineHeightHeading3": 1.14,
    "lineHeightHeading4": 1.27,
    "borderRadius": 16,
    "borderRadiusLG": 16,
    "borderRadiusOuter": 16,
    "borderRadiusSM": 16,
    "borderRadiusXS": 16,
    "wireframe": false,
    "fontFamily": "'Jost', sans-serif",
    "colorLink": "#007b85",
    "colorLinkActive": "#005a61",
    "colorLinkHover": "#009c9c",
    "boxShadow": "none",
    "boxShadowSecondary": "none",
    "boxShadowTertiary": "none",
    "colorTextDisabled": "#6d6e71",
  };

  lightThemeValues = {
    "colorPrimary": "#002A48",
    "colorSuccess": "#178804",
    "colorError": "#e9020b",
    "colorWarning": "#f5d500",
    "colorInfo": "#3c3d41",
    "colorTextBase": "#3c3d41",
    "colorPrimaryActive": "#005a61",
    "colorPrimaryBg": "rgb(0, 0, 0, 0.15)",
    "colorSuccessBg": "#fff",
    "colorSuccessBorder": "#d8d8d9",
    "colorWarningBg": "#fff",
    "colorWarningBorder": "#d8d8d9",
    "colorErrorBg": "#fff",
    "colorErrorBorder": "#d8d8d9",
    "colorInfoBg": "#fff",
    "colorInfoBorder": "#d8d8d9",
    "colorBorder": "#d8d8d9",
    "fontSizeHeading1": 64,
    "fontSizeHeading2": 36,
    "fontSizeHeading3": 28,
    "fontSizeHeading4": 22,
    "lineHeightHeading1": 1,
    "lineHeightHeading2": 1.11,
    "lineHeightHeading3": 1.14,
    "lineHeightHeading4": 1.27,
    "borderRadius": 16,
    "borderRadiusLG": 16,
    "borderRadiusOuter": 16,
    "borderRadiusSM": 16,
    "borderRadiusXS": 16,
    "wireframe": false,
    "fontFamily": "'Jost', sans-serif",
    "colorLink": "#002A48",
    "colorLinkActive": "rgb(0, 0, 0, 0.15)",
    "colorLinkHover": "rgb(0, 0, 0, 0.15)",
    "boxShadow": "none",
    "boxShadowSecondary": "none",
    "boxShadowTertiary": "none",
  };
}

function App() {

  const [currentUser, setCurrentUser] = useState(null);
  const [isDarkMode, setIsDarkMode] = useLocalStorageState('darkMode', { defaultValue: false });
  const [navbarState, setNavbarState] = useState({});
  const [ready, setReady] = useState(0);

  const [selectedWorkspace, setSelectedWorkspace] = useLocalStorageState('workspace', { defaultValue: null });

  const navbarContextValue = { isDarkMode, navbarState, setIsDarkMode, setNavbarState };
  const userContextValue = { currentUser, setCurrentUser };
  const workspaceContextValue = { selectedWorkspace, setSelectedWorkspace };

  const authStatusChecked = useSelector(selectAuthStatusChecked);
  const currentUsr = useSelector(selectCurrentUser);
  const workspaces = useSelector(selectWorkspaces);

  const isAdmin = useMemo(() => (currentUsr?.roles || []).includes('admin'), [currentUsr]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (authStatusChecked && !currentUsr) {
  //     setReady(2);
  //   }
  // }, [authStatusChecked]);

  useEffect(() => {
    if (process.env.REACT_APP_FIREBASE_API_KEY) {
      console.log('Using firebase');
      let unsubscribe;
      import('./config/firebase.js')
        .then(({ default: auth }) => {
          // Adds an observer for changes to the signed-in user's ID token, 
          // which includes sign-in, sign-out, and token refresh events.
          unsubscribe = auth.onIdTokenChanged(async (user) => {
            if (user) {
              const accessToken = await user.getIdToken();
              if (accessToken) {
                setToken({ accessToken });
                setCurrentUser((cur) => {
                  if (cur) return { ...cur, ...user };
                  return user;
                });
              }
            }
          });
        });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, []);

  // console.log('ready:', ready);
  // console.log('currentUser:', currentUser);
  // console.log('currentUsr:', currentUsr);
  // console.log('workspaces:', workspaces);

  useEffect(() => {
    if (ready === 0 && currentUser) {
      if (!currentUsr) {
        dispatch(getCurrentUserAsync());
      } else {
        setCurrentUser((cur) => cur ? { ...cur, ...currentUsr } : currentUsr);
        dispatch(getWorkspacesAsync());
        setReady(1);
      }
    }
  }, [currentUser, currentUsr, ready]);

  useEffect(() => {
    if (ready === 1) {
      if (!isEmpty(workspaces)) {
        // console.log('Workspaces:', workspaces);
        // console.log('Selected workspace:', selectedWorkspace);
        // console.log('Admin?', isAdmin);
        // console.log('Default workspace id:', DEFAULT_WORKSPACE_ID);
        if (selectedWorkspace) {
          if (!Object.values(workspaces).find(w => w.id === selectedWorkspace.id)) {
            setSelectedWorkspace(null);
          }
        } else if (!isAdmin) {
          console.log('DEFAULT_WORKSPACE_ID:', DEFAULT_WORKSPACE_ID);
          console.log('workspaces:', workspaces);
          const workspace = workspaces[DEFAULT_WORKSPACE_ID];
          console.log('Setting default workspace:', workspace);
          setSelectedWorkspace(workspace);
          // console.log('Reload window in 400');
          // setTimeout(() => {
          //   window.location.reload();
          // }, 400);
        }
        setReady(2);
      }
    }
  }, [ready, selectedWorkspace, workspaces]);

  const Loading = () => (
    <div style={{ margin: '20px 40px' }}>Loading...</div>
  );

  return (
    <Suspense fallback={<Loading />}>
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
          token: isDarkMode ? darkThemeValues : lightThemeValues,
        }}
      >
        <AuthProvider>
          <UserContext.Provider value={userContextValue}>
            <WorkspaceContext.Provider value={workspaceContextValue}>
              <NavbarContext.Provider value={navbarContextValue}>
                <ReactFlowProvider>
                  <ErrorMessage />
                  <RouterProvider router={router({ currentUser, isAdmin, isDarkMode })} />
                </ReactFlowProvider>
              </NavbarContext.Provider>
            </WorkspaceContext.Provider>
          </UserContext.Provider>
        </AuthProvider>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
