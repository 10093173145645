import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Dropdown, Empty, Flex, List, Space, Typography } from 'antd';
import {
  FileOutlined,
  ForkOutlined,
  MenuOutlined,
  PlusOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import UserContext from '../../contexts/UserContext';

import {
  getOntologiesAsync,
  selectOntologies,
} from '../ontology/ontologiesSlice';
import {
  getTasksAsync,
  selectTasks,
} from '../tasks/tasksSlice';

const { Meta } = Card;
const { Title } = Typography;

export function OpsDashboard() {

  const [ready, setReady] = useState(false);

  const ontologies = useSelector(selectOntologies);
  const tasks = useSelector(selectTasks);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);
  const { currentUser } = useContext(UserContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: null,
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace?.id) {
      dispatch(getOntologiesAsync({ workspaceId: selectedWorkspace.id }));
      // dispatch(getTasksAsync({ workspaceId: selectedWorkspace.id }));
      setReady(true);
    }
  }, [selectedWorkspace]);

  const taskList = [
    {
      title: 'Review scam controls',
      description: 'Review new scam controls.',
    },
    {
      title: 'Update scam obligations',
      description: 'Process updates to the obligations view to notify impacted stakeholders.',
    },
  ];

  const assetList = [
    {
      title: 'rep761-published-20-april-2023.pdf',
      description: 'Scam prevention, detection and response by the four major banks.',
    },
    {
      title: 'Scams Prevention Framework Bill 2024.pdf',
      description: 'Scams Prevention Framework Bill 2024.',
    },
    {
      title: 'rep790-published-20-august-2024.pdf',
      description: 'Anti scam practices outside the four major banks.',
    },
  ];

  const newsList = [
    {
      title: 'Scam alert: Bulk email extortion scam',
      description: 'Scammers send you an email falsely claiming that your computer or webcam ...',
    },
    {
      title: 'Scam warning for Aussie travellers as UK introduces online permit',
      description: 'Online security experts are warning Australian travellers to take extra care...',
    },
    {
      title: 'Explainer - 5 scams to watch in 2025',
      description: 'While scams are always evolving, there are common underlying red flags across....',
    },
  ];

  const handleItemClick = ({ key }) => {
    let ontology = selectedWorkspace.defaultOntology;
    if (!ontology) {
      ontology = Object.values(ontologies)[0].id;
    }
    switch (key) {
      case 'regulatory':
        navigate(`/graph/SubMandate?title=Regulatory+Frameworks&ontology=${ontology}`);
        break;

      case 'mandates':
        navigate(`/graph/Mandate?title=Mandates&ontology=${ontology}`);
        break;

      case 'sub-mandates':
        navigate(`/graph/SubMandate?title=Sub+mandates&ontology=${ontology}`);
        break;

      case 'business-requirements':
        navigate(`/graph/BusinessRequirement?title=Business+Requirements&ontology=${ontology}`);
        break;

      case 'compliance-arrangements':
        navigate(`/graph/ComplianceArrangement?title=Compliance+Arrangements&ontology=${ontology}`);
        break;

      case 'obligations':
        navigate(`/graph/Obligation?title=Obligations&ontology=${ontology}`);
        break;

      case 'organization':
        navigate(`/graph/OrganizationUnit?title=Organization+Units&ontology=${ontology}`);
        break;

      case 'risk':
        navigate(`/graph/L1RiskCategory?title=Risks&ontology=${ontology}`);
        break;

      case 'control':
        navigate(`/graph/ControlType?title=Controls&ontology=${ontology}`);
        break;

      case 'process':
        navigate(`/graph/BusinessFunction?title=Processes&ontology=${ontology}`);
        break;

      case 'product':
        navigate(`/graph/BusinessFunction?title=Processes&ontology=${ontology}`);
        break;

      case 'scenario':
        navigate(`/graph/L2RiskScenario?title=Risk+Scenarios&ontology=${ontology}`);
        break;

      case 'document':
        navigate('/uploads');
        break;

      case 'role':
        navigate('/defined?title=Roles+%26+Responsibilities&types=OrganizationUnit');
        break;

      case 'system':
        navigate('/');
        break;

    }
  };

  const menu = {
    items: [
      {
        key: 'mandates',
        label: 'Mandates',
      },
      {
        key: 'sub-mandates',
        label: 'Sub mandates',
      },
      {
        key: 'business-requirements',
        label: 'Business Requirements',
      },
      {
        key: 'compliance-arrangements',
        label: 'Compliance Arrangements',
      },
      {
        key: 'obligations',
        label: 'Obligations',
      },
      // {
      //   key: 'organization',
      //   label: 'Organization Units',
      // },
      // {
      //   key: 'risk',
      //   label: 'Risks',
      // },
      // {
      //   key: 'control',
      //   label: 'Controls',
      // },
      // {
      //   key: 'process',
      //   label: 'Processes',
      // },
      // {
      //   key: 'product',
      //   label: 'Products',
      // },
      // {
      //   key: 'scenario',
      //   label: 'Risk Scenarios',
      // },
    ],
    onClick: handleItemClick,
  };

  return (
    <Flex id="home" vertical gap={24}>
      <div id="library">
        <Dropdown
          arrow
          menu={menu}
        >
          <Button type="primary" icon={<MenuOutlined />} size="large">
            Library
          </Button>
        </Dropdown>
      </div>
      <div id="start" style={{ background: '#fff', padding: '40px 16px' }}>
        <div style={{ fontWeight: 600 }}>
          Tasks to get started
        </div>
        <Flex justify="center">
          <Space wrap size={8}>
            <Button icon={<FileOutlined />} size="large" type="primary"
              onClick={() => navigate('/uploads')}
            >
              Load assets
            </Button>
            <Button icon={<ForkOutlined />} size="large" type="primary"
              onClick={() => navigate('/inference')}
            >
              Augment data
            </Button>
            <Button icon={<ShareAltOutlined />} size="large" type="primary"
              onClick={() => navigate('/graph')}
            >
              Explore knowledge
            </Button>
            <Button icon={<SettingOutlined />} size="large" type="primary"  >
              Tune models
            </Button>
          </Space>
        </Flex>
      </div>
      {currentUser?.firstName ?
        <Title level={3} >
          Welcome, {currentUser.firstName}
        </Title>
        : null
      }
      <Flex gap={24} justify="center" stye={{ minWidth: 358 }} wrap>
        <Flex vertical gap={24}>
          <List
            header={
              <Flex align="center" justify="space-between">
                <div style={{ fontWeight: 600 }}>News alerts</div>
              </Flex>
            }
            dataSource={newsList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
            style={{ width: 300 }}
          />
        </Flex>
        <Flex vertical gap={24}>
          <List
            header={
              <Flex align="center" justify="space-between">
                <div style={{ fontWeight: 600 }}>Active Tasks</div>
                <Button type="text" size="small"
                  icon={<PlusOutlined />}
                />
              </Flex>
            }
            dataSource={taskList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
            style={{ width: 300 }}
          />
          <List
            header={
              <Flex align="center" justify="space-between">
                <div style={{ fontWeight: 600 }}>Notifications</div>
              </Flex>
            }
            dataSource={[]}
            renderItem={(item) => (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications" />
            )}
            style={{ width: 300 }}
          />
        </Flex>
        <Flex vertical gap={24}>
          <List
            header={
              <Flex align="center" justify="space-between">
                <div style={{ fontWeight: 600 }}>New assets</div>
                <Button type="text" size="small"
                  icon={<PlusOutlined />}
                />
              </Flex>
            }
            dataSource={assetList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
            style={{ width: 300 }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
