import React from 'react';
import {
  Form,
  Modal,
} from 'antd';

import EdgeForm from './EdgeForm';

export default function AddEdgeModal({
  onCancel,
  onSubmit,
  open,
  schema,
  allowedEdgeTypes,
}) {

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onSubmit({ values });
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      okText="Save"
      title="Add Edge"
    >
      <EdgeForm form={form} schema={schema} allowedEdgeTypes={allowedEdgeTypes} />
    </Modal>
  );
}