import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Space, Table, message } from 'antd';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  deleteAssessmentsAsync,
  getAssessmentsAsync,
  selectLoading,
  selectAssessments,
} from './assessmentsSlice';

export function AssessmentsList() {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loading = useSelector(selectLoading);
  const assessments = useSelector(selectAssessments);

  const data = useMemo(() => {
    const list = Object.values(assessments).map(m => ({
      key: m.id,
      created: m.created,
      createdBy: m.createdBy,
      modified: m.modified,
      subjectName: m.subjectName,
      submitted: m.submitted,
      type: m.type,
    }));
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [assessments]);

  // console.log('data:', data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  const handleCreate = ({ key }) => {
    window.location.href = `/surveys/new?type=${key}`;
  };

  const menu = {
    items: [
      {
        label: 'PQ5 Leadership Survey',
        key: 'leadership',
      },
      {
        label: 'PQ Cost: Organizational Due Diligence',
        key: 'cost',
      },
    ],
    onClick: handleCreate,
  };

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: (
        <Dropdown menu={menu}>
          <Button type="primary">Create</Button>
        </Dropdown>
      ),
      title: 'Assessments',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getAssessmentsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  const onDelete = () => {
    dispatch(deleteAssessmentsAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: 'Assessment Date',
      dataIndex: 'created',
      render: (_, { key, created, type }) => <Link to={`/assessments/${key}?type=${type}`}>{new Date(created).toLocaleDateString('en-US')}</Link>
    },
    // {
    //   title: 'Created by',
    //   dataIndex: 'createdBy',
    // },
    {
      title: 'Subject',
      dataIndex: 'subjectName',
      render: (_, { subjectName }) => <div style={{ whiteSpace: 'nowrap' }}>{subjectName}</div>
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
      render: (_, { modified }) => new Date(modified).toLocaleDateString('en-US'),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(`/surveys/${record.key}?type=${record.type}`)}
          >
            {record.submitted ? 'View' : 'Edit'}
          </Button>
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(`/assessments/${record.key}?type=${record.type}`)}
          >
            Assessment
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <div style={{ marginBottom: 16 }}>
          <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ hideOnSinglePage: true }}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};
