import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Flex,
  Form,
  Input,
  Layout,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import useLocalStorageState from 'use-local-storage-state';
import * as dayjs from 'dayjs';

import AddColumnModal from './AddColumnModal';
import ExcelExport from './ExcelExport';
import SearchBox from './SearchBox';
import SortableSelect from './SortableSelect';

const FEATURE_FLAGS = {
  facets: 0,
  selectedColumns: 0,
  selectedNodes: 1,
  sortableColumns: 0,
  tableType: 0,
};

const EXCLUDED_COLUMNS = ['type', 'filetype', 'filename', 'code', 'inferred', 'start_char_idx', 'end_char_idx', 'index', '__rels', 'responsible_entity', 'action', 'conditions', 'references', 'notes'];

const IS_EDGE_VERSION = process.env.REACT_APP_IS_EDGE_VERSION === 'true';

const { Content, Sider } = Layout;
const { TextArea } = Input;

const EditableContext = React.createContext(null);

const getLength = (dataSource, dataIndex) => {
  if (dataIndex === 'id') {
    return 5;
  }
  let length = 0;
  for (const row of dataSource) {
    const value = row[dataIndex];
    if (value) {
      length = Math.max(length, String(value).length);
    }
  }
  return length;
};

const getMinWidth = (length = 0) => {
  if (length > 150) {
    return 500;
  }
  if (length > 75) {
    return 350;
  }
  if (length > 50) {
    return 250;
  }
  if (length > 10) {
    return 200;
  }
  return 150;
};

const spaced = (name) => {
  return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

const EditableRow = ({ index, ...props }) => {

  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const CellValue = ({ value }) => {

  const [expanded, setExpanded] = useState(false);

  if (value === null || typeof value === 'undefined' || value === '') {
    return null;
  }
  if (typeof value === 'string' && value.match(/[a-z0-9]{32}/)) {
    return value.slice(0, 5);
  }
  const dt = dayjs(value);
  if (dt.isValid()) {
    return dt.format('YYYY-MM-DD');
  }

  let text;
  if (typeof value === 'boolean') {
    text = value ? 'T' : 'F';
  } else {
    text = value;
  }

  return (
    <Typography.Paragraph
      className="editable-cell-value-wrap"
      copyable={typeof value === 'string'}
      ellipsis={{
        rows: 3,
        // expandable: 'collapsible',
        // expanded: expanded,
        // onExpand: (_, info) => setExpanded(info.expanded),
      }}
      style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}
    >
      {text}
    </Typography.Paragraph>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  onSave,
  ...restProps
}) => {

  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      onSave({
        record,
        value: Object.keys(values).map(key => ({
          key,
          value: values[key],
        }))[0],
      });
    } catch (err) {
      console.error('Save failed:', err);
    }
  };

  const MyTextArea = ({ onChange, value }) => {
    return (
      <>
        <TextArea
          autoSize={{ minRows: 1, maxRows: 14 }}
          ref={inputRef}
          onChange={onChange}
          value={value}
        />
        <div style={{ display: 'flex', gap: 5, justifyContent: 'right', marginTop: 5 }}>
          <Button
            size="small"
            icon={<CloseOutlined />}
            onClick={toggleEdit}
          />
          <Button
            size="small"
            icon={<CheckOutlined />}
            onClick={save}
          />
        </div>
      </>
    );
  };

  let childNode;
  let style;
  if (editable) {
    style = { cursor: 'pointer', paddingInlineEnd: 24 };
  } else {
    style = { cursor: 'default', paddingInlineEnd: 24 };
  }
  if (editing) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
          minWidth: 150,
        }}
        name={dataIndex}
      >
        <MyTextArea />
      </Form.Item>
    );
  } else {
    childNode = (
      <CellValue value={children[1]} />
    );
  }

  return (
    <td
      {...restProps}
      onClick={() => {
        if (editable && !editing) {
          toggleEdit();
        }
      }}
      style={style}
    >
      {childNode}
    </td>
  );
};

export function EditableTable({
  fixed,
  onAdd,
  onChange,
  onDelete,
  onSave,
  onAddColumn,
  onSaveProperty,
  nodeOptions,
  nodesMetadata,
  colorMap,
  nodeType,
  title,
  value,
}) {

  const [formOpen, setFormOpen] = useState(false);
  const [maxTagCount, setMaxTagCount] = useState('responsive');
  const [newColumn, setNewColumn] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedNodeInstances, setSelectedNodeInstances] = useState({});
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
      hideOnSinglePage: true,
      position: ['topLeft'],
      size: 'small',
    },
    filters: {},
  });
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(-1);
  const [facetsCollapsed, setFacetsCollapsed] = useLocalStorageState('facets-collapsed', { defaultValue: true });
  const [filterProps, setFilterProps] = useState({});

  const navigate = useNavigate();

  const columnOptions = value.defaultColumns
    .filter(c => !['label', 'id', 'type'].includes(c.title))
    .map(c => ({
      label: c.title,
      value: c.dataIndex,
    }));

  const nodeLabelOptions = useMemo(() => {
    const typeCol = value.tableView === 'nodes' ? 'type' : 'source_type';
    const nodes = [...new Set(value.dataSource.map(n => n[typeCol]))];
    const list = nodes.map(n => ({
      label: n,
      value: n,
    }));
    list.sort((a, b) => a.label < b.label ? -1 : 1);
    return list;
  }, [value.dataSource, value.tableView]);

  const nodeLabels = useMemo(() => {
    return nodeLabelOptions.map(n => n.label);
  }, [nodeLabelOptions]);

  const data = useMemo(() => {
    const typeCol = value.tableView === 'nodes' ? 'type' : 'source_type';
    const ds = value.dataSource;
    // console.log('ds', ds);
    ds.sort((a, b) => a.code?.localeCompare(b.code));
    return ds
      .filter((row) => !value.selectedNodes?.length || value.selectedNodes?.includes(row[typeCol]))
      .filter((row) => {
        for (const [key, values] of Object.entries(filterProps)) {
          if (values.length) {
            if (!values.includes(row[key])) {
              return false;
            }
          }
        }
        return true;
      })
      .filter((row) => {
        // console.log('selectedNodeInstances', selectedNodeInstances);
        // console.log('row', row);
        const values = selectedNodeInstances?.[row.type] || [];
        // console.log('values', values);
        if (values.length) {
          if (!values.includes(row.id)) {
            return false;
          }
        }
        return true;
      })
      .map((row) => {
        if (selectedColumns.length) {
          const ret = ['label', 'id', 'type', '__rels', ...selectedColumns].reduce((a, col, i) => {
            a[col] = row[col];
            return a;
          }, {});
          const topics = (row.__rels?.Topic || []).map(t => t.name);
          return { ...ret, topics };
        }
        const ret = Object.keys(row).reduce((a, col) => {
          a[col] = row[col];
          return a;
        }, {});
        const topics = (row.__rels?.Topic || []).map(t => t.name);
        return { ...ret, topics };
      });
  }, [
    filterProps,
    value.dataSource,
    selectedColumns,
    value.selectedNodes,
    selectedNodeInstances,
    value.tableView,
  ]);

  // TODO reset between table type changes
  // .filter((row) => {
  //   const targetTypes = tableParams?.filters?.target_type;
  //   if (targetTypes) {
  //     return targetTypes.includes(row.target_type);
  //   }
  //   return true;
  // })
  const exportedData = useMemo(() => {
    return data
      .map((row) => Object.entries(row)
        .filter(([k, _]) => k !== '__rels')
        .reduce((a, [k, v]) => {
          if (k === 'Topic') {
            a[k] = v?.split(', ').map((x, i) => (i + 1) + '. ' + x.slice(1, -1)).join('\n');
          } else if (typeof v === 'boolean') {
            a[k] = v ? 'true' : 'false';
          } else {
            a[k] = v;
          }
          return a;
        }, {}));
  }, [data, tableParams]);

  const filterNodes = useMemo(() => {
    if (value.selectedNodes?.length) {
      return value.selectedNodes.flatMap(n => {
        const meta = nodesMetadata[n] || {};
        const inboundNodes = Object.values(meta.relationships || {})
          .filter(n => !Object.values(meta.edges).includes(n));
        return [...new Set(inboundNodes)];
      });
    }
    return [];
  }, [value.selectedNodes]);

  useEffect(() => {
    if (!selectedColumns?.length) {
      setSelectedColumns(columnOptions.map(c => c.value));
    }
  }, []);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: value.page || 1,
        pageSize: value.pageSize || 10,
        total: value.total,
      },
    });
  }, [value.page, value.pageSize, value.total]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleAddColumn = (values) => {
    const name = values.values.name;
    setNewColumn(cur => ({ ...cur, name }));
    setFormOpen(false);
    const index = newColumn.index;
    if (selectedColumns.length) {
      setSelectedColumns(cur => [...cur.slice(0, index), name, ...cur.slice(index + 1)]);
    } else {
      const cols = columnOptions.map(c => c.value);
      setSelectedColumns(cur => [...cols.slice(0, index), name, ...cols.slice(index + 1)]);
    }
    onAddColumn(values);
  };

  const handleRefresh = () => {
    const aliases = 'defghijklmn';
    const filters = [];
    const params = {};
    let i = 0;
    for (const label of Object.keys(selectedNodeInstances)) {
      const ids = selectedNodeInstances[label];
      if (ids?.length) {
        const alias = aliases.charAt(i);
        const param = label.toLowerCase() + '_ids';
        const filter = `MATCH (n)-[*]-(${alias}:${label}) WHERE ${alias}.id IN $${param}`;
        filters.push(filter);
        params[param] = ids;
      }
      i += 1;
    }
    onChange({ filters, params });
  };

  const handleSelectNodeInstances = (label, values) => {
    let newSelectedNodeInstances;
    if (!values.length) {
      newSelectedNodeInstances = { ...selectedNodeInstances };
      delete newSelectedNodeInstances[label];
    } else {
      newSelectedNodeInstances = {
        ...selectedNodeInstances,
        [label]: values,
      };
    }
    setSelectedNodeInstances(newSelectedNodeInstances);
    onChange({ selectedNodeInstances: newSelectedNodeInstances });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      pagination,
      filters,
      ...sorter,
    };
    setTableParams(params);
    onChange({
      page: params.pagination.current,
      pageSize: params.pagination.pageSize,
    });
  };

  const columns = useMemo(() => {
    let cols;
    if (selectedColumns.length) {
      cols = ['id', 'label', 'type', '__rels', ...selectedColumns].reduce((a, c) => {
        let col;
        if (c === '__rels') {
          col = {
            title: 'subtypes',
            editable: false,
            dataIndex: c,
          }
        } else {
          col = value.defaultColumns.find(x => x.dataIndex === c);
        }
        if (col) {
          a.push(col);
        }
        return a;
      }, []);
    } else {
      cols = value.defaultColumns;
    }
    if (nodeType) {
      cols = cols.filter(col => !EXCLUDED_COLUMNS.includes(col.dataIndex));
    }
    cols.push({
      title: 'Topic',
      editable: false,
      dataIndex: 'topics',
    });

    return cols
      .filter(col => col)
      .map((col, i) => {
        const length = getLength(value.dataSource, col.dataIndex);
        const minWidth = getMinWidth(length);
        const colTitle = getTitle(col);

        if (!col.editable) {
          return {
            ...col,
            minWidth,
            render: (_, record) => {
              if (col.dataIndex === '__rels') {
                return (
                  <Space wrap className="subtypes" size="small">
                    {Object.entries(record.__rels || {}).map(([label, edges = []]) => (
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: '1',
                              label,
                              type: 'group',
                              children: edges.map(edge => ({
                                key: edge.id,
                                label: (
                                  <Link to={`/node-details/${edge.target}/${edge.id}?title=${title}&ontology=${value.ontology}&parent=${record.type},${record.label},${record.id}`}>{edge.name}</Link>
                                )
                              })),
                            },
                          ],
                        }}
                        placement="bottomLeft"
                        arrow
                      >
                        <Tag color={colorMap[label]}>{label.slice(0, 2).toUpperCase()}</Tag>
                      </Dropdown>
                    ))}
                  </Space>
                );
              }
              if (col.dataIndex === 'topics') {
                return (
                  <Space wrap size="small">
                    {record.topics?.map((t, i) => (
                      <Tag key={record.id + '-' + i} color="green">{t}</Tag>
                    ))}
                  </Space>
                );
              }
              if (nodeLabels.includes(col.dataIndex)) {
                const entries = record[col.dataIndex]?.split(', ') || [];
                entries.sort();
                return (
                  <ol>
                    {entries.map(x => x.slice(1, -1)).map((x, i) => (
                      <li key={col.dataIndex + '-' + i}>{x}</li>
                    ))}
                  </ol>
                );
              }
              if (typeof record[col.dataIndex] === 'boolean') {
                return record[col.dataIndex] ? 'T' : 'F';
              }
              if (col.dataIndex === 'id') {
                const shortId = record.code || record.id.slice(0, 5);
                return (
                  <Link to={`/node-details/${record.type}/${record.id}?title=${title}&ontology=${value.ontology}`}>{shortId}</Link>
                );
              }
              return record[col.dataIndex];
            },
            title: (
              <div className="table-title" style={{ position: 'relative' }}>
                {!nodeType && !fixed && i > 1 ?
                  <div className="add-column-btn" style={{ position: 'absolute', right: -29, top: -41 }}>
                    <Button
                      size="small"
                      type="text"
                      icon={<PlusOutlined />}
                      onMouseEnter={() => setSelectedColumnIndex(i)}
                      onMouseLeave={() => setSelectedColumnIndex(-1)}
                      onClick={() => {
                        setFormOpen(true);
                        setNewColumn({ index: i + 1 });
                      }}
                    />
                  </div>
                  : null
                }
                <div>{colTitle}</div>
              </div>
            ),
            className: i === selectedColumnIndex ? 'selected' : '',
          };
        }

        return {
          ...col,
          minWidth,
          onCell: (record) => ({
            record,
            editable: !nodeType && col.editable,
            dataIndex: col.dataIndex,
            title: colTitle,
            onSave: onSaveProperty,
          }),
          title: (
            <div className="table-title" style={{ position: 'relative' }}>
              {!nodeType && !fixed && i > 1 ?
                <div className="add-column-btn" style={{ position: 'absolute', right: -29, top: -41 }}>
                  <Button
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                    onMouseEnter={() => setSelectedColumnIndex(i)}
                    onMouseLeave={() => setSelectedColumnIndex(-1)}
                    onClick={() => {
                      setFormOpen(true);
                      setNewColumn({ index: i - 2 });
                    }}
                  />
                </div>
                : null
              }
              <div>{colTitle}</div>
            </div>
          ),
        };
      });
  }, [value.defaultColumns, newColumn, selectedColumnIndex, selectedColumns]);

  return (
    <>
      <AddColumnModal
        nodeOptions={nodeLabelOptions}
        onCancel={() => setFormOpen(false)}
        onSubmit={handleAddColumn}
        open={formOpen}
      />
      <Layout>
        {!fixed ?
          <Sider
            collapsible
            collapsed={facetsCollapsed}
            collapsedWidth={0}
            trigger={null}
            style={{
              background: '#FBFBFB',
              marginRight: facetsCollapsed ? 0 : 10,
            }}
            width={250}
            theme="light"
          >
            <div style={{ fontWeight: 600, fontSize: '17px', paddingTop: 1 }}>Facets</div>
            <div style={{ paddingBottom: 16 }}>
              <div style={{ fontWeight: 600, padding: '8px 0' }}>Node Types</div>
              <Select
                allowClear
                maxTagCount="responsive"
                mode="multiple"
                options={nodeLabelOptions}
                placeholder="Select row types to display"
                size="small"
                style={{ width: 225 }}
                value={value.selectedNodes}
                onChange={(value) => onChange({ selectedNodes: value })}
              />
            </div>
            {Object.entries(nodeOptions).map(([label, options]) => (
              <div style={{ paddingBottom: 16 }}>
                <div style={{ fontWeight: 600, padding: '8px 0' }}>{spaced(label)}</div>
                <Select
                  allowClear
                  maxTagCount="responsive"
                  mode="multiple"
                  options={options}
                  placeholder="Select entries to display"
                  size="small"
                  style={{ width: 225 }}
                  value={selectedNodeInstances[label]}
                  onChange={(values) => handleSelectNodeInstances(label, values)}
                />
              </div>
            ))}
            <Button type="primary" size="small"
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          </Sider>
          : null
        }
        <Content>
          <Flex vertical gap={8}>
            <Flex align="center" gap={8}>
              {!fixed ?
                <>
                  {FEATURE_FLAGS['facets'] && !nodeType ?
                    <Button
                      size="small"
                      type="text"
                      icon={facetsCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                      onClick={() => setFacetsCollapsed(cur => !cur)}
                      style={{ background: 'rgba(60, 61, 65, 0.06)' }}
                    >
                      Facets
                    </Button>
                    : null
                  }
                  {FEATURE_FLAGS['selectedNodes'] && !nodeType ?
                    <Select
                      allowClear
                      maxTagCount="responsive"
                      mode="multiple"
                      options={nodeLabelOptions}
                      placeholder="Select root node"
                      size="small"
                      style={{ width: 200 }}
                      value={value.selectedNodes}
                      onChange={(value) => onChange({ selectedNodes: value })}
                    />
                    : null
                  }
                  {FEATURE_FLAGS['selectedColumns'] && !nodeType ?
                    <SortableSelect
                      allowClear
                      className={maxTagCount ? '' : 'expanded'}
                      maxTagCount={maxTagCount}
                      mode="multiple"
                      options={columnOptions}
                      placeholder="Select columns to display"
                      size="small"
                      style={{ width: 367 }}
                      value={selectedColumns}
                      onChange={setSelectedColumns}
                    />
                    : null
                  }
                  {FEATURE_FLAGS['sortableColumns'] && !nodeType ?
                    <Button
                      size="small"
                      type="text"
                      icon={<SortAscendingOutlined />}
                      onClick={() => setMaxTagCount(cur => cur ? null : 'responsive')}
                      style={{ marginLeft: -4 }}
                    />
                    : null
                  }
                  {FEATURE_FLAGS['tableType'] && !nodeType ?
                    <Segmented
                      onChange={(value) => onChange({ tableType: value })}
                      value={value.tableType}
                      size="small"
                      style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                      options={[
                        {
                          label: 'Cell List',
                          value: 'cell-list',
                        },
                        {
                          label: 'Adjacency Matrix',
                          value: 'adjacency-matrix',
                        },
                      ]}
                    />
                    : null
                  }
                </>
                : null
              }
              {fixed ?
                <Button
                  size="small"
                  type="text"
                  icon={<LeftOutlined />}
                  onClick={() => navigate('/')}
                >
                  Home
                </Button>
                : null
              }
            </Flex>
            <Flex wrap align="center" gap={16}>
              {Object.entries(nodeOptions)
                .filter(([label, _]) => filterNodes.includes(label))
                .map(([label, options]) => (
                  <Flex align="center" gap={8}>
                    <div style={{ fontWeight: 600, padding: '8px 0' }}>{spaced(label)}</div>
                    <Select
                      allowClear
                      maxTagCount="responsive"
                      mode="multiple"
                      options={options}
                      placeholder="Select entries"
                      size="small"
                      style={{ width: 200 }}
                      value={selectedNodeInstances[label]}
                      onChange={(values) => handleSelectNodeInstances(label, values)}
                    />
                  </Flex>
                ))
              }
              {nodeType === 'Mandate' ?
                <>
                  <Flex align="center" gap={8}>
                    <div style={{ fontWeight: 600, padding: '8px 0' }}>Document Type</div>
                    <Select
                      allowClear
                      maxTagCount="responsive"
                      mode="multiple"
                      options={[
                        {
                          label: 'Act',
                          value: 'Act',
                        },
                        {
                          label: 'Standard',
                          value: 'Standard',
                        },
                        {
                          label: 'Guideline',
                          value: 'Guideline',
                        },
                        {
                          label: 'Related',
                          value: 'Related',
                        },
                      ]}
                      placeholder="Select document type"
                      size="small"
                      style={{ width: 200 }}
                      // value={selectedNodeInstances.DocumentType}
                      // onChange={(values) => handleSelectNodeInstances('DocumentType', values)}
                      value={filterProps.mandate_type}
                      onChange={(values) => setFilterProps({ mandate_type: values })}
                    />
                  </Flex>
                  <Flex align="center" gap={8}>
                    <div style={{ fontWeight: 600, padding: '8px 0' }}>Regulator</div>
                    <Select
                      allowClear
                      maxTagCount="responsive"
                      mode="multiple"
                      options={[
                        {
                          label: 'ACCC',
                          value: 'ACCC',
                        },
                        {
                          label: 'APRA',
                          value: 'APRA',
                        },
                        {
                          label: 'ASIC',
                          value: 'ASIC',
                        },
                        {
                          label: 'AUSTRAC',
                          value: 'AUSTRAC',
                        },
                        {
                          label: 'RBA',
                          value: 'RBA',
                        },
                        {
                          label: 'Other',
                          value: 'Other',
                        },
                      ]}
                      placeholder="Select Regulator"
                      size="small"
                      style={{ width: 200 }}
                      value={filterProps.regulator}
                      onChange={(values) => setFilterProps({ regulator: values })}
                    />
                  </Flex>
                </>
                : null
              }
              <div style={{ flex: 1 }}></div>
              <SearchBox ontology={value.ontology} size="small" width={350} />
              <ExcelExport
                data={exportedData}
                filename={'export'}
              />
            </Flex>
            <div id="graph-table" style={{ marginTop: data.length ? 0 : 24, width: 'calc(100% - 7px)' }}>
              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                tableLayout="auto"
                style={{ height: 'calc(100vh - 154px)', overflow: 'auto' }}
              />
            </div>
            {!fixed && onAdd ?
              <Button block
                onClick={onAdd}
                type="dashed"
                style={{
                  marginBottom: 16,
                  marginTop: 16,
                }}
              >
                Add a row
              </Button>
              : null
            }
          </Flex>
        </Content>
      </Layout>
    </>
  );
}

function capitalize(val) {
  return String(val)
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const isObjectEmpty = (obj) => {
  if (!obj) return true;
  for (const node of Object.values(obj)) {
    if (node?.length) {
      return false;
    }
  }
  return true;
};

function getTitle(col) {
  if (col.dataIndex === 'id') {
    return 'ID';
  }
  if (col.dataIndex === 'label') {
    return 'Title';
  }
  if (col.dataIndex === 'text') {
    return 'Details';
  }
  return capitalize(col.title.replace(/_/g, ' '));
}
