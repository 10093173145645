import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const metricSetsSlice = createSlice({
  name: 'metricSets',
  initialState: {
    loaded: false,
    loading: false,
    metricSets: {},
  },
  reducers: {
    removeMetricSets: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.metricSets[id];
      }
    },
    setMetricSets: (state, action) => {
      for (const metricSet of action.payload.metricSets) {
        state.metricSets[metricSet.id] = metricSet;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeMetricSets,
  setMetricSets,
  startLoad,
} = metricSetsSlice.actions;

export const getMetricSetsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/metric-sets?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setMetricSets({ metricSets: res.data }));
};

export const getMetricSetAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/metric-sets/${id}`;
  const res = await http.get(url);
  dispatch(setMetricSets({ metricSets: [res.data] }));
};

export const createMetricSetAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/metric-sets';
  const res = await http.post(url, values);
  dispatch(setMetricSets({ metricSets: [{ ...res.data, correlationId }] }));
};

export const updateMetricSetAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/metric-sets/${id}`;
  const res = await http.put(url, values);
  dispatch(setMetricSets({ metricSets: [{ ...res.data, correlationId }] }));
};

export const deleteMetricSetsAsync = (ids) => async (dispatch) => {
  const url = `/api/metric-sets?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeMetricSets({ ids }));
};

export const materializeMetricSetAsync = ({ clientId, key, workspaceId }) => async (dispatch) => {
  const url = `/api/metric-sets/materialize/${key}`;
  await http.post(url, { clientId, workspaceId });
};

export const selectMetricSets = (state) => state.metricSets.metricSets;

export const selectLoaded = (state) => state.metricSets.loaded;

export const selectLoading = (state) => state.metricSets.loading;

export default metricSetsSlice.reducer;
