import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Avatar, Card, Collapse, Image, Input, Layout, List, Modal, Segmented, Space } from 'antd';
import { AppstoreOutlined, ToolOutlined } from '@ant-design/icons';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import useLocalStorageState from 'use-local-storage-state';
import YouTube from 'react-youtube';

import { ContentView } from '../../components/ContentView';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import { isFloat } from '../../numberUtils';
import { getExtension } from '../../pathUtils';
import {
  getUploadForObjectNameAsync,
  selectUploads,
} from '../uploader/fileUploaderSlice';
import {
  searchAsync,
  selectResults,
} from './searchSlice';

const { Meta } = Card;

const formatMetric = (distance) => {
  return (Math.round(distance * 100) / 100).toFixed(4);
};

const { Search } = Input;
const { Content, Sider } = Layout;

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    rel: 0,
  },
};

export function SearchForm() {

  const [isModalOpen, setModalOpen] = useState(false);
  const [isVideoPreviewModalOpen, setVideoPreviewModalOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [previousSearches, setPreviousSearches] = useLocalStorageState('previous-searches', { defaultValue: {} });
  const [searchText, setSearchText] = useState('');
  const [selectedObject, setSelectedObject] = useState(null);
  const [toolsFilter, setToolsFilter] = useState('all');
  const [upload, setUpload] = useState(null);
  const [videoParams, setVideoParams] = useState({});

  const results = useSelector(selectResults);
  const uploads = useSelector(selectUploads);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  const ext = useMemo(() => {
    if (!upload) return null;
    getExtension(upload.filename);
  }, [upload]);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Search Knowledge Base',
    }));
  }, []);

  useEffect(() => {
    if (selectedObject) {
      const upload = uploads.find(u => u.objectName === selectedObject);
      if (upload) {
        setUpload(upload);
        setModalOpen(true);
        setSelectedObject(null);
      }
    }
  }, [uploads]);

  const hits = useMemo(() => {
    if (results) {
      const metricType = results.metric_type;
      return results.result.map(r => {
        const chunkFields = [
          'object_name',
          'filename',
          'filetype',
          'languages',
          'last_modified',
          'page_number',
          'row_number',
          'index',
          'timestamps',
          'channel',
          'video_id',
          'url',
          'start',
          'duration',
          'tags',
        ];
        const exclude = [
          'node_id',
          'node_type',
          'text',
          '_distance',
          '_relevance_score',
          '_score',
          'metadata',
          ...chunkFields,
        ];
        const props = {
          ...omit(r, exclude),
          ...omit(r.metadata, [...chunkFields, 'thumbnail']),
        };
        const meta = Object.entries(props)
          .filter(([_, v]) => typeof v !== 'undefined' && v !== null)
          .map(([k, v]) => `${k}=${String(v)}`)
          .join('; ');
        const datasourceProps = pick(r, chunkFields);
        const datasource = Object.entries(datasourceProps)
          .filter(([_, v]) => typeof v !== 'undefined' && v !== null)
          .map(([k, v]) => [k, String(v)]);

        let distance, score;
        if (metricType === 'distance') {
          distance = formatMetric(r._distance);
        } else {
          score = formatMetric(r._relevance_score || r._score);
        }

        return {
          key: r.node_id,
          title: r.node_type,
          description: r.text,
          metricType,
          distance,
          score,
          meta,
          datasource,
          objectName: r.object_name,
          thumbnail: r.metadata?.thumbnail,
        };
      });
    }
    return [];
  }, [results]);

  // console.log('hits:', hits);
  // console.log('results:', results);

  const onCancel = () => {
    setModalOpen(false);
    setUpload(null);
  };

  const onChange = (ev) => {
    setSearchText(ev.target.value);
  };

  const onSearch = (query) => {
    if (query) {
      const workspaceId = selectedWorkspace.id;
      dispatch(searchAsync({
        query,
        excludeTypes: ['Chunk', 'Document'],
        // includeTypes: ['Chunk'],
        workspaceId,
      }));
      setPreviousSearches(cur => {
        const list = (cur[workspaceId] || []).filter(q => q !== query);
        list.unshift(query);
        return { ...cur, [workspaceId]: list.slice(0, 10) };
      });
    }
  };

  const searchPrevious = (index) => {
    const query = previousSearches[selectedWorkspace.id]?.[index];
    setSearchText(query);
    onSearch(query);
  };

  const showContent = (objectName) => {
    dispatch(getUploadForObjectNameAsync({
      objectName,
      workspaceId: selectedWorkspace.id,
    }));
    setSelectedObject(objectName);
  };

  const onCancelVideoPreview = () => {
    player.pauseVideo();
    setVideoPreviewModalOpen(false);
    setVideoParams({});
  };

  const showVideo = (item) => {
    const ds = item.datasource.reduce((a, [key, value]) => {
      a[key] = value;
      return a;
    }, {});
    setVideoParams({
      videoId: ds.video_id,
      start: parseInt(ds.start),
    });
    setVideoPreviewModalOpen(true);
  };

  const onReady = (ev) => {
    // ev.target.pauseVideo();
    setPlayer(ev.target);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        title="Content Preview"
        width={ext === 'pdf' ? 643 : '70%'}
        styles={{
          body: { height: 500, overflowY: 'auto' },
        }}
        onCancel={onCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <ContentView upload={upload} />
      </Modal>
      <Modal
        open={isVideoPreviewModalOpen}
        title="Content Preview"
        width={688}
        onCancel={onCancelVideoPreview}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <YouTube
          onReady={onReady}
          opts={{
            ...opts,
            playerVars: { ...opts.playerVars, start: videoParams.start },
          }}
          videoId={videoParams.videoId}
        />
      </Modal>
      <div style={{ marginTop: 20 }}>
        <Layout>
          <Sider
            theme="light"
            width={300}
            style={{ marginRight: 24, padding: '10px 15px' }}
          >
            <div style={{ fontWeight: 400 }}>Previous searches</div>
            <div style={{ marginTop: 8 }}>
              <List
                itemLayout="horizontal"
                dataSource={previousSearches[selectedWorkspace?.id]?.map((title, index) => ({
                  title,
                  index,
                }))}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      description={
                        <Link onClick={() => searchPrevious(item.index)}>{item.title}</Link>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </Sider>
          <Content>
            <Search allowClear
              value={searchText}
              onChange={onChange}
              onSearch={onSearch}
              style={{ width: 650 }}
            />
            {/* <div style={{ marginTop: 20 }}>
              <Segmented
                onChange={setToolsFilter}
                value={toolsFilter}
                style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                options={[
                  {
                    label: 'All Tools',
                    value: 'all',
                  },
                  {
                    label: 'Create',
                    value: 'create',
                  },
                  {
                    label: 'Learn',
                    value: 'learn',
                  },
                ]}
              />
              <div style={{ marginTop: 20 }} id="tools">
                <Space wrap>
                  <Card
                    style={{
                      width: 250,
                    }}
                  >
                    <Meta
                      avatar={
                        <Avatar icon={<ToolOutlined />}
                        >
                        </Avatar>
                      }
                      title={<Link>Summarize</Link>}
                      description={'Summarize the results.'}
                      style={{ color: 'rgba(0, 0, 0, 0.88)' }}
                    />
                  </Card>
                  <Card
                    style={{
                      width: 250,
                    }}
                  >
                    <Meta
                      avatar={
                        <Avatar icon={<ToolOutlined />}
                        >
                        </Avatar>
                      }
                      title={<Link>Learning Objectives</Link>}
                      description={'Develop clear, measurable learning objectives.'}
                      style={{ color: 'rgba(0, 0, 0, 0.88)' }}
                    />
                  </Card>
                  <Card
                    style={{
                      width: 250,
                    }}
                  >
                    <Meta
                      avatar={
                        <Avatar icon={<ToolOutlined />}
                        >
                        </Avatar>
                      }
                      title={<Link>Elaborate</Link>}
                      description={'Dive deeper into the subject.'}
                      style={{ color: 'rgba(0, 0, 0, 0.88)' }}
                    />
                  </Card>
                </Space>
              </div>
            </div> */}
            <div style={{ marginTop: 20 }}>
              <List
                itemLayout="horizontal"
                dataSource={hits}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 16,
                            marginBottom: item.title === 'Video' ? 8 : 0,
                          }}
                        >
                          {item.title === 'Video' ?
                            <Image
                              onClick={() => showVideo(item)}
                              preview={false}
                              src={item.thumbnail}
                              style={{ cursor: 'pointer' }}
                            />
                            : null
                          }
                          <div>{item.title}</div>
                          <div style={{ flex: 1 }}></div>
                          {item.metricType === 'distance' ?
                            <div>Distance: {item.distance} JSON: {JSON.stringify(item)}</div>
                            :
                            <div>Score: {item.score}</div>
                          }
                        </div>
                      }
                      description={
                        <>
                          <p>{item.description}</p>
                          <p>({item.meta})</p>
                          {item.title === 'Image' ?
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                              <Image src={'/api/images/object/' + item.objectName} width={250} />
                            </div>
                            : null
                          }
                          {item.datasource.length ?
                            <Collapse
                              ghost
                              items={[
                                {
                                  key: '1',
                                  label: 'Data source',
                                  children: (
                                    <ul style={{ listStyle: 'none' }}>
                                      {item.datasource.map(([k, v], i) => {
                                        if (k === 'object_name') {
                                          return (
                                            <li key={item.key + '-' + i}>
                                              {k} = <Link onClick={() => showContent(v)}>{v}</Link>
                                            </li>
                                          );
                                        }
                                        if (k === 'video_id') {
                                          return (
                                            <li key={item.key + '-' + i}>
                                              {k} = <Link onClick={() => showVideo(item)}>{v}</Link>
                                            </li>
                                          );
                                        }
                                        return (
                                          <li key={item.key + '-' + i}>{k} = {format(v)}</li>
                                        );
                                      })}
                                    </ul>
                                  ),
                                }
                              ]}
                              size="small"
                            />
                            : null
                          }
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </Content>
        </Layout>
      </div>
    </>
  )
}

const format = (value) => {
  if (isFloat(value)) {
    return Number(value).toFixed(2);
  }
  return value;
};