import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, Table, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  getOntologiesAsync,
  selectOntologies,
} from '../ontology/ontologiesSlice';
import {
  deleteInferenceRunsAsync,
  getInferenceRunsAsync,
  runInferenceAsync,
  selectLoading,
  selectInferenceRuns,
} from './inferenceRunsSlice';

const API_HOST = process.env.REACT_APP_API_HOST;

export function InferenceRunsList() {

  const [domain, setDomain] = useState(null);
  const [processing, setProcessing] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const inferenceRuns = useSelector(selectInferenceRuns);
  const loading = useSelector(selectLoading);
  const ontologies = useSelector(selectOntologies);

  const data = useMemo(() => {
    const list = Object.values(inferenceRuns).map(a => ({
      key: a.id,
      name: a.name,
      created: a.created,
      createdBy: a.createdBy,
      modified: a.modified,
    }));
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [inferenceRuns]);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const clientIdRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const onMessage = (ev) => {
    const recv = JSON.parse(ev.data);
    console.log('recv:', recv);
    if (recv.status === 'done') {
      const key = recv.data.key;
      const p = { ...processing };
      delete p[key];
      setProcessing(p);
    }
  };

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: '/inference/new',
      title: 'Data Augmentation Tasks',
    }));
    const clientId = uuidv4();
    const protocol = process.env.REACT_APP_SECURE_WEBSOCKETS === 'true' ? 'wss' : 'ws';
    const ws = new WebSocket(`${protocol}://${API_HOST}/api/websockets/${clientId}`);
    ws.onmessage = onMessage;
    clientIdRef.current = clientId;

    return () => {
      console.log("Closing connection");
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getOntologiesAsync({ workspaceId }));
      dispatch(getInferenceRunsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace && Object.keys(ontologies).length) {
      let ontology = selectedWorkspace.defaultOntology;
      if (!ontology) {
        ontology = Object.values(ontologies)[0].id;
      }
      const domain = ontologies[ontology].domain;
      setDomain(domain);
    }
  }, [ontologies, selectedWorkspace]);

  const onDelete = () => {
    dispatch(deleteInferenceRunsAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const runInference = (key) => {
    let ontology = selectedWorkspace.defaultOntology;
    if (!ontology) {
      ontology = Object.values(ontologies)[0].id;
    }

    const run = inferenceRuns[key];
    if (run) {
      dispatch(runInferenceAsync({
        activity: run.activity,
        setting: run.setting,
        nodeLabel: run.nodeLabel,
        textField: run.textField,
        workspaceId: selectedWorkspace.id,
        domain,
        clientId: clientIdRef.current,
        key,
      }));
      const p = { ...processing };
      p[key] = true;
      setProcessing(p);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, { key, name }) => <Link to={`/inference/${key}`}>{name}</Link>
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            onClick={() => navigate(`/inference/${record.key}`)}
            style={{ paddingLeft: 0 }}
          >
            Edit
          </Button>
          <Button type="link"
            loading={processing[record.key]}
            onClick={() => runInference(record.key)}
            style={{ paddingLeft: 0 }}
          >
            Run
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <p style={{ marginBottom: 16 }}>
          A data augmentation task will augment the knowledge base by using AI to infer
          additional entities and relationships that are mentioned in the content
          of the acquired data. Entities include People, Organisations, Products,
          Policies, Objectives, etc. Relationships show how entities are linked
          together.
        </p>
        <div style={{ marginBottom: 16 }}>
          <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ hideOnSinglePage: true }}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};
