import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Flex, Form, Rate, Segmented, Space, Table, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import NavbarContext from '../../contexts/NavbarContext';
import UserContext from '../../contexts/UserContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  createAssessmentAsync,
  getAssessmentAsync,
  selectAssessments,
  selectLoaded,
  updateAssessmentAsync,
} from '../assessments/assessmentsSlice';
import { getSurveyAsync } from './surveySlice';

const ReadOnlyField = ({ value }) => {
  return (
    <div style={{ fontStyle: 'italic', fontWeight: 600 }}>{value}</div>
  );
};

export function SurveyForm() {

  const [ratings, setRatings] = useState({});

  const assessments = useSelector(selectAssessments);
  const dataSource = useSelector((state) => state.survey.survey);
  const loaded = useSelector(selectLoaded);

  console.log('dataSource', dataSource);

  const { setNavbarState } = useContext(NavbarContext);
  const { currentUser } = useContext(UserContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const id = location.pathname.match(/\/surveys\/(.*)/)[1];
  const params = new URLSearchParams(location.search);
  const type = params.get('type');
  const isNew = id === 'new';
  const assessment = assessments[id];
  const title = type === 'cost' ? 'PQ Cost: Organizational Due Diligence' : 'PQ5 Leadership Survey';

  const columns = [
    {
      title: 'Pillar / Attribute / Element',
      dataIndex: 'name',
      key: 'category',
      render: (_, record) => <Flex>{record.name}</Flex>,
    },
    {
      title: 'Left Desc.',
      dataIndex: 'left',
      key: 'left',
      width: 250,
      responsive: ['xl'],
    },
    {
      render: (_, record) => {
        if (record.children) {
          return null;
        }
        return (
          <Tooltip title={record.left}>
            <QuestionCircleOutlined />
          </Tooltip>
        );
      },
      responsive: ['xs'],
      width: 46,
    },
    {
      title: 'Feedback',
      align: 'center',
      render: (_, record) => {
        if (record.children) {
          return null;
        }
        if (record.control === 'select') {
          return (
            <Segmented vertical
              disabled={assessment?.submitted}
              options={[record.left, record.right]}
              value={ratings[record.key]}
              onChange={(value) => {
                setRatings((prev) => ({ ...prev, [record.key]: value }));
              }}
              style={{ width: 100 }}
            />
          );
        } else {
          return (
            <Rate
              disabled={assessment?.submitted}
              character={({ index = 0 }) => index}
              count={6}
              style={{ color: '#333' }}
              value={ratings[record.key]}
              onChange={(value) => {
                setRatings((prev) => ({ ...prev, [record.key]: value }));
              }}
            />
          );
        }
      },
      width: 150,
    },
    {
      render: (_, record) => {
        if (record.children) {
          return null;
        }
        return (
          <Tooltip title={record.right}>
            <QuestionCircleOutlined />
          </Tooltip>
        );
      },
      responsive: ['xs'],
      width: 46,
    },
    {
      title: 'Right Desc.',
      dataIndex: 'right',
      key: 'right',
      width: 250,
      responsive: ['xl'],
    },
  ];

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title,
    }));
    dispatch(getSurveyAsync(type));
    if (!isNew) {
      dispatch(getAssessmentAsync(id));
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      respondent: currentUser.email,
    });
  }, [currentUser]);

  useEffect(() => {
    if (assessment) {
      setRatings(assessment.ratings);
    }
  }, [assessment]);

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      onFinish(values, false);
    } catch (err) {
      console.error('Error saving survey');
    }
  };

  const onFinish = (values, submitted = true) => {
    const workspaceId = selectedWorkspace.id;
    const correlationId = uuidv4();
    if (isNew) {
      dispatch(createAssessmentAsync({
        correlationId,
        values: {
          ...values,
          ratings,
          submitted,
          type,
          workspaceId,
        },
      }));
    } else {
      dispatch(updateAssessmentAsync({
        correlationId,
        id,
        values: {
          ...values,
          ratings,
          submitted,
          type,
        },
      }));
    }
    setTimeout(() => {
      navigate('/assessments');
    }, 1000);
  };

  const returnToList = () => {
    navigate('/assessments');
  };

  console.log('assessment', assessment);

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <Button type="link" onClick={returnToList}>Back to List</Button>
      </div>
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        style={{ paddingTop: 0 }}
        onFinish={onFinish}
        initialValues={assessment}
      >
        <Form.Item
          label="Respondent"
          name="respondent"
        >
          <ReadOnlyField />
        </Form.Item>
        <Form.Item
          label="Subject Name"
          name="subjectName"
          rules={[
            { required: true, message: 'Please enter the subject\'s name' },
          ]}
        >
          {assessment?.submitted ? <ReadOnlyField /> : <Input />}
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="default" onClick={returnToList}>Cancel</Button>
            <Button disabled={assessment?.submitted} type="default" onClick={onSave}>Save Draft</Button>
            <Button disabled={assessment?.submitted} type="primary" htmlType="submit">Submit</Button>
          </Space>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
}
