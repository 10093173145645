import React from 'react';
import {
  Form,
  Modal,
} from 'antd';

import NodeInstanceForm from './NodeInstanceForm';

export default function AddNodeInstanceModal({
  onCancel,
  onSubmit,
  open,
  schema,
  anchorNode,
}) {

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (anchorNode?.type) {
        const meta = schema[anchorNode.type];
        const relLookup = Object.entries(meta.relationships).reduce((a, [k, v]) => {
          a[v] = k;
          return a;
        }, {});
        const relType = relLookup[values.type];
        const direction = meta.edges[relType] ? 'outgoing' : 'incoming';
        onSubmit({ values, anchorNode, direction, relType });
      } else {
        onSubmit({ values });
      }
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      okText="Save"
      title="Add Node"
    >
      <NodeInstanceForm form={form} schema={schema} anchorNode={anchorNode} />
    </Modal>
  );
}