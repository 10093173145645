import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const graphSessionsSlice = createSlice({
  name: 'graphSessions',
  initialState: {
    loaded: false,
    loading: false,
    graphSessions: {},
  },
  reducers: {
    removeGraphSessions: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.graphSessions[id];
      }
    },
    resetGraphSessions: (state, action) => {
      state.graphSessions = {};
    },
    setGraphSessions: (state, action) => {
      for (const session of action.payload.graphSessions) {
        state.graphSessions[session.id] = session;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeGraphSessions,
  resetGraphSessions,
  setGraphSessions,
  setTestResult,
  startLoad,
} = graphSessionsSlice.actions;

export const getGraphSessionsAsync = ({ type, workspaceId, username }) => async (dispatch) => {
  dispatch(startLoad());
  dispatch(resetGraphSessions());
  const url = `/api/graph-sessions?type=${type}&workspace_id=${workspaceId}&created_by=${username}`;
  const res = await http.get(url);
  dispatch(setGraphSessions({ graphSessions: res.data }));
};

export const getGraphSessionAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph-sessions/${id}`;
  const res = await http.get(url);
  dispatch(setGraphSessions({ graphSessions: [res.data] }));
};

export const createGraphSessionAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = `/api/graph-sessions`;
  const res = await http.post(url, values);
  dispatch(setGraphSessions({ graphSessions: [{ ...res.data, correlationId }] }));
};

export const updateGraphSessionAsync = ({ id, values }) => async (dispatch) => {
  const url = `/api/graph-sessions/${id}`;
  const res = await http.put(url, values);
  dispatch(setGraphSessions({ graphSessions: [res.data] }));
};

export const deleteGraphSessionsAsync = ({ ids }) => async (dispatch) => {
  const url = `/api/graph-sessions?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeGraphSessions({ ids }));
};

export const selectLoaded = (state) => state.graphSessions.loaded;

export const selectLoading = (state) => state.graphSessions.loading;

export const selectGraphSessions = (state) => state.graphSessions.graphSessions;

export default graphSessionsSlice.reducer;
