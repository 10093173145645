import React from 'react';
import Icon from '@ant-design/icons';

import agent from '../../images/icons/agent.png';
import brainstorming from '../../images/icons/brainstorming.png';
import chat from '../../images/icons/chat.png';
import check from '../../images/icons/check.png';
import checklist from '../../images/icons/checklist.png';
import clipboard from '../../images/icons/clipboard.png';
import debt from '../../images/icons/debt.png';
import digitalKey from '../../images/icons/digital-key.png';
import documents from '../../images/icons/documents.png';
import edit from '../../images/icons/edit.png';
import education from '../../images/icons/education.png';
import employee from '../../images/icons/employee.png';
import evaluation from '../../images/icons/evaluation.png';
import files from '../../images/icons/files.png';
import fire from '../../images/icons/fire.png';
import folder from '../../images/icons/folder.png';
import gear from '../../images/icons/gear.png';
import globalLaws from '../../images/icons/global-laws.png';
import investment from '../../images/icons/investment.png';
import justice from '../../images/icons/justice.png';
import medal from '../../images/icons/medal.png';
import networking from '../../images/icons/networking.png';
import notificationBell from '../../images/icons/notification-bell.png';
import organization from '../../images/icons/organization.png';
import padlock from '../../images/icons/padlock.png';
import paperClip from '../../images/icons/paper-clip.png';
import repeat from '../../images/icons/repeat.png';
import riskAnalysis from '../../images/icons/risk-analysis.png';
import txt from '../../images/icons/txt.png';
import unlocked from '../../images/icons/unlocked.png';

export const images = {
  agent: {
    label: 'Agent',
    image: agent,
  },
  brainstorming: {
    label: 'Brainstorming',
    image: brainstorming,
  },
  chat: {
    label: 'Chat',
    image: chat,
  },
  check: {
    label: 'Check',
    image: check,
  },
  checklist: {
    label: 'Checklist',
    image: checklist,
  },
  clipboard: {
    label: 'Clipboard',
    image: clipboard,
  },
  debt: {
    label: 'Debt',
    image: debt,
  },
  digitalKey: {
    label: 'Digital Key',
    image: digitalKey,
  },
  documents: {
    label: 'Documents',
    image: documents,
  },
  edit: {
    label: 'Edit',
    image: edit,
  },
  education: {
    label: 'Education',
    image: education,
  },
  employee: {
    label: 'Employee',
    image: employee,
  },
  evaluation: {
    label: 'Evaluation',
    image: evaluation,
  },
  files: {
    label: 'Files',
    image: files,
  },
  fire: {
    label: 'Fire',
    image: fire,
  },
  folder: {
    label: 'Folder',
    image: folder,
  },
  gear: {
    label: 'Gear',
    image: gear,
  },
  globalLaws: {
    label: 'Global Laws',
    image: globalLaws,
  },
  investment: {
    label: 'Investment',
    image: investment,
  },
  justice: {
    label: 'Justice',
    image: justice,
  },
  medal: {
    label: 'Medal',
    image: medal,
  },
  networking: {
    label: 'Networking',
    image: networking,
  },
  notificationBell: {
    label: 'Notification Bell',
    image: notificationBell,
  },
  organization: {
    label: 'Organization',
    image: organization,
  },
  padlock: {
    label: 'Padlock',
    image: padlock,
  },
  paperClip: {
    label: 'Paper Clip',
    image: paperClip,
  },
  repeat: {
    label: 'Repeat',
    image: repeat,
  },
  riskAnalysis: {
    label: 'Risk Analysis',
    image: riskAnalysis,
  },
  txt: {
    label: 'Text',
    image: txt,
  },
  unlocked: {
    label: 'Unlocked',
    image: unlocked,
  },
};

export function MyIcon({ type, size = 24 }) {
  const im = images[type];
  if (!im) {
    return null;
  }
  return (
    <Icon
      component={() => <img src={im.image} />}
      style={{ width: size, height: size }}
    />
  );
}

export function MyImage({ type }) {
  const im = images[type];
  if (!im) {
    return null;
  }
  return im.image;
}
