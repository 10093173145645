import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Breadcrumb,
  Button,
  Collapse,
  Descriptions,
  Drawer,
  Flex,
  Form,
  Layout,
  Space,
  Table,
  Tag,
  Timeline,
  Tooltip,
  Tree,
  Typography,
} from 'antd';
import {
  ExpandAltOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  InfoCircleOutlined,
  ShrinkOutlined,
  UserOutlined,
} from '@ant-design/icons';
import YouTube from 'react-youtube';
import lowerCase from 'lodash.lowercase';
import pluralize from 'pluralize';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';

import NodeInstanceForm from '../../components/NodeInstanceForm';
import ChatBox from '../../components/ChatBox';
import GraphContextView from '../../components/GraphContextView';
import SearchBox from '../../components/SearchBox';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  getOntologiesAsync,
  selectOntologies,
} from '../ontology/ontologiesSlice';
import {
  getGraphFromNodeAsync,
  getNodesMetadataAsync,
  regenerateBusinessRequirementsAsync,
  selectGraphs,
  selectNodesMetadata,
} from './graphSlice';

const { Content, Sider } = Layout;

const API_HOST = process.env.REACT_APP_API_HOST;

const CONTEXT_ROLES = ['authority', 'application', 'interpretation'];

const opts = {
  height: '112.5',
  width: '200',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    rel: 0,
  },
};

const spaced = (name) => {
  return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

const hyphenated = (name) => {
  return name.replace(/([a-z])([A-Z])/g, m => `${m[0]} ${m[1].toLowerCase()}`)
};

export function NodeDetails() {

  const [activeKey, setActiveKey] = useState(['1']);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [expandView, setExpandView] = useState(false);
  const [graph, setGraph] = useState(null);
  const [processing, setProcessing] = useState({});
  const [selected, setSelected] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);

  const graphs = useSelector(selectGraphs);
  const nodesMetadata = useSelector(selectNodesMetadata);
  const ontologies = useSelector(selectOntologies);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nodeForm] = Form.useForm();

  const clientIdRef = useRef(null);
  const correlationIdRef = useRef(null);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [_, nodeType, nodeId] = location.pathname.match(/\/node-details\/([^\/]*)\/([^\/]*)/);
  const params = new URLSearchParams(location.search);
  const ontology = params.get('ontology');
  const [parentType, parentLabel, parentId] = params.get('parent')?.split(',') || [];
  let title = params.get('title') || 'Node Details';
  title = hyphenated(title);

  const onMessage = (ev) => {
    const recv = JSON.parse(ev.data);
    console.log('recv:', recv);
    if (recv.status === 'done') {
      const key = recv.data.key;
      const p = { ...processing };
      delete p[key];
      setProcessing(p);
      window.location.reload();
    }
  };

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title,
    }));
    const clientId = uuidv4();
    const protocol = process.env.REACT_APP_SECURE_WEBSOCKETS === 'true' ? 'wss' : 'ws';
    const ws = new WebSocket(`${protocol}://${API_HOST}/api/websockets/${clientId}`);
    ws.onmessage = onMessage;
    clientIdRef.current = clientId;

    return () => {
      console.log("Closing connection");
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      const correlationId = uuidv4();
      dispatch(getOntologiesAsync({ workspaceId }));
      let id, type;
      if (parentId) {
        id = parentId;
        type = parentType;
      } else {
        id = 'all';
        type = nodeType;
      }
      dispatch(getGraphFromNodeAsync({
        correlationId,
        nodeId: id,
        nodeType: type,
        workspaceId,
      }));
      correlationIdRef.current = correlationId;
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace && Object.keys(ontologies).length) {
      const workspaceId = selectedWorkspace.id;
      const domain = ontologies[ontology]?.domain;
      if (domain) {
        dispatch(getNodesMetadataAsync({ workspaceId, domain }));
      }
    }
  }, [ontologies, selectedWorkspace]);

  useEffect(() => {
    const graph = graphs[correlationIdRef.current];
    setGraph(graph);
  }, [graphs]);

  const selectedNode = useMemo(() => {
    if (nodeId && graph) {
      const nodeMap = graph.nodes.reduce((a, node) => {
        a[node.id] = node;
        return a;
      }, {});
      const node = nodeMap[nodeId];
      if (node) {
        const properties = Object.entries(node.data || {})
          .filter(([k, v]) => k !== 'id' && typeof v !== 'undefined')
          .map(([k, v]) => {
            if (typeof v === 'boolean') {
              return [k, v ? 'T' : 'F'];
            }
            return [k, v];
          });

        properties.sort((a, b) => a[0] < b[0] ? -1 : 1);
        const nodeAsSourceEdges = [];
        const nodeAsTargetEdges = [];
        for (const edge of graph.edges) {
          if (edge.source === node.id) {
            const targetNode = nodeMap[edge.target];
            nodeAsSourceEdges.push(({
              type: edge.label,
              id: edge.target,
              target: targetNode.type,
              name: targetNode.label,
              targetData: nodeMap[edge.target].data,
            }));
          }
          if (edge.target === node.id) {
            const sourceNode = nodeMap[edge.source];
            nodeAsTargetEdges.push({
              type: edge.label,
              id: edge.source,
              source: sourceNode.type,
              name: sourceNode.label,
              sourceData: nodeMap[edge.source].data,
            });
          }
        }
        const peers = graph.nodes.filter(n => n.type === node.type);
        peers.sort((a, b) => a.label < b.label ? -1 : 1);
        const currentIndex = peers.findIndex(n => n.id === node.id);
        let nextIndex, previousIndex;
        if (currentIndex > 0) {
          previousIndex = currentIndex - 1;
          if (currentIndex === peers.length - 1) {
            nextIndex = 0;
          } else {
            nextIndex = currentIndex + 1;
          }
        } else {
          previousIndex = peers.length - 1;
          nextIndex = currentIndex + 1;
        }
        return {
          id: node.id,
          type: node.type,
          label: node.label,
          properties,
          nodeAsSourceEdges,
          nodeAsTargetEdges,
          peers,
          currentIndex,
          nextIndex,
          previousIndex,
          next: peers.length > 1 ? peers[nextIndex]?.id : null,
          previous: peers.length > 1 ? peers[previousIndex]?.id : null,
        };
      }
    }
    return null;
  }, [graph, nodeId]);

  console.log('selectedNode:', selectedNode);
  console.log('selectedEdge:', selectedEdge);

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const onReady = (ev) => {
    // setPlayer(ev.target);
  };

  const getValue = (n) => {
    if (!n) {
      return {};
    }
    return {
      ...(n.properties || []).reduce((a, [k, v]) => {
        a[k] = v;
        return a;
      }, {}),
      id: n.id,
      name: n.label,
      type: n.type,
    }
  };

  const handleNodeDetails = (node) => {
    setSelected(node);
    setDrawerOpen(true);
  };

  const handleRegenerateBusinessRequirements = () => {
    const domain = ontologies[ontology]?.domain;
    if (domain) {
      const key = 'brq';
      dispatch(regenerateBusinessRequirementsAsync({
        nodeId,
        workspaceId: selectedWorkspace.id,
        domain,
        clientId: clientIdRef.current,
        key,
      }));
      const p = { ...processing };
      p[key] = true;
      setProcessing(p);
    }
  };

  const handleRelTargetDetails = (rel) => {
    setSelected({
      id: rel.id,
      label: rel.name,
      type: rel.target,
      properties: Object.entries(rel.targetData || {}),
    });
    setDrawerOpen(true);
  };

  const subtypes = Object.entries((selectedNode?.nodeAsSourceEdges || [])
    .reduce((a, edge) => {
      if (!['Chunk', 'Document', 'Topic'].includes(edge.target)) {
        if (!a[edge.target]) {
          a[edge.target] = [];
        }
        a[edge.target].push(edge);
      }
      return a;
    }, {}));

  subtypes.sort((a, b) => a[0] < b[0] ? -1 : 1);

  const supertypes = Object.entries((selectedNode?.nodeAsTargetEdges || [])
    .reduce((a, edge) => {
      if (!['Chunk', 'Document', 'Topic'].includes(edge.source)) {
        if (!a[edge.source]) {
          a[edge.source] = [];
        }
        a[edge.source].push(edge);
      }
      return a;
    }, {}));

  supertypes.sort((a, b) => a[0] < b[0] ? -1 : 1);

  // console.log('supertypes:', supertypes);

  let parent = '';
  const breadcrumbs = [
    {
      title: <Link to={'/'}>Home</Link>,
    },
  ];
  if (parentType) {
    breadcrumbs.push(
      {
        title: <Link to={`/graph/${parentType}?title=${title}&ontology=${ontology}`}>{pluralize(hyphenated(parentType))}</Link>,
      },
      {
        title: `${ellipsis(parentLabel)} (${parentId.slice(0, 5)})`,
      },
      {
        title: pluralize(hyphenated(nodeType)),
      },
      {
        title: `${ellipsis(selectedNode?.label)} (${nodeId.slice(0, 5)})`,
      },
    );
    parent = `&parent=${parentType},${parentLabel},${parentId}`;
  } else {
    breadcrumbs.push(
      {
        title: <Link to={`/graph/${nodeType}?title=${title}&ontology=${ontology}`}>{pluralize(hyphenated(nodeType))}</Link>,
      },
      {
        title: `${ellipsis(selectedNode?.label)} (${nodeId.slice(0, 5)})`,
      },
    );
  }

  const properties = (selectedNode?.properties || []).reduce((a, p) => {
    a[p[0]] = p[1];
    return a;
  }, {});

  const treeData = useMemo(() => {
    if (!selectedNode) {
      return [];
    }
    const includedTypes = [
      'mandate_contains_section',
      // 'mandate_contains_sub_preamble',
      // 'mandate_contains_sub_mandate',
    ];
    const edges = (selectedNode?.nodeAsSourceEdges || [])
      // .filter(e => e.targetData.index !== '1' && includedTypes.includes(e.type) && e.targetData.text)
      .filter(e => includedTypes.includes(e.type))
      .map(e => {
        const { type, color } = getTagAttributes(e);
        return {
          key: e.id,
          title: (
            <Space size="small">
              <Tag
                color={color}
                style={{ borderRadius: 3, margin: 0, fontSize: 10, lineHeight: '18px', padding: '0 3px' }}
              >
                {type}
              </Tag>
              {ellipsis(e.name, 40)}
            </Space>
          ),
          index: e.targetData.index.split('.').map(x => String(parseInt(x, 10)).padStart(3, '0')).join('.'),
          type,
          elementId: e.targetData.element_id,
          parentId: e.targetData.parent_id,
        };
      });

    edges.sort((a, b) => a.index - b.index);

    // console.log('edges:', edges);

    const data = [];
    // let currentSection;
    // let children = [];
    // for (const edge of edges) {
    //   const type = edge.type;
    //   if (type === 'SEC') {
    //     if (currentSection) {
    //       if (children.length) {
    //         data.push({ ...currentSection, children });
    //         children = [];
    //       } else {
    //         data.push(currentSection);
    //       }
    //     }
    //     currentSection = edge;
    //   } else {
    //     children.push(edge);
    //   }
    // }
    // if (children.length) {
    //   data.push({ ...currentSection, children });
    // } else {
    //   data.push(currentSection);
    // }
    let edgeMap = {};
    edges.forEach(edge => {
      edgeMap[edge.elementId] = { ...edge, children: [] };
    });

    edges.forEach(edge => {
      if (edge.parentId && edgeMap[edge.parentId]) {
        edgeMap[edge.parentId].children.push(edgeMap[edge.elementId]);
      } else {
        data.push(edgeMap[edge.elementId]);
      }
    });
    return data;
  }, [selectedNode]);

  // console.log('treeData:', treeData);

  const docTitle = useMemo(() => {
    if (!selectedNode) {
      return null;
    }
    // return selectedNode?.nodeAsSourceEdges.find(e => e.targetData.index === '1')?.name;
    return selectedNode?.label;
  }, [selectedNode]);

  const content = selectedEdge?.type === 'mandate_contains_section' ? ((selectedEdge?.name || '') + ' ' + (selectedEdge?.targetData.text)) : selectedEdge?.targetData.text || selectedEdge?.name;

  const handleSelect = (selectedKeys, info) => {
    // console.log('selectedKeys:', selectedKeys);
    // console.log('info:', info);
    const edge = selectedNode?.nodeAsSourceEdges.find(e => e.id === selectedKeys[0]);
    if (edge) {
      const { type, color } = getTagAttributes(edge);
      setSelectedEdge({ ...edge, type, color });
    }
  };

  const linkToNode = (nodeType, id) => {
    window.location.href = `/node-details/${nodeType}/${id}?title=${spaced(pluralize(nodeType))}&ontology=${ontology}`;
  };

  const ElementTag = () => {
    if (!selectedEdge) {
      return null;
    }
    return (
      <Tag
        color={selectedEdge.color}
      >
        {selectedEdge.type}
      </Tag>
    );
  };

  return (
    <>
      <Breadcrumb items={breadcrumbs} style={{ marginBottom: 16 }} />
      <Flex align="center" style={{ marginBottom: 16 }}>
        {/* <Link to={`/graph/${nodeType}?title=${title}&ontology=${ontology}`}>
          Back to list
        </Link> */}
        <div style={{ flex: 1 }}></div>
        <Space>
          <GraphContextView nodeType={nodeType} nodeId={nodeId} buttonType="primary" />
          <SearchBox ontology={ontology} width={350} />
          <Flex gap={1}>
            <Button
              className="prev-btn"
              disabled={!selectedNode?.previous}
              type="primary"
              onClick={() => navigate(`/node-details/${selectedNode?.type}/${selectedNode?.previous}?title=${title}&ontology=${ontology}${parent}`)}
              style={{ backgroundColor: 'rgba(60, 61, 65, 0.88)', borderColor: 'rgba(60, 61, 65, 0.88)', width: 85 }}
            >
              Previous
            </Button>
            <Button
              disabled={!selectedNode?.next}
              type="primary"
              onClick={() => navigate(`/node-details/${selectedNode?.type}/${selectedNode?.next}?title=${title}&ontology=${ontology}${parent}`)}
              style={{ width: 85 }}
            >
              Next
            </Button>
          </Flex>
        </Space>
      </Flex>
      {editing ?
        <>
          <NodeInstanceForm
            form={nodeForm}
            readonlyFields={{ type: true }}
            schema={nodesMetadata}
            initialValues={getValue(selectedNode)}
          />
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button type="default" onClick={() => { }}>Cancel</Button>
              <Button type="primary" onClick={() => { }}>Save</Button>
            </Space>
          </div>
        </>
        :
        <Flex vertical gap={24}>
          <Flex vertical gap={16}>
            <div>{hyphenated(nodeType)}</div>
            <Flex align="center" gap={5}>
              <div style={{ fontSize: 22 }}>{properties.code}</div>
              <Button
                icon={drawerOpen ? <FolderOpenOutlined /> : <FolderOutlined />}
                type="link"
                onClick={() => setDrawerOpen(prev => !prev)}
              />
            </Flex>
          </Flex>
          <Collapse ghost
            activeKey={activeKey}
            onChange={setActiveKey}
            items={[
              {
                key: '1',
                label: 'General',
                children: (
                  <>
                    {selectedNode?.type === 'Mandate' ?
                      <Descriptions column={3} layout="vertical" size="small">
                        <Descriptions.Item label="Title">
                          {selectedNode?.label}
                        </Descriptions.Item>
                        <Descriptions.Item label="Regulator">
                          {properties.regulator}
                        </Descriptions.Item>
                        <Descriptions.Item label="Effective Date">
                          {dayjs(properties.effective_date).format('YYYY-MM-DD')}
                        </Descriptions.Item>
                        <Descriptions.Item label="End Date">
                          {properties.end_date}
                        </Descriptions.Item>
                        <Descriptions.Item label="Mandate Type">
                          <Tag color="purple" style={{ borderRadius: 3, margin: 0 }}>{properties.mandate_type}</Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Topics">
                          <Space wrap size={8}>
                            {
                              selectedNode?.nodeAsSourceEdges
                                .filter(e => e.type === `${getLabel(nodeType)}_has_topic`)
                                .map(e => <Tag key={e.id} color="green" style={{ borderRadius: 3, margin: 0 }}>{e.name}</Tag>)
                            }
                          </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                          Published
                        </Descriptions.Item>
                        <Descriptions.Item label="Owner">
                          Compliance COE
                        </Descriptions.Item>
                      </Descriptions>
                      :
                      <Descriptions column={3} layout="vertical" size="small">
                        <Descriptions.Item label="Title">
                          {selectedNode?.label}
                        </Descriptions.Item>
                        <Descriptions.Item label="Details">
                          <Typography.Paragraph
                            copyable
                            ellipsis={{
                              rows: 4,
                              expandable: 'collapsible',
                              expanded: expanded[selectedNode?.id],
                              onExpand: (_, info) => setExpanded(cur => ({ ...cur, [selectedNode?.id]: info.expanded })),
                            }}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {properties.text}
                          </Typography.Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label="Topics">
                          <Space wrap size={8}>
                            {
                              selectedNode?.nodeAsSourceEdges
                                .filter(e => e.type === `${getLabel(nodeType)}_has_topic`)
                                .map(e => <Tag key={e.id} color="green" style={{ borderRadius: 3, margin: 0 }}>{e.name}</Tag>)
                            }
                          </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                          Published
                        </Descriptions.Item>
                        <Descriptions.Item label="Owner">
                          Compliance COE
                        </Descriptions.Item>
                      </Descriptions>
                    }
                  </>
                ),
              },
            ]}
          />
          {selectedNode?.type === 'Mandate' ?
            <Collapse ghost
              activeKey={activeKey}
              onChange={setActiveKey}
              items={[
                {
                  key: '2',
                  label: (
                    <Flex align="center" justify="space-between">
                      <div>Summary</div>
                      <Tag
                        color="white"
                        style={{ border: '1px solid rgba(60, 61, 65, 0.88)', borderRadius: 3, color: '#000', margin: 0, width: 32, justifyContent: 'center' }}
                      >
                        AI
                      </Tag>
                    </Flex>
                  ),
                  children: (
                    <Descriptions column={1} layout="vertical" size="small">
                      <Descriptions.Item label="Document Registration Date"></Descriptions.Item>
                      <Descriptions.Item label="Document Version Date"></Descriptions.Item>
                      <Descriptions.Item>{properties.summary}</Descriptions.Item>
                    </Descriptions>
                  ),
                },
              ]}
            />
            : null
          }
          {selectedNode?.type === 'BusinessRequirement' || selectedNode?.type === 'Obligation' ?
            <Collapse ghost
              activeKey={activeKey}
              onChange={setActiveKey}
              items={[
                {
                  key: '2',
                  label: (
                    <div>Metadata</div>
                  ),
                  children: (
                    <Descriptions column={2} layout="vertical" size="small">
                      <Descriptions.Item label="Responsible Entity">{properties.responsible_entity}</Descriptions.Item>
                      <Descriptions.Item label="Required Action">{properties.action}</Descriptions.Item>
                      <Descriptions.Item label="Conditions">{properties.conditions?.join(', ')}</Descriptions.Item>
                      <Descriptions.Item label="Sub-mandate References">{properties.references?.join(', ')}</Descriptions.Item>
                      <Descriptions.Item label="Notes">{properties.notes}</Descriptions.Item>
                    </Descriptions>
                  ),
                },
              ]}
            />
            : null
          }
          {selectedNode?.type === 'ComplianceArrangement' ?
            <Collapse ghost
              activeKey={activeKey}
              onChange={setActiveKey}
              items={[
                {
                  key: '2',
                  label: (
                    <div>Metadata</div>
                  ),
                  children: (
                    <Descriptions column={2} layout="vertical" size="small">
                      <Descriptions.Item label="Category">{properties.category}</Descriptions.Item>
                      <Descriptions.Item label="Subcategory">{properties.subcategory}</Descriptions.Item>
                      <Descriptions.Item label="Requirement Type">{properties.requirement_type}</Descriptions.Item>
                      <Descriptions.Item label="Sub-mandate References">{properties.references?.join(', ')}</Descriptions.Item>
                      <Descriptions.Item label="Notes">{properties.notes}</Descriptions.Item>
                    </Descriptions>
                  ),
                },
              ]}
            />
            : null
          }
          {selectedNode?.type === 'SubMandate' ?
            <Collapse ghost
              activeKey={activeKey}
              onChange={setActiveKey}
              items={[
                {
                  key: '5',
                  label: 'Admin functions',
                  children: (
                    <Flex vertical gap={8}>
                      <p>These functions are viewable only to admin roles.</p>
                      <Flex align="center" gap={8}>
                        <Button
                          loading={processing.brq}
                          onClick={handleRegenerateBusinessRequirements}
                          style={{ width: 285 }}
                        >
                          Regenerate Business Requirements
                        </Button>
                        <Button
                          disabled={true}
                          loading={processing.car}
                          onClick={handleRegenerateBusinessRequirements}
                          style={{ width: 285 }}
                        >
                          Regenerate Compliance Arrangements
                        </Button>
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />
            : null
          }
          <Flex justify="flex-end" style={{ marginTop: -24, marginBottom: -8 }}>
            <ChatBox />
          </Flex>
          {selectedNode?.type === 'Mandate' ?
            <Layout>
              <Sider
                theme="light"
                width={450}
              >
                <div style={{ padding: '16px 16px 8px 16px', fontWeight: 600 }}>{docTitle}</div>
                <div style={{ height: 500, overflowY: 'auto' }}>
                  <Tree
                    treeData={treeData}
                    onSelect={handleSelect}
                  />
                </div>
              </Sider>
              <Content>
                <Flex vertical className={expandView ? 'expanded' : ''} gap={8} style={{ padding: '24px 16px 24px 24px' }}>
                  {content ?
                    <>
                      <Flex align="center" gap={0}>
                        <ElementTag />
                        <div style={{ fontWeight: 600 }}>{selectedEdge?.name}</div>
                        <div style={{ flex: 1 }}></div>
                        <Button
                          icon={expandView ? <ShrinkOutlined /> : <ExpandAltOutlined />}
                          type="link"
                          onClick={() => setExpandView(prev => !prev)}
                        />
                      </Flex>
                      <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>
                        {content}
                      </Typography.Text>
                    </>
                    :
                    <div>No section selected</div>
                  }
                </Flex>
              </Content>
            </Layout>
            : null
          }
          {selectedNode?.type !== 'Mandate' ?
            <>
              {subtypes.map(([label, edges]) => {
                const data = edges.map(e => ({
                  id: e.id,
                  name: e.name,
                  text: e.targetData?.description || e.targetData?.text,
                  code: e.targetData?.code,
                  nodeType: e.target,
                }));
                data.sort((a, b) => a.code?.localeCompare(b.code));
                const columns = [
                  {
                    title: 'Code',
                    dataIndex: 'code',
                    width: 150,
                    render: (_, { code, id, nodeType }) => code ? <Button type="link" onClick={() => linkToNode(nodeType, id)}>{code}</Button> : null,
                  },
                  {
                    title: 'Title',
                    dataIndex: 'name',
                    width: 350,
                  },
                  {
                    title: 'Details',
                    dataIndex: 'text',
                    render: (_, { text, id }) => text ?
                      <Typography.Paragraph copyable
                        ellipsis={{
                          rows: 3,
                          expandable: 'collapsible',
                          expanded: expanded[id],
                          onExpand: (_, info) => setExpanded(cur => ({ ...cur, [id]: info.expanded })),
                        }}
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {text}
                      </Typography.Paragraph>
                      : null,
                  },
                ];

                return (
                  <>
                    <Flex style={{ marginBottom: -16 }}>
                      <Flex align="center" gap={16}
                        className="text-secondary"
                        style={{ lineHeight: '22px', width: 250, minWidth: 250 }}
                      >
                        <div>{spaced(pluralize(label))}</div>
                        <Tooltip title={getTooltip(label)}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Flex>
                    </Flex>
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 5,
                      }}
                    />
                  </>
                );
              })}
              <div>Related content</div>
              {supertypes.map(([label, edges]) => {
                const data = edges.map(e => ({
                  id: e.id,
                  code: e.sourceData?.code,
                  name: e.name,
                  text: e.sourceData?.summary || e.sourceData?.text,
                  nodeType: e.source,
                }));
                const columns = [
                  {
                    title: 'Code',
                    dataIndex: 'code',
                    width: 150,
                    render: (_, { code, id, nodeType }) => code ? <Button type="link" onClick={() => linkToNode(nodeType, id)}>{code}</Button> : null,
                  },
                  {
                    title: 'Title',
                    dataIndex: 'name',
                    width: 350,
                  },
                  {
                    title: 'Details',
                    dataIndex: 'text',
                    render: (_, { text, id }) => text ?
                      <Typography.Paragraph
                        copyable
                        ellipsis={{
                          rows: 3,
                          expandable: 'collapsible',
                          expanded: expanded[id],
                          onExpand: (_, info) => setExpanded(cur => ({ ...cur, [id]: info.expanded })),
                        }}
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {text}
                      </Typography.Paragraph>
                      : null,
                  },
                ];

                return (
                  <>
                    <Flex style={{ marginBottom: -16 }}>
                      <Flex align="center" gap={16}
                        className="text-secondary"
                        style={{ lineHeight: '22px', width: 250, minWidth: 250 }}
                      >
                        <div>{spaced(pluralize(label))}</div>
                        <Tooltip title={getTooltip(label)}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Flex>
                    </Flex>
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 5,
                      }}
                    />
                  </>
                );
              })}
              {/* <Flex>
                <Flex vertical gap={8} style={{ lineHeight: '22px', minWidth: 250, width: 250 }}>
                  <div className="text-secondary">{nodeType} summary</div>
                  <div>
                    <Button
                      type="link"
                      onClick={() => handleNodeDetails(selectedNode)}
                      style={{ height: 22, padding: '0 0 0 8px' }}
                    >
                      View details panel
                    </Button>
                  </div>
                </Flex>
                <Flex vertical gap={8} style={{ flex: 1, lineHeight: '22px' }}>
                  <div className="text-secondary">{selectedNode?.label} ({selectedNode?.id.slice(0, 5)})</div>
                  <div>{properties.summary || properties.text}</div>
                  {properties.video_id ?
                    <div style={{ marginTop: 8 }}>
                      <YouTube
                        onReady={onReady}
                        opts={opts}
                        videoId={selectedNode.video_id}
                      />
                    </div>
                    : null
                  }
                </Flex>
              </Flex>
              {subtypes.map(([label, edges]) => (
                <>
                  <Flex style={{ marginBottom: -16 }}>
                    <div className="text-secondary" style={{ lineHeight: '22px', width: 250, minWidth: 250 }}>
                      {spaced(pluralize(label))}
                    </div>
                    <div style={{ flex: 1, lineHeight: '22px' }}></div>
                  </Flex>
                  {edges.map((rel) =>
                    <Flex>
                      <div style={{ lineHeight: '22px', width: 250, minWidth: 250 }}>
                        <Button
                          type="link"
                          onClick={() => handleRelTargetDetails(rel)}
                          style={{ height: 22, padding: '0 0 0 8px' }}
                        >
                          View details panel
                        </Button>
                      </div>
                      <Flex vertical gap={8} style={{ flex: 1, lineHeight: '22px' }}>
                        <div className="text-secondary">{rel.name} ({rel.id.slice(0, 5)})</div>
                        <div>{rel.targetData?.description || rel.targetData?.text}</div>
                      </Flex>
                    </Flex>
                  )}
                </>
              ))} */}
            </>
            : null
          }
        </Flex>
      }
      <Drawer
        closable={true}
        onClose={onDrawerClose}
        open={drawerOpen}
        placement="right"
        title={properties.code}
        width={500}
      >
        <Collapse ghost
          activeKey={activeKey}
          onChange={setActiveKey}
          items={[
            {
              key: '3',
              label: 'Details',
              children: (
                <Flex vertical gap={16}>
                  <Descriptions column={1} size="small">
                    <Descriptions.Item key={'title'}
                      label={'Title'}
                      labelStyle={{ width: 110 }}
                    >
                      {selectedNode?.label}
                    </Descriptions.Item>
                    <Descriptions.Item key={'status'}
                      label={'Status'}
                      labelStyle={{ width: 110 }}
                    >
                      Published
                    </Descriptions.Item>
                    <Descriptions.Item key={'alerts'}
                      label={'Change alerts'}
                      labelStyle={{ width: 110 }}
                    >
                      2
                    </Descriptions.Item>
                    <Descriptions.Item key={'tasks'}
                      label={'Tasks to do'}
                      labelStyle={{ width: 110 }}
                    >
                      2/2
                    </Descriptions.Item>
                    <Descriptions.Item key={'changeId'}
                      label={'Change ID'}
                      labelStyle={{ width: 110 }}
                    >
                      <Link>CHG-1610</Link>
                    </Descriptions.Item>
                    {/* <Descriptions.Item key={'__id'}
                      label={'id'}
                      labelStyle={{ width: 130 }}
                    >
                      {selected?.id.slice(0, 5)}
                    </Descriptions.Item>
                    <Descriptions.Item key={'__type'}
                      label={'type'}
                      labelStyle={{ width: 130 }}
                    >
                      {selected?.type}
                    </Descriptions.Item>
                    <Descriptions.Item key={'__label'}
                      label={'label'}
                      labelStyle={{ width: 130 }}
                      style={{ borderBottom: '2px solid #ccc' }}
                    >
                      {selected?.label}
                    </Descriptions.Item>
                    {selected?.properties.map(([key, val]) =>
                      <Descriptions.Item key={key}
                        label={lowerCase(key)}
                        labelStyle={{ width: 130 }}
                      >
                        {Array.isArray(val) ? val.join(', ') : typeof val === 'boolean' ? (val ? 'T' : 'F') : val}
                        {key === 'video_id' && val ?
                          <div style={{ marginTop: 8 }}>
                            <YouTube
                              onReady={onReady}
                              opts={opts}
                              videoId={val}
                            />
                          </div>
                          : null
                        }
                      </Descriptions.Item>
                    )} */}
                  </Descriptions>
                  <hr />
                  <div>Owner</div>
                  <Flex align="center" gap={8}>
                    <Avatar icon={<UserOutlined />} />
                    <div>Compliance COE</div>
                  </Flex>
                  <hr />

                </Flex>
              ),
            },
          ]}
        />
        <Collapse ghost
          activeKey={activeKey}
          onChange={setActiveKey}
          items={[
            {
              key: '4',
              label: 'Compliance Framework',
              children: (
                <Timeline
                  items={[
                    {
                      children: (
                        <Flex vertical gap={8}>
                          <div style={{ fontWeight: '600' }}>Governance</div>
                          <Flex vertical>
                            <div className="text-secondary">Compliance obligations</div>
                            <Link>CTR-01-GOV-00001</Link>
                          </Flex>
                          <Flex vertical>
                            <div className="text-secondary">Leadership commitment</div>
                            <Link>CTR-01-GOV-00002</Link>
                          </Flex>
                          <Flex vertical>
                            <div className="text-secondary">Structure and accountabilities</div>
                            <Link>CTR-01-GOV-00003</Link>
                          </Flex>
                          <Flex vertical>
                            <div className="text-secondary">Compliance objectives</div>
                            <Link>CTR-01-GOV-00004</Link>
                          </Flex>
                        </Flex>
                      ),
                    },
                    {
                      children: (
                        <Flex vertical gap={8}>
                          <div style={{ fontWeight: '600' }}>Control Activities</div>
                          <Flex vertical>
                            <div className="text-secondary">Policies</div>
                            <Link>CTR-01-CA-00001</Link>
                          </Flex>
                          <Flex vertical>
                            <div className="text-secondary">Processes</div>
                            <Link>CTR-01-CA-00002</Link>
                          </Flex>
                          <Flex vertical>
                            <div className="text-secondary">Controls</div>
                            <Link>CTR-01-CA-00003</Link>
                          </Flex>
                        </Flex>
                      ),
                    },
                    {
                      children: (
                        <Flex vertical gap={8}>
                          <div style={{ fontWeight: '600' }}>Measurement and Reporting</div>
                          <Flex vertical>
                            <div className="text-secondary">Performance measurement</div>
                            <Link>CTR-01-MR-00001</Link>
                          </Flex>
                          <Flex vertical>
                            <div className="text-secondary">Reporting activities</div>
                            <Link>CTR-01-MR-00002</Link>
                          </Flex>
                          <Flex vertical>
                            <div className="text-secondary">Document control</div>
                            <Link>CTR-01-MR-00003</Link>
                          </Flex>
                        </Flex>
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  )
}

function ellipsis(s, n = 20) {
  if (!s) {
    return null;
  }

  const title = s.trim();
  if (title.length > n) {
    return title.slice(0, n) + "...";
  }

  return title;
}

function getLabel(name) {
  return name.replace(/([A-Z])/g, (match, p1, offset) => (offset > 0 ? "_" : "") + p1).toLowerCase();
}

function getTooltip(label) {
  if (label === 'BusinessRequirement') {
    return `A Business Requirement is a regulatory interpretation that translates obligations and sub-mandates into actionable steps for an organisation. It defines how to comply with these requirements, as articulated in official regulatory guidance, by aligning compliance expectations with the organisation’s operational processes and practices.`;
  }
  if (label === 'ComplianceArrangement') {
    return `A Compliance Arrangement specifies the arrangements, such as controls, policies, processes, procedures, and training, that an organisation must have in place to meet obligations and sub-mandates. It outlines the key compliance elements necessary to demonstrate adherence to regulatory or internal expectations.`;
  }
  if (label === 'Mandate') {
    return `A Mandate is a formal requirement issued by an external authority, such as a law, regulation, or standard, that an organisation must comply with. Mandates define the overarching obligations, providing the foundation for compliance activities and governance frameworks within the organisation.`;
  }
  if (label === 'SubMandate') {
    return `A sub-mandate is a specific provision, rule, or requirement derived from a broader mandate that an organisation must comply with. It can be external, such as a clause within legislation or a regulatory standard, or internal, such as organisational policies and procedures that support compliance with the overarching mandate. Sub-mandates provide a more granular breakdown of the obligations set forth by a mandate.`;
  }
  return null;
}

function getTagAttributes(edge) {
  let type, color;
  if (edge.type === 'mandate_contains_sub_preamble') {
    type = 'PRE';
    color = '';
  } else if (edge.type === 'mandate_contains_sub_mandate') {
    type = 'SMD';
    color = 'orange';
  } else {
    const elementType = edge.targetData?.section_type;
    const role = edge.targetData?.role;
    if (role === 'objectives') {
      type = 'OBJ';
      color = 'orange';
    } else if (CONTEXT_ROLES.includes(role)) {
      type = 'CTX';
      color = 'purple';
    } else if (elementType === 'part') {
      type = 'PRT';
      color = 'green';
    } else if (elementType === 'division') {
      type = 'DIV';
      color = 'geekblue';
    } else if (elementType === 'subdivision') {
      type = 'SUB';
      color = 'volcano';
    } else if (elementType === 'preamble') {
      type = 'PRE';
      color = '';
    } else if (elementType === 'note') {
      type = 'NOT';
      color = 'cyan';
    } else {
      type = 'SEC';
      color = 'blue';
    }
    return { type, color };
  }

}