import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';

import { useAuth } from '../../contexts/AuthContext';
import {
  upsertUserAsync,
  selectUsers,
} from '../../features/users/usersSlice';

import background from '../../images/background-blank.png';

const APP = process.env.REACT_APP_NAME === 'Compliance Copilot' ? 'compliancecopilot' : 'pqacademy';

export default function Register() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const users = useSelector(selectUsers);

  const { currentUser, register, setError, updateUserProfile } = useAuth();

  const hostname = window.location.hostname;
  const subdomain = hostname?.split('.')[0];
  const is_survey = subdomain?.toLowerCase().endsWith('dd');

  useEffect(() => {
    if (users && currentUser) {
      const user = Object.values(users).find(u => u.email === currentUser.email);
      if (user) {
        try {
          updateUserProfile(currentUser, { displayName: user.fullName });
          if (APP === 'pqacademy') {
            if (is_survey) {
              window.location.href = '/assessments';
            } else {
              window.location.href = '/tutor';
            }
          } else {
            window.location.href = '/';
          }
        } catch (err) {
          let message = err.message;
          if (err.stack) {
            message += '\n' + err.stack;
          }
          console.error(message);
        }
      }
    }
  }, [currentUser, users]);

  const handleFormSubmit = async (values) => {
    const { email, firstName, lastName, password, confirmPassword } = values;
    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }
    try {
      setError('');
      setLoading(true);
      await register(email, password);
      let displayName = firstName;
      if (lastName) {
        displayName += ' ' + lastName;
      }
      const user = {
        username: email,
        fullName: displayName,
        firstName,
        lastName,
        email,
      };
      dispatch(upsertUserAsync(user));
      setLoading(false);
    } catch (err) {
      let message = err.message;
      if (err.stack) {
        message += '\n' + err.stack;
      }
      console.error(message);
      setLoading(false);
      navigate('/login');
    }
  }

  return (
    <div style={{ background: `url(${background}) no-repeat center bottom`, backgroundSize: 'cover', display: 'flex', alignItems: 'center', height: '100vh' }}>
      <Card
        title="Register your account"
        style={{ height: 611, width: 500, marginLeft: '20%' }}
      >
        <Form
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Form.Item>
            <Form.Item
              label="first name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please enter a first name',
                },
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 4px)', margin: 0 }}
            >
              <Input autoComplete="given-name" placeholder="First name" />
            </Form.Item>
            <Form.Item
              label="last name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please enter a last name',
                },
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 4px)', margin: '1px 0 0 8px' }}
            >
              <Input autoComplete="family-name" placeholder="Last name" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter an email address',
              },
            ]}
          >
            <Input autoComplete="email" placeholder="Email address" />
          </Form.Item>
          <Form.Item
            label="password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter a password',
              },
            ]}
          >
            <Input autoComplete="new-password" type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            label="confirm password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please enter a password',
              },
            ]}
          >
            <Input autoComplete="new-password" type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button disabled={loading} type="primary" htmlType="submit">Register</Button>
            </div>
          </Form.Item>
          <Form.Item>
            <Link
              to="/login"
            >
              Already have an account? Login
            </Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
