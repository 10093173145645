import { configureStore } from '@reduxjs/toolkit';

import adminReducer from '../features/admin/adminSlice';
import artefactsReducer from '../features/artefacts/artefactsSlice';
import assessmentsReducer from '../features/assessments/assessmentsSlice';
import authReducer from '../authSlice';
import botsReducer from '../features/interviews/botsSlice';
import chatSessionsReducer from '../features/tutor/chatSessionsSlice';
import chatReducer from '../features/chat/chatSlice';
import fileUploaderReducer from '../features/uploader/fileUploaderSlice';
import graphReducer from '../features/graph/graphSlice';
import graphSessionsReducer from '../features/graph/graphSessionsSlice';
import inferenceRunsReducer from '../features/inference/inferenceRunsSlice';
import metricsReducer from '../features/metrics/metricsSlice';
import metricSetsReducer from '../features/metrics/metricSetsSlice';
import notebooksReducer from '../features/notebooks/notebooksSlice';
import ontologiesReducer from '../features/ontology/ontologiesSlice';
import reportReducer from '../features/report/reportSlice';
import reviewReducer from '../features/review/reviewSlice';
import searchReducer from '../features/search/searchSlice';
import sectionsReducer from '../features/sections/sectionsSlice';
import settingsReducer from '../features/settings/settingsSlice';
import surveyReducer from '../features/survey/surveySlice';
import tasksReducer from '../features/tasks/tasksSlice';
import tutorReducer from '../features/tutor/tutorSlice';
import usersReducer from '../features/users/usersSlice';
import workspacesReducer from '../features/workspaces/workspacesSlice';

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    artefacts: artefactsReducer,
    assessments: assessmentsReducer,
    auth: authReducer,
    bots: botsReducer,
    chat: chatReducer,
    chatSessions: chatSessionsReducer,
    fileUploader: fileUploaderReducer,
    graph: graphReducer,
    graphSessions: graphSessionsReducer,
    inferenceRuns: inferenceRunsReducer,
    metrics: metricsReducer,
    metricSets: metricSetsReducer,
    notebooks: notebooksReducer,
    ontologies: ontologiesReducer,
    report: reportReducer,
    review: reviewReducer,
    search: searchReducer,
    sections: sectionsReducer,
    settings: settingsReducer,
    survey: surveyReducer,
    tasks: tasksReducer,
    tutor: tutorReducer,
    users: usersReducer,
    workspaces: workspacesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
