import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Tooltip } from 'antd';
import { ExpandOutlined, MinusOutlined, PlusOutlined, ShareAltOutlined } from '@ant-design/icons';
import { GraphCanvas, useSelection } from 'reagraph';
import { v4 as uuidv4 } from 'uuid';
import pluralize from 'pluralize';

import WorkspaceContext from '../contexts/WorkspaceContext';
import Toolbar from './Toolbar';
import {
  getOntologiesAsync,
  selectOntologies,
} from '../features/ontology/ontologiesSlice';
import {
  getGraphFromNodeAsync,
  selectGraphs,
} from '../features/graph/graphSlice';

const theme = {
  canvas: { background: '#FBFBFB' },
  node: {
    fill: '#7CA0AB',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.2,
    label: {
      color: '#2A6475',
      stroke: '#fff',
      activeColor: '#1DE9AC'
    },
    subLabel: {
      color: '#ddd',
      stroke: 'transparent',
      activeColor: '#1DE9AC'
    }
  },
  lasso: {
    border: '1px solid #55aaff',
    background: 'rgba(75, 160, 255, 0.1)'
  },
  ring: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  edge: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475',
      activeColor: '#1DE9AC',
      fontSize: 6
    }
  },
  arrow: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  cluster: {
    stroke: '#D8E6EA',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475'
    }
  }
};

const spaced = (name) => {
  return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

export default function GraphContextView({ buttonType = "default", nodeType, nodeId }) {

  const [open, setOpen] = useState(false);
  const [ready, setReady] = useState(false);

  const graphs = useSelector(selectGraphs);
  const ontologies = useSelector(selectOntologies);

  const correlationIdRef = useRef(null);
  const graphRef = useRef();
  const nodeRef = useRef(new Map());

  const graph = graphs[correlationIdRef.current];
  const labels = [...new Set(graph?.nodes.map(n => n.type) || [])];

  // console.log('graph:', graph);

  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      const correlationId = uuidv4();
      dispatch(getOntologiesAsync({ workspaceId }));
      dispatch(getGraphFromNodeAsync({
        correlationId,
        nodeId,
        nodeType,
        workspaceId,
      }));
      correlationIdRef.current = correlationId;
    }
  }, [selectedWorkspace]);

  const ontology = useMemo(() => {
    if (selectedWorkspace && Object.keys(ontologies).length) {
      let ontology = selectedWorkspace.defaultOntology;
      if (!ontology) {
        ontology = Object.values(ontologies)[0].id;
      }
      return ontology;
    }
  }, [ontologies, selectedWorkspace]);

  useEffect(() => {
    if (open) {
      setTimeout(() => setReady(true), 800);
    }
  }, [open]);

  const { selections, clearSelections, actives, onNodeClick, onCanvasClick } = useSelection({
    ...graph,
    ref: graphRef,
    type: 'multiModifier',
    pathSelectionType: 'all',
  });

  const handleCancel = () => {
    setOpen(false);
    setReady(false);
  };

  const handleCanvasClick = (ev) => {
    onCanvasClick(ev);
  };

  const handleNodeDoubleClick = (node) => {
    // console.log('node:', node);
    window.location.href = `/node-details/${node.type}/${node.id}?title=${spaced(pluralize(node.type))}&ontology=${ontology}`;
  };

  return (
    <>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        onOk={handleCancel}
        open={open}
        title="In-context View"
        width={1040}
        loading={open && !ready}
      >
        <div style={{ width: '100%', height: 600, position: 'relative' }}>
          {graph && ready ?
            <>
              <div className="graph-legend" style={{ right: 10 }}>
                <fieldset>
                  <legend>Legend</legend>
                  {Object.entries(graph.legend || {}).filter(([label, _]) => labels.includes(label)).map(([label, { color }]) => (
                    <div key={label} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <label>{label}</label>
                      <div className="color-circle" style={{ backgroundColor: color }} />
                    </div>
                  ))}
                </fieldset>
              </div>
              <GraphCanvas
                draggable
                {...graph}
                ref={graphRef}
                onCanvasClick={ev => {
                  handleCanvasClick(ev);
                }}
                onNodeClick={ev => {
                  onNodeClick(ev);
                }}
                onNodeDoubleClick={handleNodeDoubleClick}
                theme={theme}
                edgeLabelPosition="inline"
                layoutOverrides={{
                  getNodePosition: id => {
                    return nodeRef.current.get(id)?.position;
                  }
                }}
                onNodeDragged={node => {
                  nodeRef.current.set(node.id, node);
                }}
              />
              <div style={{ position: 'absolute', bottom: 10, left: 10 }}>
                <Toolbar size="small"
                  options={[
                    {
                      icon: <PlusOutlined />,
                      title: 'Zoom in',
                      onClick: () => graphRef.current?.zoomIn(),
                    },
                    {
                      icon: <MinusOutlined />,
                      title: 'Zoom out',
                      onClick: () => graphRef.current?.zoomOut(),
                    },
                    {
                      icon: <ExpandOutlined />,
                      title: 'Fit in view',
                      onClick: () => graphRef.current?.fitNodesInView(),
                    },
                  ]}
                />
              </div>
            </>
            : null
          }
        </div>
        <div className="text-secondary">
          Double-click to navigate to object
        </div>
      </Modal>
      <Tooltip title="Show object in context">
        <Button
          type={buttonType}
          icon={<ShareAltOutlined />}
          onClick={() => setOpen(prev => !prev)}
        />
      </Tooltip>
    </>
  );
}