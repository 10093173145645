import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Card, Col, Row, message } from 'antd';
import { FlagFilled } from '@ant-design/icons';
import Chart from 'chart.js/auto';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  getAssessmentAsync,
  selectCorrelationId,
  selectAssessments,
  selectLoaded,
} from './assessmentsSlice';

const { Meta } = Card;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const labels = [
  'Structure',
  'EQ',
  'Ego',
  'Excitement',
  'Insight',
];

const dataSource = [
  4,
  3,
  2,
  4,
  3,
];

export function Assessment() {

  const [correlationId, setCorrelationId] = useState(null);
  const [onDone, setOnDone] = useState(null);

  const loaded = useSelector(selectLoaded);
  const assessments = useSelector(selectAssessments);
  const returnCorrelationId = useSelector(selectCorrelationId);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  const id = location.pathname.match(/\/assessments\/(.*)/)[1];
  const isNew = id === 'new';
  const assessment = assessments[id];

  // console.log('assessment:', assessment);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Assessment - 20/7/2024 10:30',
    }));
    if (!isNew) {
      dispatch(getAssessmentAsync(id));
    }
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (correlationId && correlationId === returnCorrelationId && onDone) {
      onDone();
      setCorrelationId(null);
      setOnDone(null);
    }
  }, [returnCorrelationId]);

  useEffect(() => {
    if (location.state && location.state.message) {
      messageApi.info({
        content: location.state.message,
        duration: 5,
      });
    }
  }, [location]);

  useEffect(() => {
    const data = {
      labels,
      datasets: [
        {
          label: 'Performance Categories',
          data: dataSource,
          fill: true,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)',
        },
      ],
    };
    const config = {
      type: 'radar',
      data,
      options: {
        elements: {
          line: {
            borderWidth: 3
          }
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 5,
            ticks: {
              callback: (val, index) => {
                return index % 2 === 0 ? val : undefined;
              },
            },
          },
        },
      },
    };
    setTimeout(() => {
      const canvas = document.getElementById('chart');
      new Chart(canvas, config);
    }, 200);
  }, []);

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <>
      {contextHolder}
      <div style={{ background: '#f5f5f5', padding: 24, height: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', height: 400, minWidth: 400 }}>
          <canvas id="chart"></canvas>
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={{ display: 'flex', gap: 24 }}>
            <Card
              style={{
                width: '50%',
              }}
            >
              <Meta
                avatar={
                  <Avatar
                    icon={<FlagFilled style={{ color: 'green' }} />}
                    size={64}
                  >
                  </Avatar>
                }
                title={<Link>Green Flags</Link>}
                description={
                  <>
                    <Row>
                      <Col span={12}>Points</Col>
                      <Col span={12}>Examples</Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <ul>
                          <li>&nbsp;</li>
                          <li>&nbsp;</li>
                          <li>&nbsp;</li>
                        </ul>
                      </Col>
                      <Col span={12}>
                        <ul>
                          <li>&nbsp;</li>
                          <li>&nbsp;</li>
                          <li>&nbsp;</li>
                        </ul>
                      </Col>
                    </Row>
                  </>
                }
                style={{ color: 'rgba(0, 0, 0, 0.88)' }}
              />
            </Card>
            <Card
              style={{
                width: '50%',
              }}
            >
              <Meta
                avatar={
                  <Avatar
                    icon={<FlagFilled style={{ color: 'red' }} />}
                    size={64}
                  >
                  </Avatar>
                }
                title={<Link>Red Flags</Link>}
                description={
                  <>
                    <Row>
                      <Col span={12}>Points</Col>
                      <Col span={12}>Examples</Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <ul>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </Col>
                      <Col span={12}>
                        <ul>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </Col>
                    </Row>
                  </>
                }
                style={{ color: 'rgba(0, 0, 0, 0.88)' }}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
