import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Image, Input, List, Modal } from 'antd';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import pluralize from 'pluralize';
import YouTube from 'react-youtube';

import { ContentView } from './ContentView';
import WorkspaceContext from '../contexts/WorkspaceContext';
import { isFloat } from '../numberUtils';
import { getExtension } from '../pathUtils';
import {
  getUploadForObjectNameAsync,
  selectUploads,
} from '../features/uploader/fileUploaderSlice';
import {
  searchAsync,
  selectResults,
} from '../features/search/searchSlice';

const formatMetric = (distance) => {
  return (Math.round(distance * 100) / 100).toFixed(4);
};

const { Search } = Input;

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    rel: 0,
  },
};

const spaced = (name) => {
  return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

export default function SearchForm({ ontology, size = 'middle', width = 650 }) {

  const [isModalOpen, setModalOpen] = useState(false);
  const [isVideoPreviewModalOpen, setVideoPreviewModalOpen] = useState(false);
  const [isSearchResultsModalOpen, setSearchResultsModalOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedObject, setSelectedObject] = useState(null);
  const [upload, setUpload] = useState(null);
  const [videoParams, setVideoParams] = useState({});

  const results = useSelector(selectResults);
  const uploads = useSelector(selectUploads);

  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  const ext = useMemo(() => {
    if (!upload) return null;
    getExtension(upload.filename);
  }, [upload]);

  useEffect(() => {
    if (selectedObject) {
      const upload = uploads.find(u => u.objectName === selectedObject);
      if (upload) {
        setUpload(upload);
        setModalOpen(true);
        setSelectedObject(null);
      }
    }
  }, [uploads]);

  const hits = useMemo(() => {
    if (results) {
      const metricType = results.metric_type;
      return results.result.map(r => {
        const chunkFields = [
          'object_name',
          'filename',
          'filetype',
          'languages',
          'last_modified',
          'page_number',
          'row_number',
          'index',
          'timestamps',
          'channel',
          'video_id',
          'url',
          'start',
          'duration',
          'tags',
        ];
        const exclude = [
          'node_id',
          'node_type',
          'text',
          '_distance',
          '_relevance_score',
          '_score',
          'metadata',
          ...chunkFields,
        ];
        const props = {
          ...omit(r, exclude),
          ...omit(r.metadata, [...chunkFields, 'thumbnail']),
        };
        const meta = Object.entries(props)
          .filter(([_, v]) => typeof v !== 'undefined' && v !== null)
          .map(([k, v]) => `${k}=${String(v)}`)
          .join('; ');
        const datasourceProps = pick(r, chunkFields);
        const datasource = Object.entries(datasourceProps)
          .filter(([_, v]) => typeof v !== 'undefined' && v !== null)
          .map(([k, v]) => [k, String(v)]);

        let distance, score;
        if (metricType === 'distance') {
          distance = formatMetric(r._distance);
        } else {
          score = formatMetric(r._relevance_score || r._score);
        }

        return {
          key: r.node_id,
          title: r.node_type,
          description: r.text,
          metricType,
          distance,
          score,
          meta,
          datasource,
          objectName: r.object_name,
          thumbnail: r.metadata?.thumbnail,
        };
      });
    }
    return [];
  }, [results]);

  // console.log('hits:', hits);
  // console.log('results:', results);

  const onCancel = () => {
    setModalOpen(false);
    setUpload(null);
  };

  const onChange = (ev) => {
    setSearchText(ev.target.value);
  };

  const onSearch = (query) => {
    if (query) {
      const workspaceId = selectedWorkspace.id;
      dispatch(searchAsync({
        query,
        excludeTypes: ['Chunk', 'Document'],
        workspaceId,
      }));
      setSearchResultsModalOpen(true);
    }
  };

  const showContent = (objectName) => {
    dispatch(getUploadForObjectNameAsync({
      objectName,
      workspaceId: selectedWorkspace.id,
    }));
    setSelectedObject(objectName);
  };

  const onCancelVideoPreview = () => {
    player.pauseVideo();
    setVideoPreviewModalOpen(false);
    setVideoParams({});
  };

  const onCancelSearchResults = () => {
    setSearchResultsModalOpen(false);
  };

  const showVideo = (item) => {
    const ds = item.datasource.reduce((a, [key, value]) => {
      a[key] = value;
      return a;
    }, {});
    setVideoParams({
      videoId: ds.video_id,
      start: parseInt(ds.start),
    });
    setVideoPreviewModalOpen(true);
  };

  const onReady = (ev) => {
    // ev.target.pauseVideo();
    setPlayer(ev.target);
  };

  const linkToNode = (nodeType, id) => {
    window.location.href = `/node-details/${nodeType}/${id}?title=${spaced(pluralize(nodeType))}&ontology=${ontology}`;
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        title="Content Preview"
        width={ext === 'pdf' ? 643 : '70%'}
        styles={{
          body: { height: 500, overflowY: 'auto' },
        }}
        onCancel={onCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <ContentView upload={upload} />
      </Modal>
      <Modal
        open={isVideoPreviewModalOpen}
        title="Content Preview"
        width={688}
        onCancel={onCancelVideoPreview}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <YouTube
          onReady={onReady}
          opts={{
            ...opts,
            playerVars: { ...opts.playerVars, start: videoParams.start },
          }}
          videoId={videoParams.videoId}
        />
      </Modal>
      <Modal
        open={isSearchResultsModalOpen}
        title="Search Results"
        width={688}
        onCancel={onCancelSearchResults}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <List
          itemLayout="horizontal"
          dataSource={hits}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                      marginBottom: item.title === 'Video' ? 8 : 0,
                    }}
                  >
                    {item.title === 'Video' ?
                      <Image
                        onClick={() => showVideo(item)}
                        preview={false}
                        src={item.thumbnail}
                        style={{ cursor: 'pointer' }}
                      />
                      : null
                    }
                    <div><Button type="link" onClick={() => linkToNode(item.title, item.key)} style={{ padding: 0 }}>{spaced(item.title)}</Button></div>
                    <div style={{ flex: 1 }}></div>
                    {item.metricType === 'distance' ?
                      <div>Distance: {item.distance} JSON: {JSON.stringify(item)}</div>
                      :
                      <div>Score: {item.score}</div>
                    }
                  </div>
                }
                description={
                  <>
                    <p>{item.description}</p>
                    {/* <p>({item.meta})</p> */}
                    {item.title === 'Image' ?
                      <div style={{ marginTop: 20, textAlign: 'center' }}>
                        <Image src={'/api/images/object/' + item.objectName} width={250} />
                      </div>
                      : null
                    }
                    {/* {item.datasource.length ?
                      <Collapse
                        ghost
                        items={[
                          {
                            key: '1',
                            label: 'Data source',
                            children: (
                              <ul style={{ listStyle: 'none' }}>
                                {item.datasource.map(([k, v], i) => {
                                  if (k === 'object_name') {
                                    return (
                                      <li key={item.key + '-' + i}>
                                        {k} = <Link onClick={() => showContent(v)}>{v}</Link>
                                      </li>
                                    );
                                  }
                                  if (k === 'video_id') {
                                    return (
                                      <li key={item.key + '-' + i}>
                                        {k} = <Link onClick={() => showVideo(item)}>{v}</Link>
                                      </li>
                                    );
                                  }
                                  return (
                                    <li key={item.key + '-' + i}>{k} = {format(v)}</li>
                                  );
                                })}
                              </ul>
                            ),
                          }
                        ]}
                        size="small"
                      />
                      : null
                    } */}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
      <Search allowClear
        value={searchText}
        onChange={onChange}
        onSearch={onSearch}
        size={size}
        style={{ width }}
        placeholder="Search the knowledge base..."
      />
    </>
  )
}

const format = (value) => {
  if (isFloat(value)) {
    return Number(value).toFixed(2);
  }
  return value;
};