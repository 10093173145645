import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash.omit';

import { http } from '../../http';

export const graphSlice = createSlice({
  name: 'graph',
  initialState: {
    graphLegend: {},
    graphSize: 0,
    graphs: {},
    loaded: false,
    loading: false,
    nodes: [],
    nodesMetadata: {},
    relationshipsMetadata: {},
    nodeOptions: {},
    nodeLabels: [],
  },
  reducers: {
    setGraphLegend: (state, action) => {
      state.graphLegend = action.payload.graphLegend;
    },
    setGraphSize: (state, action) => {
      state.graphSize = action.payload.graphSize;
    },
    setGraph: (state, action) => {
      const { correlationId, graph } = action.payload;
      state.graphs[correlationId] = graph;
      state.loading = false;
      state.loaded = true;
    },
    startLoad: (state, action) => {
      state.loading = true;
      state.loaded = false;
    },
    setNodesMetadata: (state, action) => {
      state.nodesMetadata = action.payload.nodesMetadata;
      state.loading = false;
      state.loaded = true;
    },
    setRelationshipsMetadata: (state, action) => {
      state.relationshipsMetadata = action.payload.relationshipsMetadata;
    },
    setNodeOptions: (state, action) => {
      state.nodeOptions = action.payload.nodeOptions;
    },
    setNodes: (state, action) => {
      state.nodes = action.payload.nodes;
    },
    setNodeLabels: (state, action) => {
      state.nodeLabels = action.payload.nodeLabels;
    },
  }
});

export const {
  setGraphLegend,
  setGraphSize,
  setGraph,
  startLoad,
  setNodesMetadata,
  setRelationshipsMetadata,
  setNodeOptions,
  setNodes,
  setNodeLabels,
} = graphSlice.actions;

export const addPropertyTypeAsync = ({ correlationId, ...req }) => async (dispatch) => {
  const url = `/api/graph/property_types`;
  const res = await http.post(url, req);
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const savePropertyAsync = ({ correlationId, ...req }) => async (dispatch) => {
  const url = `/api/graph/properties`;
  const res = await http.post(url, req);
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const getGraphLegendAsync = ({ workspaceId }) => async (dispatch) => {
  const url = `/api/graph/legend?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setGraphLegend({ graphLegend: res.data }));
};

export const getGraphSizeAsync = ({ workspaceId }) => async (dispatch) => {
  const url = `/api/graph/count?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setGraphSize({ graphSize: res.data }));
};

export const getGraphAsync = ({ correlationId, limit = 1000, skip = 0, workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph?workspace_id=${workspaceId}&limit=${limit}&skip=${skip}`;
  const res = await http.get(url);
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const getGraphFromNodeAsync = ({ append, correlationId, nodeId, nodeType, workspaceId }) => async (dispatch, getState) => {
  dispatch(startLoad());
  let url = `/api/graph/from-node/${nodeType}/${nodeId}?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  if (append) {
    const g1 = res.data;
    const newNodeIds = g1.nodes.map(n => n.id);
    const newEdgeIds = g1.edges.map(e => e.id);
    const graphs = getState().graph.graphs;
    const g = graphs[correlationId];
    const filteredNodes = g.nodes.filter(n => !newNodeIds.includes(n.id));
    const filteredEdges = g.edges.filter(e => !newEdgeIds.includes(e.id));
    const graph = {
      ...g,
      nodes: [...filteredNodes, ...g1.nodes],
      edges: [...filteredEdges, ...g1.edges],
    };
    dispatch(setGraph({ correlationId, graph: { ...graph, expandTypes: true } }));
  } else {
    dispatch(setGraph({ correlationId, graph: { ...res.data, expandTypes: true } }));
  }
};

export const runCypherQuery = ({ correlationId, query, workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/cypher-query`;
  const res = await http.post(url, { query, workspaceId });
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const runGraphQuery = ({ correlationId, ...request }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/query-graph`;
  const res = await http.post(url, request);
  const graph = res.data;
  dispatch(setGraphSize({ graphSize: graph.nodes.length }));
  dispatch(setGraph({ correlationId, graph }));
};

export const searchGraphAsync = ({ correlationId, query, workspaceId }) => async (dispatch) => {
  const url = `/api/graph/search`;
  const res = await http.post(url, { q: query, workspaceId });
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const getNodeLabelsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/node-labels?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNodeLabels({ nodeLabels: res.data }));
};

export const getNodesAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/nodes?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNodes({ nodes: res.data }));
};

export const getNodeOptionsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/nodes-options?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNodeOptions({ nodeOptions: res.data }));
};

export const getNodesMetadataAsync = ({ workspaceId, domain }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/nodes-metadata?workspace_id=${workspaceId}&domain=${domain}`;
  const res = await http.get(url);
  dispatch(setNodesMetadata({ nodesMetadata: res.data }));
};

export const getRelationshipsMetadataAsync = ({ workspaceId }) => async (dispatch) => {
  const url = `/api/graph/relationships/metadata?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setRelationshipsMetadata({ relationshipsMetadata: res.data }));
};

export const regenerateBusinessRequirementsAsync = (request) => async (dispatch) => {
  const url = `/api/activities/regenerate-business-requirements`;
  const res = await http.post(url, request);
};

export const mergeNodesAsync = ({
  correlationId,
  fill,
  icon,
  nodeType,
  nodeIds,
  target,
  workspaceId,
}) => async (dispatch, getState) => {
  const url = `/api/graph/merge-nodes`;
  const res = await http.post(url, { nodeType, nodeIds, target, workspaceId, fill, icon });
  const g1 = res.data;
  const newNodeIds = g1.nodes.map(n => n.id);
  const newEdgeIds = g1.edges.map(e => e.id);
  const graphs = getState().graph.graphs;
  const g = graphs[correlationId];
  const filteredNodes = g.nodes.filter(n =>
    !nodeIds.includes(n.id) &&
    !newNodeIds.includes(n.id)
  );
  const filteredEdges = g.edges.filter(e =>
    !nodeIds.includes(e.source) &&
    !nodeIds.includes(e.target) &&
    !newEdgeIds.includes(e.id)
  );
  const graph = {
    ...g,
    nodes: [...filteredNodes, ...g1.nodes],
    edges: [...filteredEdges, ...g1.edges],
  };
  dispatch(setGraphSize({ graphSize: graph.nodes.length }));
  dispatch(setGraph({ correlationId, graph }));
  const nodes = getState().graph.nodes;
  dispatch(setNodes({
    nodes: [
      ...nodes.filter(n => !nodeIds.includes(n.id)),
      ...g1.nodes,
    ],
  }));
};

const formatNode = (node, fill, icon) => {
  return {
    fill,
    icon,
    id: node.id,
    label: node.name,
    type: node.type,
    data: omit(node, ['id', 'type']),
  };
};

export const createNodeAsync = ({ correlationId, fill, icon, node, anchorNode, direction, relType, workspaceId }) => async (dispatch, getState) => {
  dispatch(startLoad());
  const url = `/api/graph/nodes`;
  const res = await http.post(url, { node, anchorNode, direction, relType, workspaceId });
  const newEdge = res.data;
  const newNode = formatNode(node, fill, icon);
  const graphs = getState().graph.graphs;
  const g = graphs[correlationId];
  const filteredNodes = g.nodes.filter(n => n.id !== newNode.id);
  let graph;
  if (newEdge) {
    graph = { ...g, nodes: [...filteredNodes, newNode], edges: [...g.edges, newEdge] };
  } else {
    graph = { ...g, nodes: [...filteredNodes, newNode] };
  }
  dispatch(setGraphSize({ graphSize: graph.nodes.length }));
  dispatch(setGraph({ correlationId, graph }));
  const nodes = getState().graph.nodes;
  dispatch(setNodes({ nodes: [...nodes, newNode] }));
};

export const deleteNodeAsync = ({ correlationId, id, type, workspaceId }) => async (dispatch, getState) => {
  let url = `/api/graph/nodes/${type}/${id}?workspace_id=${workspaceId}`;
  const res = await http.delete(url);
  const graphs = getState().graph.graphs;
  const g = graphs[correlationId];
  const graph = { ...g, nodes: g.nodes.filter(n => n.id !== id) };
  dispatch(setGraphSize({ graphSize: graph.nodes.length }));
  dispatch(setGraph({ correlationId, graph }));
  const nodes = getState().graph.nodes;
  dispatch(setNodes({ nodes: nodes.filter(n => n.id !== id) }));
};

export const createRelationshipAsync = ({ correlationId, relationship, workspaceId }) => async (dispatch, getState) => {
  dispatch(startLoad());
  const url = `/api/graph/relationships`;
  const res = await http.post(url, { relationship, workspaceId });
  const graphs = getState().graph.graphs;
  const g = graphs[correlationId];
  const graph = { ...g, edges: [...g.edges, res.data] };
  dispatch(setGraph({ correlationId, graph }));
};

export const deleteRelationshipAsync = ({ correlationId, id, type, workspaceId }) => async (dispatch, getState) => {
  let url = `/api/graph/relationships/${type}/${id}?workspace_id=${workspaceId}`;
  const res = await http.delete(url);
  const graphs = getState().graph.graphs;
  const g = graphs[correlationId];
  const graph = { ...g, edges: g.edges.filter(e => e.id !== id) };
  dispatch(setGraph({ correlationId, graph }));
};

export const selectGraphLegend = (state) => state.graph.graphLegend;

export const selectGraphSize = (state) => state.graph.graphSize;

export const selectGraphs = (state) => state.graph.graphs;

export const selectLoaded = (state) => state.graph.loaded;

export const selectLoading = (state) => state.graph.loading;

export const selectNodesMetadata = (state) => state.graph.nodesMetadata;

export const selectRelationshipsMetadata = (state) => state.graph.relationshipsMetadata;

export const selectNodeOptions = (state) => state.graph.nodeOptions;

export const selectNodes = (state) => state.graph.nodes;

export const selectNodeLabels = (state) => state.graph.nodeLabels;

export default graphSlice.reducer;
