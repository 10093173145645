import isEmpty from 'lodash.isempty';

export const filterGraph = (graph, selectedTypes) => {
  if (!selectedTypes) {
    selectedTypes = {};
  }
  if (graph) {
    const nodes = graph.nodes.filter(n => selectedTypes[n.type]);
    const nodesMap = graph.nodes.reduce((a, n) => {
      a[n.id] = n.type;
      return a;
    }, {});
    const edges = graph.edges.filter(r => {
      return selectedTypes[nodesMap[r.source]] && selectedTypes[nodesMap[r.target]];
    });
    return { nodes, edges, legend: graph.legend };
  }
  return null;
};

export const getDataSource = (graph, asAdjacencyMatrix) => {
  if (graph.nodes?.length) {
    const nodeMap = graph.nodes.reduce((a, n) => {
      a[n.id] = n;
      return a;
    }, {});
    let relationships = {};
    if (asAdjacencyMatrix) {
      const rels = {};
      for (const rel of graph.edges) {
        const targetNode = graph.nodes.find(node => node.id == rel.target);
        const key = targetNode.type + ' - ' + targetNode.label;
        rels[key] = 0;
      }
      const keys = Object.keys(rels);
      keys.sort();
      relationships = keys.reduce((a, r) => {
        a[r] = 0;
        return a;
      }, {});
    }

    return graph.nodes
      .map(node => {
        const props = Object.entries(node.data || {})
          .filter(([k, v]) => k !== 'id' && typeof v !== 'undefined');

        props.sort((a, b) => a[0] < b[0] ? -1 : 1);
        const properties = props.reduce((a, [k, v]) => {
          a[k] = v;
          return a;
        }, {});

        const nodeAsSourceEdges = [];
        const nodeAsTargetEdges = [];
        for (const rel of graph.edges) {
          if (rel.source === node.id) {
            const targetNode = graph.nodes.find(node => node.id == rel.target);
            nodeAsSourceEdges.push(({
              type: rel.label,
              id: rel.target,
              target: targetNode.type,
              name: targetNode.label,
              targetData: nodeMap[rel.target].data,
            }));
          }
          if (rel.target === node.id) {
            const sourceNode = graph.nodes.find(node => node.id == rel.source);
            nodeAsTargetEdges.push({
              type: rel.label,
              id: rel.source,
              source: sourceNode.type,
              name: sourceNode.label,
              sourceData: nodeMap[rel.source].data,
            });
          }
        }
        // for (const edge of nodeAsTargetEdges) {
        //   if (!rels[edge.source]) {
        //     rels[edge.source] = [];
        //   }
        //   rels[edge.source].push(edge.name);
        // }

        const rels = {};

        if (asAdjacencyMatrix) {
          for (const edge of nodeAsSourceEdges) {
            const key = edge.target + ' - ' + edge.name;
            relationships[key] = 1;
          }
        } else {
          for (const edge of nodeAsSourceEdges) {
            if (!['Chunk', 'Document'].includes(edge.target)) {
              if (!rels[edge.target]) {
                rels[edge.target] = [];
              }
              rels[edge.target].push(edge);
            }
          }
          relationships = Object.entries(rels).reduce((a, [k, v]) => {
            // a[pluralize(k.toLowerCase())] = v.map(x => `"${x}"`).join(', ');
            a[k] = v.map(x => `"${x.name}"`).join(', ');
            return a;
          }, {});
        }

        return {
          id: node.id,
          type: node.type,
          label: node.label,
          __rels: rels,
          // ...relationships,
          ...properties,
        };
      });
  }

  return [];
};

export const getEdges = (graph) => {
  if (graph.edges?.length) {
    const nodesMap = graph.nodes.reduce((a, n) => {
      a[n.id] = n;
      return a;
    }, {});
    return graph.edges
      .map(edge => {
        const source = nodesMap[edge.source];
        const target = nodesMap[edge.target];

        const nodeAsSourceEdges = [];
        for (const rel of graph.edges) {
          if (rel.source === source.id) {
            const targetNode = graph.nodes.find(node => node.id == rel.target);
            nodeAsSourceEdges.push(({
              type: rel.label,
              id: rel.target,
              target: targetNode.type,
              name: targetNode.label,
            }));
          }
        }
        const rels = {};
        for (const edge of nodeAsSourceEdges) {
          if (!rels[edge.target]) {
            rels[edge.target] = [];
          }
          rels[edge.target].push(edge.name);
        }
        const relationships = Object.entries(rels).reduce((a, [k, v]) => {
          // a[pluralize(k.toLowerCase())] = v.map(x => `"${x}"`).join(', ');
          a[k] = v.map(x => `"${x}"`).join(', ');
          return a;
        }, {});

        return {
          source_id: source.id,
          source_type: source.type,
          source_label: source.label,
          ...relationships,
          source_text: source.data.text || source.data.description || source.data.summary,
          edge_label: edge.label,
          target_id: target.id,
          target_type: target.type,
          target_label: target.label,
          target_text: target.data.text || target.data.description || target.data.summary,
        };
      });
  }
  return [];
};

export const getEdgeMappingColumns = (graph, tableParams) => {
  const nodesMap = graph.nodes.reduce((a, n) => {
    a[n.id] = n;
    return a;
  }, {});
  const relationships = [
    ...new Set(
      // graph.edges.map(e => pluralize(nodesMap[e.target].type.toLowerCase()))
      graph.edges.map(e => nodesMap[e.target].type)
    )
  ];
  relationships.sort();
  return [
    'source_id',
    'source_type',
    'source_label',
    ...relationships,
    'source_text',
    'edge_label',
    'target_id',
    'target_type',
    'target_label',
    'target_text',
  ].map(col => {
    if (col === 'target_type') {
      return {
        title: col,
        dataIndex: col,
        editable: false,
        filters: relationships.map(value => ({ text: value, value })),
        filteredValue: tableParams?.filters?.target_type,
        onFilter: (value, record) => record.target_type === value,
      };
    }
    return {
      title: col,
      dataIndex: col,
      editable: true,
    };
  });
};

export const getDefaultColumns = (graph, asAdjacencyMatrix) => {
  if (graph.nodes?.[0]?.data) {
    const nodesMap = graph.nodes.reduce((a, n) => {
      a[n.id] = n;
      return a;
    }, {});

    let relationships;
    if (asAdjacencyMatrix) {
      const rels = {};
      for (const rel of graph.edges) {
        const targetNode = graph.nodes.find(node => node.id == rel.target);
        const key = targetNode.type + ' - ' + targetNode.label;
        rels[key] = 0;
      }
      relationships = Object.keys(rels);
      relationships.sort();
    } else {
      relationships = [
        ...new Set(
          // graph.edges.map(e => pluralize(nodesMap[e.target].type.toLowerCase()))
          graph.edges.map(e => nodesMap[e.target].type)
        )
      ];
      relationships.sort();
    }

    return [
      'label',
      'id',
      'type',
      // ...relationships,
      ...Object.keys(graph.nodes[0].data),
    ].map(col => ({
      title: col,
      dataIndex: col,
      editable: !['id', 'type'].includes(col),
    }));
  }

  return [];
};

export const getLegend = (graph) => {
  if (graph.legend) {
    const list = Object.entries(graph.legend);
    list.sort((a, b) => a[0] < b[0] ? -1 : 1);
    return list;
  }
  return [];
};

export const getSelectedTypes = (graph, types) => {
  if (graph.legend) {
    const graphTypes = {};
    if (graph.expandTypes) {
      for (const node of graph.nodes) {
        graphTypes[node.type] = true;
      }
    }
    if (!isEmpty(types)) {
      const mytypes = { ...types, ...graphTypes };
      return Object.entries(graph.legend)
        .reduce((a, [label, _]) => {
          a[label] = mytypes[label];
          return a;
        }, {});
    }
    return Object.entries(graph.legend)
      // .filter(([label, _]) => !['Chunk', 'Document', 'Topic'].includes(label))
      .reduce((a, [label, _]) => {
        a[label] = true;
        return a;
      }, {});
  }
  return {};
};
