import { useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  getNodeLabelsAsync,
  selectNodeLabels,
} from '../graph/graphSlice';
import {
  getSettingsAsync,
  selectLoading as selectSettingsLoading,
  selectSettings,
} from '../settings/settingsSlice';

import {
  createInferenceRunAsync,
  getInferenceRunAsync,
  selectInferenceRuns,
  selectLoaded,
  updateInferenceRunAsync,
} from './inferenceRunsSlice';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const activityOptions = [
  {
    label: 'Create Syllabus',
    value: 'create-syllabus',
  },
  {
    label: 'Extract Best Topic',
    value: 'extract-best-topic',
  },
  {
    label: 'Extract Business Requirements',
    value: 'extract-business-requirements',
  },
  {
    label: 'Extract Compliance Arrangements',
    value: 'extract-compliance-arrangements',
  },
  {
    label: 'Extract Compliance Classifications',
    value: 'extract-compliance-classifications',
  },
  {
    label: 'Extract Compliance Topics',
    value: 'extract-compliance-topics',
  },
  {
    label: 'Extract Industries',
    value: 'extract-industries',
  },
  {
    label: 'Extract Obligations',
    value: 'extract-obligations',
  },
  {
    label: 'Extract Summaries',
    value: 'extract-summaries',
  },
  {
    label: 'Extract Thesis Statement',
    value: 'extract-thesis-statement',
  },
  {
    label: 'Extract Topics',
    value: 'extract-topics',
  },
];

export function InferenceRunForm() {

  const loaded = useSelector(selectLoaded);
  const inferenceRuns = useSelector(selectInferenceRuns);
  const nodeLabels = useSelector(selectNodeLabels);
  const settingsLoading = useSelector(selectSettingsLoading);
  const settings = useSelector(selectSettings);

  const [form] = Form.useForm();

  const correlationIdRef = useRef();
  const taskDoneCallbackRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  const id = location.pathname.match(/\/inference\/(.*)/)[1];
  const isNew = id === 'new';
  const inferenceRun = inferenceRuns[id];

  const nodeLabelOptions = useMemo(() => {
    const list = nodeLabels.map(label => ({
      label,
      value: label,
    }));
    list.sort((a, b) => a.label < b.label ? -1 : 1);
    return list;
  }, [nodeLabels]);

  const settingOptions = useMemo(() => {
    const list = Object.values(settings).map(s => ({
      label: s.key,
      value: s.id,
    }));
    list.sort((a, b) => a.label < b.label ? -1 : 1);
    return list;
  }, [settings]);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Data Augmentation Task',
    }));
    if (!isNew) {
      dispatch(getInferenceRunAsync(id));
    }
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getSettingsAsync({ workspaceId }));
      dispatch(getNodeLabelsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (correlationIdRef.current) {
      const run = Object.values(inferenceRuns).find(a => a.correlationId === correlationIdRef.current);
      if (run) {
        if (taskDoneCallbackRef.current) {
          taskDoneCallbackRef.current();
          taskDoneCallbackRef.current = null;
        }
        correlationIdRef.current = null;
      }
    }
  }, [inferenceRuns]);

  useEffect(() => {
    if (location.state && location.state.message) {
      messageApi.info({
        content: location.state.message,
        duration: 5,
      });
    }
  }, [location]);

  const returnToList = () => {
    navigate('/inference');
  };

  const onFinish = (values) => {
    const correlationId = uuidv4()
    if (isNew) {
      dispatch(createInferenceRunAsync({
        correlationId,
        values: { ...values, workspaceId: selectedWorkspace.id },
      }));
    } else {
      dispatch(updateInferenceRunAsync({ correlationId, id, values }));
    }
    correlationIdRef.current = correlationId;
    taskDoneCallbackRef.current = returnToList;
  };

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <>
      {contextHolder}
      <div style={{ background: '#f5f5f5', padding: '16px 0', height: '100%' }}>
        <Form
          form={form}
          {...layout}
          autoComplete="off"
          onFinish={onFinish}
          initialValues={inferenceRun}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter a name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
          </Form.Item>
          <Form.Item
            label="Activity"
            name="activity"
            rules={[
              {
                required: true,
                message: "Please select the activity",
              },
            ]}
          >
            <Select allowClear
              options={activityOptions}
              optionFilterProp="label"
              placeholder="Select activity"
            />
          </Form.Item>
          <Form.Item
            label="Node"
            name="nodeLabel"
          >
            <Select allowClear
              options={nodeLabelOptions}
              optionFilterProp="label"
              placeholder="Select node"
            />
          </Form.Item>
          <Form.Item
            label="Text Field"
            name="textField"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Setting"
            name="setting"
          >
            <Select allowClear
              loading={settingsLoading}
              options={settingOptions}
              optionFilterProp="label"
              placeholder="Select setting"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Space>
              <Button type="default" onClick={returnToList}>Cancel</Button>
              <Button type="primary" htmlType="submit">Save</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
