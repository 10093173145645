import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import {
  AutoComplete,
  Button,
  Input,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import {
  ArrowRightOutlined,
  BarsOutlined,
  CloseOutlined,
  PlusOutlined,
  SubnodeOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import isEmpty from 'lodash.isempty';

const tagInputStyle = {
  width: 200,
  verticalAlign: 'top',
};

const tagPlusStyle = {
  background: 'inherit',
  borderStyle: 'dashed',
  cursor: 'pointer',
};

const ops = {
  STRING: ['equals', 'contains'],
  INT64: ['equals', 'less than', 'greater than', 'less than or equal to', 'greater than or equal to'],
  BOOL: ['is true', 'is false'],
};

function InputBox({
  handleAutocompleteChange,
  handleInputConfirm,
  inputRef,
  isInput,
  onSearch,
  onSelect,
  options,
  value,
}) {
  if (isInput) {
    return (
      <AutoComplete
        onBlur={() => handleInputConfirm()}
        onChange={handleAutocompleteChange}
        onSearch={onSearch}
        onSelect={onSelect}
        options={options}
        value={value}
      >
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          onPressEnter={() => handleInputConfirm()}
        />
      </AutoComplete>
    )
  } else {
    return (
      <Select allowClear
        autofocus
        defaultOpen={true}
        onSelect={handleInputConfirm}
        options={options}
        size="small"
        style={{ width: 200 }}
        value={value}
      />
    );
  }
}

export function CypherQueryBuilder({
  nodesMetadata,
  relationshipsMetadata,
  onQuery,
  state,
}) {

  const [es, setQueryElements] = useState([]);
  const [currentState, setCurrentState] = useState(state?.currentState || []);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [tags, setTags] = useState(state?.tags || []);

  // console.log('currentState:', currentState);
  // console.log('options:', options);
  // console.log('tags:', tags);

  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const nodeOptions = useMemo(() => {
    return Object.keys(nodesMetadata).map(label => ({
      label,
      value: `node::${label}`,
    }))
  }, [nodesMetadata]);

  const getLabel = (tag) => {
    return tag.split(':')[2];
  };

  const getRelationshipOptions = useCallback((node) => {
    const label = getLabel(node);
    return Object.keys(nodesMetadata[label]?.relationships || {}).map(label => {
      const target = relationshipsMetadata[label];
      return {
        label: `${label} → ${target}`,
        value: `rel::${label}:${target}`,
      };
    });
  }, [nodesMetadata]);

  const getNodePropertyOptions = useCallback((node) => {
    const label = getLabel(node);
    return (nodesMetadata[label]?.properties || []).map(p => ({
      label: p.name,
      value: `prop:${p.type}:${p.name}`,
    }));
  }, [nodesMetadata]);

  const getNodeOptions = useCallback((rel) => {
    const label = getLabel(rel);
    return (relationshipsMetadata[label] || []).slice(1).map(label => ({
      label,
      value: `node::${label}`,
    }));
  }, [relationshipsMetadata]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    if (!options.length) {
      if (currentState.length) {
        setOptions(getOptions(currentState));
      } else {
        setOptions(getFilteredOptions(nodeOptions));
      }
    }
  }, [nodeOptions]);

  const handleAutocompleteChange = (value) => {
    setInputValue(value);
  };

  const combineState = (states) => {
    return states.reduce((a, s) => {
      a = { ...a, ...s };
      return a;
    }, {});
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter(t => t !== removedTag);
    setTags(newTags);
    const newState = currentState.slice(0, -1);
    setCurrentState(newState);
    if (newState.length) {
      setOptions(getOptions(newState));
    } else {
      setOptions(getFilteredOptions(nodeOptions));
    }
  };

  const handleEditInputChange = (ev) => {
    setEditInputValue(ev.target.value);
  };

  const handleEditInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;
      setTags(newTags);
    }
    setEditInputIndex(-1);
    setInputValue('');
  };

  const handleInputConfirm = (val) => {
    let value = inputValue || val;
    if (value) {
      if (value.indexOf(':') === -1) {
        value = `value::${value}`;
      }
      const newTags = [...tags, value];
      setTags(newTags);
      updateState(value);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const updateState = (data) => {
    const [type, dataType, label] = data.split(':');
    let state;
    if (type === 'rel') {
      const target = relationshipsMetadata[label];
      state = { node: `node::${target}` };
    } else {
      state = { [type]: data };
    }
    const newState = [...currentState, state];
    setCurrentState(newState);
    setOptions(getOptions(newState));
  };

  const onSelect = (data) => {
  };

  const getFilteredOptions = (options, searchText) => {
    return options
      .filter(o => !searchText || o.label.toLowerCase().startsWith(searchText.toLowerCase()))
      .filter(o => !tags.includes(o.label));
  };

  const getOptions = (states, text) => {
    const state = states[states.length - 1];
    if (state.node) {
      const options = [];
      const relOptions = getFilteredOptions(getRelationshipOptions(state.node), text);
      const propOptions = getFilteredOptions(getNodePropertyOptions(state.node), text);
      if (!isEmpty(relOptions)) {
        options.push({
          label: 'Relationships',
          options: relOptions,
        });
      }
      if (!isEmpty(propOptions)) {
        options.push({
          label: 'Properties',
          options: propOptions,
        });
      }
      return options;
    }
    if (state.rel) {
      const options = [];
      const nodeOptions = getNodeOptions(state.rel);
      if (!isEmpty(nodeOptions)) {
        options.push({
          label: 'Nodes',
          options: nodeOptions,
        })
      }
      return options;
    }
    if (state.prop) {
      const [type, dataType, label] = state.prop.split(':');
      return ops[dataType].map(label => ({
        label,
        value: `op::${label}`,
      }));
    }
    if (state.op) {
      return [];
    }
    if (state.value) {
      return [
        {
          label: 'AND',
          value: 'conjunction::AND',
        },
        {
          label: 'NEW MATCH',
          value: 'conjunction::NEW',
        },
      ];
    }
    if (state.conjunction) {
      const [type, dataType, label] = state.conjunction.split(':');
      const combinedState = combineState(states);
      const propOptions = getFilteredOptions(getNodePropertyOptions(combinedState.node), text);
      if (label === 'AND') {
        const options = [];
        if (!isEmpty(propOptions)) {
          options.push({
            label: 'Properties',
            options: propOptions,
          });
        }
        return options;
      } else {  // label === 'NEW'
        setQueryElements(cur => [...cur, currentState]);
        setCurrentState([]);
        return nodeOptions;
      }
    }
    return getFilteredOptions(nodeOptions, text);
  };

  const search = (text) => {
    return getOptions(currentState, text);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const getTagColor = (type) => {
    if (type === 'node') return 'blue';
    if (type === 'prop') return 'cyan';
    if (type === 'rel') return 'green';
    if (type === 'op') return 'volcano';
    return null;
  };

  const getTagIcon = (type) => {
    if (type === 'node') {
      return <SubnodeOutlined />
    }
    if (type === 'rel') {
      return <ArrowRightOutlined />
    }
    if (type === 'prop') {
      return <BarsOutlined />
    }
    return null;
  };

  const getScalar = (dataType, value) => {
    switch (dataType) {
      case 'STRING':
        return `'${value}'`;

      default:
        return value;
    }
  };

  const getOp = (prop, opName, dataType, value) => {
    switch (opName) {
      case 'equals':
        return `${prop} = ${getScalar(dataType, value)}`;

      case 'contains':
        return `LOWER(${prop}) CONTAINS '${value.toLowerCase()}'`;

      case 'less than':
        return `${prop} < ${getScalar(dataType, value)}`;

      case 'greater than':
        return `${prop} > ${getScalar(dataType, value)}`;

      case 'less than or equal to':
        return `${prop} <= ${getScalar(dataType, value)}`;

      case 'greater than or equal to':
        return `${prop} >= ${getScalar(dataType, value)}`;

      case 'is true':
        return `${prop} IS TRUE`;

      case 'is false':
        return `${prop} IS FALSE`;

      default:

    }
  }

  const handleQuery = () => {
    const queries = [];
    if (tags.length) {
      const es = [];
      let e = { node: null, rel: null, props: [] };
      let curProp;
      let curDataType;
      let curOp;
      for (const tag of tags) {
        const [type, dataType, label, connected] = tag.split(':');
        if (type === 'node') {
          if (label === 'any') {
            e.node = '(n)';
          } else {
            e.node = `(n:${label})`;
          }
        } else if (type === 'rel') {
          if (label === 'any') {
            e.rel = '[r]-(c)';
          } else {
            e.rel = `[r:${label}]-(c:${connected})`;
          }
        } else if (type === 'prop') {
          if (e.rel) {
            curProp = `c.${label}`;
          } else {
            curProp = `n.${label}`;
          }
          curDataType = dataType;
        } else if (type === 'op') {
          curOp = label;
        } else if (type === 'value') {
          e.props.push(getOp(curProp, curOp, curDataType, label));
        } else if (type === 'conjunction') {
          if (label === 'NEW') {
            es.push(e);
            e = { node: null, rel: null, props: [] };
          }
        }
      }
      if (e.node) {
        es.push(e);
      }
      for (const e of es) {
        let q = `MATCH ${e.node}`;
        if (e.rel) {
          q += `-${e.rel}`;
        } else {
          q += '-[r]-(c)'
        }
        if (e.props.length) {
          q += ' WHERE ';
          q += e.props.join(' AND ');
        }
        q += ' RETURN n, labels(n) AS n_type, r, c, labels(c) AS c_type';
        queries.push(q);
      }
    } else {
      queries.push('MATCH (n) OPTIONAL MATCH (n)-[r]-(c) RETURN n, labels(n) AS n_type, r, c, labels(c) AS c_type');
    }
    if (typeof onQuery === 'function') {
      onQuery({ state: { currentState, tags }, queries });
    }
  };

  const handleReset = () => {
    if (typeof onQuery === 'function') {
      const queries = [];
      queries.push('MATCH (n) OPTIONAL MATCH (n)-[r]-(c) RETURN n, labels(n) AS n_type, r, c, labels(c) AS c_type');
      onQuery({ state: { currentState: [], tags: [] }, queries });
    }
  };

  const isInput = useMemo(() => {
    const state = currentState[currentState.length - 1];
    if (state) {
      const op = state.op;
      if (op) {
        const name = op.split(':')[2];
        if ([...ops.STRING, ...ops.INT64].includes(name)) {
          return true;
        }
      }
    }
    return false;
  }, [currentState]);

  return (
    <div id="tags-input"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        width: 900,
      }}
    >
      <Space size={[0, 8]} style={{ minHeight: 32 }} wrap>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={tag}
                size="small"
                style={tagInputStyle}
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }
          const [type, dataType, label, target] = tag.split(':');
          let text = label;
          if (target) {
            text += ': ' + target;
          }
          const isLongTag = text.length > 40;
          const tagElem = (
            <Tag
              key={tag}
              closable={tags[tags.length - 1] === tag}
              style={{
                userSelect: 'none',
              }}
              icon={getTagIcon(type)}
              color={getTagColor(type)}
              onClose={() => handleClose(tag)}
              className={label === 'NEW' ? 'break' : ''}
            >
              <span
              // onDoubleClick={(e) => {
              //   setEditInputIndex(index);
              //   setEditInputValue(tag);
              //   e.preventDefault();
              // }}
              >
                {isLongTag ? `${text.slice(0, 20)}...` : text}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible ? (
          <InputBox
            handleAutocompleteChange={handleAutocompleteChange}
            handleInputConfirm={handleInputConfirm}
            inputRef={inputRef}
            isInput={isInput}
            onSearch={(text) => setOptions(search(text))}
            onSelect={onSelect}
            options={options}
            setOptions={setOptions}
            value={inputValue}
          />
        ) : (
          <Tag style={tagPlusStyle} onClick={() => showInput()}>
            <PlusOutlined /> Element
          </Tag>
        )}
        {tags.length ?
          <Button type="text" size="small" icon={<CloseOutlined />}
            onClick={handleReset}
          />
          : null
        }
      </Space>
      <Space size={[0, 8]} wrap>
        <Button
          size="small"
          type="primary"
          onClick={handleQuery}
        >
          Query
        </Button>
      </Space>
    </div>
  );
}