import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, Table, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  deleteMetricSetsAsync,
  getMetricSetsAsync,
  materializeMetricSetAsync,
  selectLoading,
  selectMetricSets,
} from './metricSetsSlice';

const API_HOST = process.env.REACT_APP_API_HOST;

export function MetricSetsList() {

  const [processing, setProcessing] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loading = useSelector(selectLoading);
  const metricSets = useSelector(selectMetricSets);

  const data = useMemo(() => {
    const list = Object.values(metricSets).map(s => ({
      key: s.id,
      name: s.name,
      createdBy: s.createdBy,
      modified: s.modified,
    }));
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [metricSets]);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const clientIdRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const onMessage = (ev) => {
    const recv = JSON.parse(ev.data);
    console.log('recv:', recv);
    if (recv.status === 'done') {
      const key = recv.data.key;
      const p = { ...processing };
      delete p[key];
      setProcessing(p);
    }
  };

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: '/metric-sets/new',
      title: 'Metric Sets',
    }));
    const clientId = uuidv4();
    const protocol = process.env.REACT_APP_SECURE_WEBSOCKETS === 'true' ? 'wss' : 'ws';
    const ws = new WebSocket(`${protocol}://${API_HOST}/api/websockets/${clientId}`);
    ws.onmessage = onMessage;
    clientIdRef.current = clientId;

    return () => {
      console.log("Closing connection");
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getMetricSetsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  const handleMaterialize = (key) => {
    dispatch(materializeMetricSetAsync({
      key,
      workspaceId: selectedWorkspace.id,
      clientId: clientIdRef.current,
    }));
    const p = { ...processing };
    p[key] = true;
    setProcessing(p);
  };

  const onDelete = () => {
    dispatch(deleteMetricSetsAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, { key, name }) => <Link to={`/metric-sets/${key}`}>{name}</Link>
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(`/metric-sets/${record.key}`)}
          >
            Edit
          </Button>
          <Button type="link"
            loading={processing[record.key]}
            onClick={() => handleMaterialize(record.key)}
            style={{ paddingLeft: 0 }}
          >
            Materialize
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <div style={{ marginBottom: 16 }}>
          <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ hideOnSinglePage: true }}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};
