import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, Table, message } from 'antd';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  deleteArtefactsAsync,
  getArtefactsAsync,
  selectLoading,
  selectArtefacts,
} from './artefactsSlice';

export function ArtefactsList() {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loading = useSelector(selectLoading);
  const artefacts = useSelector(selectArtefacts);

  const data = useMemo(() => {
    const list = Object.values(artefacts).map(a => ({
      key: a.id,
      name: a.name,
      created: a.created,
      createdBy: a.createdBy,
      modified: a.modified,
    }));
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [artefacts]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: '/artefacts/new',
      title: 'Artefacts',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getArtefactsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  const onDelete = () => {
    dispatch(deleteArtefactsAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, { key, name }) => <Link to={`/artefacts/${key}`}>{name}</Link>
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(`/artefacts/${record.key}`)}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <div style={{ marginBottom: 16 }}>
          <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ hideOnSinglePage: true }}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};
