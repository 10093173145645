import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Image,
  Mentions,
  Radio,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { jsonToPlainText } from '../jsonToPlainText';
import tablemark from 'tablemark';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import YouTube from 'react-youtube';

const opts = {
  height: '112.5',
  width: '200',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    rel: 0,
  },
};

const jsonToTextOpts = {
  color: true,  // Whether to apply colors to the output or not
  spacing: true,  // Whether to include spacing after colons or not
  seperator: ':',  // seperator. Default ':',
  squareBracketsForArray: false,  // Whether to use square brackets for arrays or not
  doubleQuotesForKeys: false,  // Whether to use double quotes for object keys or not
  doubleQuotesForValues: false,  // Whether to use double quotes for string values or not
  displayArrayAsList: true,
};

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

export function Chat({
  disabled,
  enableActions,
  enableCritique,
  loading,
  messages,
  onCritique,
  onDelete,
  onSave,
  onSelected,
  onSubmit,
  onReset,
  onShowCitation,
  placeholder,
  selectable,
  selectMultiple,
  suggestPrompts,
  tourRefs,
  traceId,
  isWidget,
  width,
}) {

  if (!placeholder) {
    placeholder = 'Query the knowledge base using plain language...';
  }

  const [activeKey, setActiveKey] = useState([]);
  const [elaboratedKeys, setElaboratedKeys] = useState({});
  const [indeterminate, setIndeterminate] = useState(false);
  const [selected, setSelected] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [input, setInput] = useState(null);
  const [player, setPlayer] = useState(null);
  const [videoParams, setVideoParams] = useState({});

  const latestResponseRef = useRef(null);
  const messageScrollerRef = useRef(null);
  const scrollTopRef = useRef(null);

  let previewVisible = false;

  const selectedEntries = Object.entries(selected).filter(([_, v]) => v.checked).map(([k, v]) => [k, v.index]);
  selectedEntries.sort((a, b) => a[1] < b[1] ? -1 : 1);
  const selectedKeys = selectedEntries.map(x => x[0]);
  const hasSelected = selectedKeys.length > 0;

  const hasMessages = messages.length > 0;

  const navigate = useNavigate();

  useLayoutEffect(() => {
    const el = messageScrollerRef.current;
    if (scrollTopRef.current === null) {
      el.scrollTop = el.scrollHeight;
    } else {
      el.scrollTop = scrollTopRef.current;
    }
  });

  const findMessage = (key) => {
    for (const m of messages) {
      if (m.role === 'assistant' && Array.isArray(m.content)) {
        const content = m.content.find(c => c.key === key);
        if (content) {
          return { message: m, content };
        }
      } else if (m.key === key) {
        return { message: m };
      }
    }
    return {};
  };

  const handleChange = (key) => {
    if (previewVisible) return;
    scrollTopRef.current = messageScrollerRef.current.scrollTop;
    if (selectMultiple) {
      const { message, content } = findMessage(key);
      let selectedUpdate;
      if (content) {
        selectedUpdate = {
          ...selected,
          ...message.content.reduce((a, c) => {
            a[c.key] = {
              checked: false,
              index: message.index,
            };
            return a;
          }, {}),
          [key]: {
            checked: !selected[key]?.checked,
            index: message.index,
          },
        };
      } else {
        selectedUpdate = {
          ...selected,
          [key]: {
            checked: !selected[key]?.checked,
            index: message.index,
          },
        };
      }
      const selectedEntries = Object.entries(selectedUpdate).filter(([_, v]) => v.checked).map(([k, v]) => [k, v.index]);
      selectedEntries.sort((a, b) => a[1] < b[1] ? -1 : 1);
      const selectedKeys = selectedEntries.map(x => x[0]);
      if (selectedKeys.length > 0 && selectedKeys.length < messages.length) {
        setIndeterminate(true);
      } else {
        setIndeterminate(false);
      }
      if (selectedKeys.length === messages.length) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
      setSelected(selectedUpdate);
      if (typeof onSelected === 'function') {
        onSelected(selectedKeys);
      }
    } else {
      setSelected({ [key]: true });
      if (typeof onSelected === 'function') {
        onSelected([key]);
      }
    }
    setTimeout(() => {
      scrollTopRef.current = null;
    }, 2000);
  };

  const handleReset = () => {
    setSelected({});
    if (typeof onReset === 'function') {
      onReset();
    }
  };

  const handleSave = () => {
    setSelected({});
    if (typeof onSave === 'function') {
      onSave(messages);
    }
  };

  const handleSubmit = () => {
    const msg = {
      key: uuidv4(),
      role: 'user',
      content: input,
    };
    // setInput(null);
    onSubmit({ messages: [...messages, msg] });
  };

  const handleSuggestedQuery = (query) => {
    setInput(query);
    setTimeout(() => {
      const msg = {
        key: uuidv4(),
        role: 'user',
        content: query,
      };
      // setInput(null);
      onSubmit({ messages: [...messages, msg] });
    }, 1000);
  };

  const deleteSelectedMessages = () => {
    onDelete(selectedKeys);
  };

  const regenerate = () => {
    const userMessage = [...messages].reverse().find(m => m.role === 'user');
    const content = userMessage.content;
    const msg = {
      key: uuidv4(),
      role: 'user',
      content,
    };
    onSubmit({ messages: [...messages, msg] });
  };

  const critique = () => {
    const input = messages.slice(-2)[0].content;
    const completion = messages.slice(-1)[0].content[0].content;
    onCritique({ input, completion });
  };

  const handleSuggestPrompts = () => {
    suggestPrompts({ messages });
  };

  const onCheckAllChange = (ev) => {
    scrollTopRef.current = 0;
    if (ev.target.checked) {
      setCheckAll(true);
      const selectedUpdate = messages.reduce((a, m) => {
        if (Array.isArray(m.content)) {
          a[m.content[0].key] = {
            checked: true,
            index: m.index,
          };
        } else {
          a[m.key] = {
            checked: true,
            index: m.index,
          };
        }
        return a;
      }, {});
      setSelected(selectedUpdate);
      const selectedEntries = Object.entries(selectedUpdate).filter(([_, v]) => v.checked).map(([k, v]) => [k, v.index]);
      selectedEntries.sort((a, b) => a[1] < b[1] ? -1 : 1);
      const selectedKeys = selectedEntries.map(x => x[0]);
      if (typeof onSelected === 'function') {
        onSelected(selectedKeys);
      }
    } else {
      setCheckAll(false);
      setSelected({});
    }
    setIndeterminate(false);
    setTimeout(() => {
      scrollTopRef.current = null;
    }, 1000);
  };

  const createMessages = (selectedKeys) => {
    return selectedKeys.map(key => {
      const { message, content } = findMessage(key);
      if (content) {
        return {
          role: message.role,
          content: content.content,
          key: content.key,
        };
      }
      return message;
    });
  };

  useEffect(() => {
    if (messages.length) {
      const m = messages[messages.length - 1];
      if (m.role === 'assistant') {
        const content = m.content[0];
        if (content.search_hits?.length) {
          for (const { citation } of content.search_hits) {
            if (citation?.start) {
              const url = new URL(citation.url);
              const videoId = url.searchParams.get('v');
              let x, y;
              if (latestResponseRef.current) {
                const offset = getOffset(latestResponseRef.current);
                const rect = latestResponseRef.current.getBoundingClientRect();
                x = offset.left + rect.width + 124;
                y = offset.top;
              }
              setVideoParams({
                videoId,
                start: citation.start,
                x,
                y,
                quote: content.quote,
              });
            }
          }
        }
      }
    }
    setInput(null);
  }, [messages]);

  const onCancelVideoPreview = () => {
    player.pauseVideo();
    setVideoParams({});
  };

  const onReady = (ev) => {
    setPlayer(ev.target);
  };

  const CitationSource = ({ key, source, index }) => {
    const { dataSourceId, dataSourceName, page, row, uri } = source;
    let displayURI;
    if (uri.startsWith('http') || uri.startsWith('/')) {
      const resourceName = uri.split('/').slice(-1)[0];
      if (typeof onShowCitation === 'function') {
        displayURI = (
          <Link onClick={() => onShowCitation(uri.slice(1))}>
            {resourceName}
          </Link>
        );
      } else {
        displayURI = (
          <Link to={uri} target="_blank" rel="noopener noreferrer">
            {resourceName}
          </Link>
        );
      }
    } else {
      displayURI = (
        <div>{uri}</div>
      );
    }
    return (
      <div key={key + '-source-' + index}
        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 5 }}
      >
        {displayURI}
        {page || row ?
          <div>({page ? 'page' : 'chunk'} {page || row})</div>
          : null
        }
        {dataSourceName ?
          <Tag
            onClick={() => navigate(`/data-sources/${dataSourceId}`)}
            style={{ cursor: 'pointer' }}
          >
            {dataSourceName}
          </Tag>
          : null
        }
      </div>
    );
  };

  const SearchHit = ({ hit, index }) => {
    const { citation, information } = hit;
    const { last_modified, location, object_name, thumbnail, video_id } = citation;
    let source;
    if (object_name) {
      source = object_name.split('/').slice(-1)[0];
    } else {
      source = video_id;
    }
    const desc = (
      <div>
        <div>{information}</div>
        <div style={{ fontStyle: 'italic', marginTop: 5 }}>
          Source: {source}
          {location?.value && location.value !== 'None'
            && <span> [{location.type}: {location.value}]</span>}
          {last_modified && ', ' + new Date(last_modified).toLocaleString()}
        </div>
      </div>
    );

    return (
      <>
        <div>{index + 1}.</div>
        {thumbnail ?
          <div style={{ display: 'flex', gap: 16 }}>
            <Image src={thumbnail} preview={false} />
            {desc}
          </div>
          :
          desc
        }
      </>
    );
  };

  const AssistantMessage = ({ first, isLatestResponse, message, onChange }) => {
    let assistantChatlineStyle = {};
    if (isWidget) {
      assistantChatlineStyle = { marginRight: 40 };
    }
    if (!selectable || (first && !selectMultiple)) {
      return (
        <div
          className="chatline assistant"
          key={message.key}
          ref={isLatestResponse ? latestResponseRef : null}
          style={assistantChatlineStyle}
        >
          <div className="ant-radio"></div>
          <div className="avatar"><Avatar>A</Avatar></div>
          <div className="content" style={{ maxWidth: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24, padding: '0 8px' }}>
              {message.content.map((c, i) => (
                <div key={c.key} className={i > 0 ? 'chat-sep' : ''}>
                  {/^\[.*/.test(c.content) ?
                    <Markdown className="markdown" remarkPlugins={[remarkGfm]}>
                      {tablemark(JSON.parse(c.content))}
                    </Markdown>
                    : (
                      /^{.*/.test(c.content) ?
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                          {jsonToPlainText(JSON.parse(c.content), jsonToTextOpts)}
                        </div>
                        :
                        <Typography.Text copyable
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          {c.content}
                        </Typography.Text>
                    )
                  }
                  {c.elaboration ?
                    <Button
                      onClick={() => setElaboratedKeys(cur => ({ ...cur, [c.key]: !cur[c.key] }))}
                      size="small"
                      type="link"
                    >
                      {elaboratedKeys[c.key] ? 'Hide' : 'Elaborate...'}
                    </Button>
                    : null
                  }
                  {elaboratedKeys[c.key] ?
                    <div className="chat-card" style={{ marginTop: 16, width: '100%' }}>
                      <Typography.Text
                        copyable
                      // copyable={{ icon: ['Copy', 'Copied'], tooltips: false }}
                      >
                        <Markdown>{c.elaboration}</Markdown>
                      </Typography.Text>
                    </div>
                    : null
                  }
                  {c.suggested_next_queries?.length ?
                    <Space wrap style={{ margin: '16px 0' }}>
                      {c.suggested_next_queries.map((q, i) => (
                        <Button key={'q-' + i}
                          onClick={() => handleSuggestedQuery(q.query)}
                        >
                          {q.short_label}
                        </Button>
                      ))}
                    </Space>
                    : null
                  }
                  {(c.citation_metadata || c.search_hits || c.cypher) && !isWidget ?
                    <Collapse ghost
                      activeKey={activeKey}
                      onChange={setActiveKey}
                      items={[
                        {
                          key: '1',
                          label: (activeKey[0] === '1' ? 'Hide' : 'Show') + ' lineage',
                          children: (
                            <>
                              {c.citation_metadata?.citation_sources?.length ?
                                <div style={{ display: 'flex', alignItems: 'flex-start', fontSize: '14px', gap: 5, lineHeight: '22px' }}>
                                  <div>Citations:</div>
                                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 16 }}>
                                    {c.citation_metadata.citation_sources.map((s, i) => (
                                      <CitationSource key={c.key} source={s} index={i} />
                                    ))}
                                  </div>
                                </div>
                                : null
                              }
                              {c.search_hits?.length ?
                                <div style={{ marginTop: 10 }}>
                                  <div>Search Hits:</div>
                                  <div style={{ display: 'grid', gridRowGap: 10, gridTemplateColumns: '20px auto', marginTop: 5 }}>
                                    {c.search_hits.map((h, i) => (
                                      <SearchHit hit={h} index={i} />
                                    ))}
                                  </div>
                                </div>
                                : null
                              }
                              {c.cypher ?
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 24 }}>
                                  <div>Cypher:</div>
                                  <Typography.Text code>{c.cypher}</Typography.Text>
                                  <div>
                                    <div>Result:</div>
                                    {c.cypher_result ? (
                                      /^\[.*/.test(c.cypher_result) ? (
                                        <Markdown className="markdown" remarkPlugins={[remarkGfm]}>
                                          {tablemark(JSON.parse(c.cypher_result))}
                                        </Markdown>
                                      ) : (
                                        /^{.*/.test(c.cypher_result) ?
                                          <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {jsonToPlainText(JSON.parse(c.cypher_result), jsonToTextOpts)}
                                          </div>
                                          :
                                          <Typography.Paragraph copyable
                                            style={{ whiteSpace: 'pre-wrap' }}
                                          >
                                            {c.cypher_result}
                                          </Typography.Paragraph>
                                      )
                                    ) : (
                                      <div>None</div>
                                    )}
                                  </div>
                                </div>
                                : null
                              }
                            </>
                          )
                        }
                      ]}
                    />
                    : null
                  }
                  {c.model && !isWidget ?
                    <div className="text-secondary"
                      style={{ marginTop: 4 }}
                    >
                      {c.model}
                    </div>
                    : null
                  }
                </div>
              ))}
            </div>
          </div>
        </div >
      );
    }
    if (selectMultiple) {
      // return (
      //   <Checkbox value={message.key} onChange={onChange} checked={selected[message.key]?.checked}>
      //     <div className="chatline assistant">
      //       <div className="avatar"><Avatar>A</Avatar></div>
      //       <div className="content">
      //         <Space size="large">
      //           {message.content.map((c, i) => (
      //             <div key={c.key} className={i > 0 ? 'chat-sep' : ''}>
      //               <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>{c.content}</Typography.Text>
      //               <div className="text-secondary" style={{ marginTop: 8 }}>{c.model}</div>
      //             </div>
      //           ))}
      //         </Space>
      //       </div>
      //     </div>
      //   </Checkbox>
      // );
      return (
        <div key={message.key} className="chatline assistant" style={assistantChatlineStyle}>
          <div className="avatar"><Avatar>A</Avatar></div>
          <div className="content">
            <Space size="large">
              {message.content.map((c, i) => (
                <div key={c.key} className={i > 0 ? 'chat-sep' : ''}>
                  <Checkbox
                    checked={selected[c.key]?.checked}
                    onChange={onChange}
                    value={c.key}
                  >
                    <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>{c.content}</Typography.Text>
                    <div className="text-secondary" style={{ marginTop: 4 }}>{c.model}</div>
                  </Checkbox>
                </div>
              ))}
            </Space>
          </div>
        </div>
      );
    }
    return (
      <Radio key={message.key} value={message.key}>
        <div className="chatline assistant" style={assistantChatlineStyle}>
          <div className="avatar"><Avatar>A</Avatar></div>
          <div className="content">
            <Space size="large">
              {message.content.map((c, i) => (
                <div key={c.key} className={i > 0 ? 'chat-sep' : ''}>
                  {/^\[.*/.test(c.content) ?
                    <Markdown className="markdown" remarkPlugins={[remarkGfm]}>
                      {tablemark(JSON.parse(c.content))}
                    </Markdown>
                    : (
                      /^{.*/.test(c.content) ?
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                          {jsonToPlainText(JSON.parse(c.content), jsonToTextOpts)}
                        </div>
                        :
                        <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>
                          {c.content}
                        </Typography.Text>
                    )
                  }
                  <div className="text-secondary" style={{ marginTop: 4 }}>{c.model}</div>
                </div>
              ))}
            </Space>
          </div>
        </div>
      </Radio>
    );
  };

  const UserContent = ({ message }) => {
    const { key, content } = message;
    if (Array.isArray(content)) {
      return (
        <div style={{
          alignContent: 'start',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: 16,
          textAlign: 'left'
        }}>
          {content.map((c, i) => {
            if (c.type === 'text') {
              return (
                <div key={key + '-' + i}>
                  <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>
                    {c.text}
                  </Typography.Text>
                </div>
              );
            } else if (c.type === 'image_url') {
              return (
                <div key={key + '-' + i}>
                  <Image src={c.image_url.url} width={200}
                    preview={{
                      onVisibleChange: (visible) => {
                        previewVisible = visible;
                      },
                    }}
                  />
                </div>
              );
            }
          })}
        </div>
      )
    }
    return (
      <Typography.Text key={key} copyable style={{ whiteSpace: 'pre-wrap' }}>
        {content}
      </Typography.Text>
    );
  }

  const UserMessage = ({ message, onChange }) => {
    let userChatlineStyle = {};
    if (isWidget) {
      userChatlineStyle = { marginLeft: 64 };
    }
    if (selectMultiple) {
      return (
        <Checkbox
          checked={selected[message.key]?.checked}
          onChange={onChange}
          value={message.key}
          style={{ paddingLeft: 40 }}
        >
          <div className="chatline user" style={userChatlineStyle}>
            <div className="content">
              <UserContent message={message} />
            </div>
            <div className="avatar"><Avatar>U</Avatar></div>
          </div>
        </Checkbox>
      );
    }
    return (
      <div className="chatline user" style={userChatlineStyle}>
        <div className="content">
          <UserContent message={message} />
        </div>
        <div className="avatar"><Avatar>U</Avatar></div>
      </div>
    );
  };

  const Message = ({ first, isLatestResponse, message, onChange }) => {
    if (message.role === 'assistant') {
      return (
        <AssistantMessage
          first={first}
          isLatestResponse={isLatestResponse}
          message={message}
          onChange={onChange}
        />
      );
    }
    if (message.role === 'user') {
      return (
        <UserMessage message={message} onChange={onChange} />
      );
    }
    return null;
  };

  const Messages = ({ messages, onChange, value }) => {
    const latestResponseIndex = messages.findLastIndex(m => m.role === 'assistant');
    if (selectable) {
      if (selectMultiple) {
        return (
          <div style={{ marginBottom: 24 }}>
            {messages.map((m, i) => (
              <Message
                key={m.key}
                first={i === 0}
                isLatestResponse={i === latestResponseIndex}
                message={m}
                onChange={onChange}
              />
            ))}
          </div>
        );
      }
      return (
        <Radio.Group onChange={onChange} value={value}>
          {messages.map((m, i) => (
            <Message
              key={m.key}
              first={i === 0}
              isLatestResponse={i === latestResponseIndex}
              message={m}
            />
          ))}
        </Radio.Group>
      );
    }
    return (
      <div style={{ marginBottom: 24 }}>
        {messages.map((m, i) => (
          <Message
            key={m.key}
            first={i === 0}
            isLatestResponse={i === latestResponseIndex}
            message={m}
          />
        ))}
      </div>
    )
  };

  const Loading = ({ loading }) => {
    if (!loading) {
      return null;
    }
    return (
      <div style={{ margin: '20px 0', textAlign: 'center' }}>
        <Spin />
      </div>
    );
  };

  const MessagesSection = ({ disabled, loading, messages, onChange, value }) => {
    if (disabled) {
      return (
        <div></div>
      );
    }
    return (
      <div ref={messageScrollerRef} className="message-scroller">
        <div style={{ height: isWidget && messages.length === 0 && !loading ? 0 : 'auto' }}>
          {selectMultiple && messages.length > 0 ?
            <>
              <Checkbox
                checked={checkAll}
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                style={{ marginBottom: 0, paddingLeft: 40 }}
              >
                {checkAll ? 'Unselect all' : 'Select all'}
              </Checkbox>
              <Divider style={{ marginBottom: 16, marginTop: 8 }} />
            </>
            : null
          }
          <Messages
            messages={messages}
            onChange={onChange}
            value={value}
          />
          <Loading loading={loading} />
        </div>
      </div>
    );
  };

  const Actions = ({
    deleteSelectedMessages,
    disabled,
    enableCritique,
    hasMessages,
    hasSelected,
    regenerate,
    saveChatSession,
    startNewChatSession,
    visible,
  }) => {
    if (!visible) {
      return null;
    }
    return (
      <div className="chat-actions">
        <Button type={isWidget ? 'default' : 'primary'} size="small"
          disabled={disabled || !(hasMessages || input)}
          onClick={startNewChatSession}
        >
          {isWidget ? 'Clear' : 'New Session'}
        </Button>
        {!isWidget ?
          <Button type="primary" size="small"
            disabled={disabled || !hasMessages}
            onClick={regenerate}
          >
            Regenerate
          </Button>
          : null
        }
        {onSave ?
          <Button type="primary" size="small"
            disabled={disabled || !hasMessages}
            onClick={saveChatSession}
          >
            Save Session
          </Button>
          : null
        }
        {onDelete ?
          <Button danger type="primary" size="small"
            disabled={disabled || !hasSelected}
            onClick={deleteSelectedMessages}
          >
            Delete
          </Button>
          : null
        }
        {onCritique ?
          <Button type="primary" size="small"
            disabled={disabled || !hasMessages || !enableCritique}
            onClick={critique}
          >
            Evaluate
          </Button>
          : null
        }
        {traceId ?
          <div style={{ color: '#1677ff', flex: 1, marginRight: 36, textAlign: 'end', whiteSpace: 'nowrap' }}>
            <Link to={`/traces/${traceId}`}>Latest trace...</Link>
          </div>
          : null
        }
      </div>
    );
  };

  let style;
  if (isWidget) {
    style = {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      width,
    }
  } else {
    style = {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      paddingRight: 256,
    }
  }


  return (
    <>
      <div
        className={isWidget ? 'chat-widget' : 'chat'}
        style={style}
      >
        <MessagesSection
          disabled={disabled}
          loading={loading}
          messages={messages}
          onChange={(ev) => handleChange(ev.target.value)}
          value={selectedKeys[0]}
          isWidget={isWidget}
        />
        <div ref={tourRefs?.prompt}
          style={{ marginTop: isWidget && messages.length === 0 && !loading ? -16 : 0 }}
        >
          <MessageInput
            appOptions={[]}
            disabled={disabled}
            loading={loading}
            onChange={setInput}
            onSubmit={handleSubmit}
            placeholder={placeholder}
            value={input}
            isWidget={isWidget}
          />
        </div>
        {!isWidget || messages.length || input ?
          <Actions
            critique={critique}
            deleteSelectedMessages={deleteSelectedMessages}
            disabled={disabled}
            enableCritique={enableCritique}
            handleSuggestPrompts={handleSuggestPrompts}
            hasMessages={hasMessages}
            hasSelected={hasSelected}
            regenerate={regenerate}
            saveChatSession={handleSave}
            startNewChatSession={handleReset}
            visible={enableActions}
          />
          : null
        }
      </div>
      {videoParams.videoId ?
        <div
          className="bubble"
          style={{
            top: videoParams.y,
            left: videoParams.x,
          }}
        >
          <YouTube
            onReady={onReady}
            opts={{
              ...opts,
              playerVars: { ...opts.playerVars, start: videoParams.start },
            }}
            videoId={videoParams.videoId}
          />
          <div>
            <Typography.Paragraph>
              <blockquote>{videoParams.quote}</blockquote>
            </Typography.Paragraph>
          </div>
        </div>
        : null
      }
    </>
  );
}

const MessageInput = ({ appOptions, disabled, loading, onChange, onSubmit, placeholder, value, isWidget }) => {

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (value) => {
    const mention = appOptions.find(a => value.includes(a.value));
    if (!mention) {
      setSelectedOption(null);
    }
    onChange(value);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    onSubmit(selectedOption?.value);
  };

  let style;
  if (isWidget) {
    style = {
      flex: 1,
      fontSize: '14px',
      minHeight: 32,
    };
  } else {
    style = {
      flex: 1,
    }
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Mentions
          autoSize={{ minRows: 1, maxRows: 14 }}
          filterOption={() => !selectedOption}
          onPressEnter={(ev) => {
            if (!ev.shiftKey) {
              ev.preventDefault();
              if (!disabled && ev.target.value) {
                handleSubmit(ev);
              }
            }
          }}
          style={style}
          onChange={handleChange}
          onSelect={handleSelect}
          options={appOptions}
          placeholder={placeholder}
          value={value}
          variant="borderless"
        />
        <div style={{ marginLeft: 3 }}>
          <Button type="text"
            disabled={disabled || loading || !value}
            icon={<SendOutlined />}
            onClick={handleSubmit}
          />
        </div>
      </div>
      {!isWidget ?
        <p className="text-secondary"
          style={{ lineHeight: '32px' }}
        >
          Press Shift+Enter to insert a new line.
        </p>
        : null
      }
    </>
  );
};
