import React from 'react';
import {
  Form,
  Input,
  Modal,
} from 'antd';

export default function YoutubeUploadModal({
  onCancel,
  onSubmit,
  open,
}) {

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onSubmit({ values });
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      okText="Upload"
      title="Upload from YouTube"
    >
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="youtubeUrl"
          extra="Paste link to a YouTube Video"
          rules={[
            {
              required: true,
              message: 'Please paste the URL',
            },
          ]}
        >
          <Input placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" />
        </Form.Item>
      </Form>
    </Modal>
  );
}