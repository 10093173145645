import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { http } from '../../http';
import { pollStatus } from '../../utils';
import { setOntologies } from '../ontology/ontologiesSlice';
import { setSettings } from '../settings/settingsSlice';

export const fileUploaderSlice = createSlice({
  name: 'fileUploader',
  initialState: {
    loaded: false,
    loading: false,
    uploaded: false,
    uploading: false,
    uploads: {},
    intervalIds: {},
  },
  reducers: {
    removeUploads: (state, action) => {
      const uploads = { ...state.uploads };
      for (const id of action.payload.ids) {
        delete uploads[id];
      }
      state.uploads = uploads;
    },
    resetUploads: (state) => {
      state.uploads = {};
      state.loaded = false;
      state.loading = false;
    },
    setUploads: (state, action) => {
      const { uploads } = action.payload;
      for (const upload of uploads) {
        state.uploads[upload.id] = upload;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
    startUpload: (state) => {
      state.uploading = true;
      state.uploaded = false;
    },
    uploaded: (state) => {
      state.uploaded = true;
      state.uploading = false;
    },
    addIntervalId: (state, action) => {
      state.intervalIds[action.payload.intervalId] = 1;
    },
    resetIntervalIds: (state, action) => {
      for (const intervalId of Object.keys(state.intervalIds)) {
        clearInterval(intervalId);
      }
      state.intervalIds = {};
      state.loading = false;
    },
  }
});

export const {
  addIntervalId,
  resetIntervalIds,
  removeUploads,
  resetUploads,
  setUploads,
  startLoad,
  startUpload,
  uploaded,
} = fileUploaderSlice.actions;

export const applyActivityAsync = ({ activity, domain, objectNames, workspaceId }) => async (dispatch) => {
  dispatch(startUpload());
  const correlationId = uuidv4();
  const url = '/api/activities';
  await http.post(url, {
    activity,
    correlationId,
    domain,
    objectNames,
    workspaceId,
    domain,
  });
  const intervalId = pollStatus(http, correlationId, () => {
    dispatch(uploaded());
  });
  dispatch(addIntervalId({ intervalId }));
};

export const applyExtractClaimsActivityAsync = ({ pattern, rootFolderId, workspaceId, domain }) => async (dispatch) => {
  dispatch(startUpload());
  const correlationId = uuidv4();
  const url = '/api/education-activities/extract-claims';
  await http.post(url, {
    correlationId,
    pattern,
    rootFolderId,
    workspaceId,
    domain,
  });
  const intervalId = pollStatus(http, correlationId, () => {
    dispatch(uploaded());
  });
  dispatch(addIntervalId({ intervalId }));
};

export const applyExtractMandateActivityAsync = ({ clientId, key, path, searchQuery, workspaceId, domain }) => async (dispatch) => {
  dispatch(startUpload());
  const url = '/api/activities/extract-mandate';
  await http.post(url, {
    clientId,
    key,
    path,
    searchQuery,
    workspaceId,
    domain,
  });
  dispatch(uploaded());
};

export const applyExtractQuotesActivityAsync = ({ pattern, rootFolderId, workspaceId, domain }) => async (dispatch) => {
  dispatch(startUpload());
  const correlationId = uuidv4();
  const url = '/api/education-activities/extract-quotes';
  await http.post(url, {
    correlationId,
    pattern,
    rootFolderId,
    workspaceId,
    domain,
  });
  const intervalId = pollStatus(http, correlationId, () => {
    dispatch(uploaded());
  });
  dispatch(addIntervalId({ intervalId }));
};

export const applyExtractReferencesActivityAsync = ({ pattern, rootFolderId, workspaceId, domain }) => async (dispatch) => {
  dispatch(startUpload());
  const correlationId = uuidv4();
  const url = '/api/education-activities/extract-references';
  await http.post(url, {
    correlationId,
    pattern,
    rootFolderId,
    workspaceId,
    domain,
  });
  const intervalId = pollStatus(http, correlationId, () => {
    dispatch(uploaded());
  });
  dispatch(addIntervalId({ intervalId }));
};

export const applyExtractThesisStatementActivityAsync = ({ pattern, rootFolderId, workspaceId, domain }) => async (dispatch) => {
  dispatch(startUpload());
  const correlationId = uuidv4();
  const url = '/api/education-activities/extract-thesis-statement';
  await http.post(url, {
    correlationId,
    pattern,
    rootFolderId,
    workspaceId,
    domain,
  });
  const intervalId = pollStatus(http, correlationId, () => {
    dispatch(uploaded());
  });
  dispatch(addIntervalId({ intervalId }));
};

export const applyExtractVideoActivityAsync = ({ clientId, key, youtubeUrl, workspaceId, domain }) => async (dispatch) => {
  dispatch(startUpload());
  const url = '/api/activities/extract-video';
  const res = await http.post(url, {
    clientId,
    key,
    youtubeUrl,
    workspaceId,
    domain,
  });
  dispatch(uploaded());
};

export const applyExtractVideosActivityAsync = ({ clientId, key, query, workspaceId, domain }) => async (dispatch) => {
  dispatch(startUpload());
  const correlationId = uuidv4();
  const url = '/api/activities/extract-videos';
  const res = await http.post(url, {
    clientId,
    key,
    query,
    workspaceId,
    domain,
  });
  // const intervalId = pollStatus(http, correlationId, () => {
  //   dispatch(uploaded());
  // });
  // dispatch(addIntervalId({ intervalId }));
  dispatch(uploaded());
};

export const fileUploadAsync = (file, isImage, workspaceId, domain, clientId, process) => async (dispatch, getState) => {
  dispatch(startUpload());
  const form = new FormData();
  form.append('file', file.originFileObj);
  form.append('isImage', isImage);
  form.append('clientId', clientId);
  form.append('workspaceId', workspaceId);
  form.append('domain', domain);
  form.append('process', process ? 'true' : 'false');
  const res = await http.post('/api/uploads', form);
  const uploads = getState().fileUploader.uploads;
  dispatch(setUploads({ uploads: [...Object.values(uploads), res.data] }));
  dispatch(uploaded());
};

const fetchUploads = async (workspaceId) => {
  const url = `/api/uploads?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  return res.data;
};

export const getUploadsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  dispatch(resetUploads());
  const uploads = await fetchUploads(workspaceId);
  dispatch(setUploads({ uploads }));
};

export const getUploadAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/uploads/${id}`;
  const res = await http.get(url);
  dispatch(setUploads({ uploads: [res.data] }));
};

export const deleteUploadsAsync = ({ workspaceId, uploads }) => async (dispatch) => {
  const ids = uploads.map((u) => u.id);
  const idList = ids.join(',');
  const url = `/api/uploads?workspace_id=${workspaceId}&ids=${idList}`;
  await http.delete(url);
  dispatch(removeUploads({ workspaceId, ids }));
};

export const getUploadForObjectNameAsync = ({ objectName, workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/uploads/objects/${objectName}?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setUploads({ uploads: [res.data] }));
};

export const objectUploadAsync = ({ file, type, workspaceId }) => async (dispatch) => {
  dispatch(startUpload());
  const form = new FormData();
  form.append('workspaceId', workspaceId);
  form.append('type', type);
  form.append('file', file.originFileObj);
  const res = await http.post('/api/uploads/objects', form);
  if (type === 'setting') {
    dispatch(setSettings({ settings: [res.data] }));
  } else if (type === 'ontology') {
    dispatch(setOntologies({ ontologies: [res.data] }));
  }
  dispatch(uploaded());
};

export const createUploadAsync = ({ correlationId, values }) => async (dispatch, getState) => {
  const url = '/api/uploads/record';
  const res = await http.post(url, values);
  const uploads = getState().fileUploader.uploads;
  dispatch(setUploads({ uploads: [...Object.values(uploads), { ...res.data, correlationId, processing: true }] }));
};

export const updateUploadAsync = ({ correlationId, id, values, partial }) => async (dispatch, getState) => {
  let url = `/api/uploads/record/${id}`;
  if (partial) url += '?partial=true';
  const res = await http.put(url, values);
  const uploads = getState().fileUploader.uploads;
  dispatch(setUploads({ uploads: [...Object.values(uploads), { ...res.data, correlationId }] }));
};

export const createPodcastAsync = ({ objectName, workspaceId }) => async (dispatch) => {
  const url = `/api/audio/podcast`;
  const res = await http.post(url, { objectName, workspaceId });
  // dispatch(setUploads({ uploads: [res.data] }));
};

export const selectLoaded = (state) => state.fileUploader.loaded;

export const selectLoading = (state) => state.fileUploader.loading;

export const selectUploaded = (state) => state.fileUploader.uploaded;

export const selectUploading = (state) => state.fileUploader.uploading;

export const selectUploads = (state) => state.fileUploader.uploads;


export default fileUploaderSlice.reducer;
