import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  AlertOutlined,
  AntDesignOutlined,
  ApartmentOutlined,
  AppstoreOutlined,
  BarsOutlined,
  BulbOutlined,
  CheckCircleOutlined,
  CommentOutlined,
  ControlOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  DotChartOutlined,
  FileOutlined,
  ExpandOutlined,
  ForkOutlined,
  FormOutlined,
  FundOutlined,
  HomeOutlined,
  PaperClipOutlined,
  ProjectOutlined,
  SafetyCertificateOutlined,
  SearchOutlined,
  SettingOutlined,
  ShareAltOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import omit from 'lodash.omit';
import useLocalStorageState from 'use-local-storage-state';

import MyLogo from '../images/compliance_copilot_logo15.png';
import MyLogoFull from '../images/compliance_copilot_logo14.png';
import PQLogo from '../images/pq_logo.svg';

const APP = process.env.REACT_APP_NAME === 'Compliance Copilot' ? 'compliancecopilot' : 'pqacademy';
const IS_EDGE_VERSION = process.env.REACT_APP_IS_EDGE_VERSION === 'true';

// console.log('App:', APP);
// console.log('Edge?', IS_EDGE_VERSION ? 'true' : 'false');

const { Sider } = Layout;

function SideMenu({ isDarkMode, isAdmin, currentUser }) {

  const menuConfig = APP === 'pqacademy' ? pqacademyMenuConfig : compliancecopilotMenuConfig;

  const menuItems = getMenuItems(modules, menuConfig, currentUser, isAdmin, IS_EDGE_VERSION);

  // console.log('menu config:', menuConfig);
  // console.log('menu items:', menuItems);

  const [collapsed, setCollapsed] = useLocalStorageState('side-menu', { defaultValue: true });

  return (
    <Sider id="menu"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      theme={isDarkMode ? 'dark' : 'light'}
      width={235}
    >
      <NavLink to="/" className={'logo-image' + (collapsed ? ' collapsed' : '')}>
        <div>
          <div>
            {APP === 'pqacademy' ? (
              collapsed ?
                <img
                  src={PQLogo}
                  style={{ width: 45, height: 27 }}
                  alt={process.env.REACT_APP_NAME}
                />
                :
                <img
                  src={'https://cdn.prod.website-files.com/65a0ee83191f426bb713550e/65a0ee83191f426bb71355b7_logotype%20-%20white.svg'}
                  alt={process.env.REACT_APP_NAME}
                />
            ) : (
              collapsed ?
                <img
                  src={MyLogo}
                  style={{ width: 37, height: 37 }}
                  alt={process.env.REACT_APP_NAME} />
                : <img
                  src={MyLogoFull}
                  alt={process.env.REACT_APP_NAME} />
            )}
          </div>
        </div>
      </NavLink>
      <br />
      <Menu
        items={menuItems}
        mode="vertical"
        theme={isDarkMode ? 'dark' : 'light'}
        triggerSubMenuAction="click"
      />
    </Sider>
  );
}

const compliancecopilotMenuConfig = [
  'opsDashboard',
  // 'complianceOps',
  // { module: 'complianceDesigner', children: ['graph', 'ontologies', 'chat', 'search', 'notebooks'] },
  { module: 'complianceDesigner', children: ['graph', 'ontologies', 'chat', 'search'] },
  { module: 'opsQualityAssurance', children: ['tasksBoard', 'tasks'] },
  { module: 'dataCenter', children: ['sources', 'inference'] },
  // {
  //   module: 'documentation',
  //   children: [
  //     { module: 'artefactsParent', children: ['artefacts', 'sections'] },
  //     { module: 'metricsParent', children: ['metrics', 'metricSets'] },
  //   ],
  // },
  // { module: 'adminParent', children: ['workspaces', 'users', 'settings', 'maintenance'] },
  { module: 'adminParent', children: ['workspaces', 'settings'] },
];

const pqacademyMenuConfig = [
  'tutor',
  { module: 'analysis', children: ['graph', 'ontologies', 'chat', 'search'] },
  { module: 'inputs', children: ['sources', 'inference'] },
  // {
  //   module: 'documentation',
  //   children: [
  //     { module: 'artefactsParent', children: ['artefacts', 'sections'] },
  //   ],
  // },
  { module: 'adminParent', children: ['workspaces', 'users', 'settings', 'maintenance'] },
];

const modules = {
  home: {
    menuItem: {
      key: 'home',
      icon: <HomeOutlined />,
      label: (
        <NavLink to="/">Home</NavLink>
      ),
    },
    role: 'admin',
  },
  workspaces: {
    menuItem: {
      key: 'workspaces',
      icon: <TeamOutlined />,
      label: (
        <NavLink to="/workspaces">Workspaces</NavLink>
      ),
    },
    role: 'admin',
  },
  sources: {
    menuItem: {
      key: 'sources',
      icon: <FileOutlined />,
      label: (
        <NavLink to="/uploads">Data Sources</NavLink>
      ),
    },
    role: 'admin',
  },
  inference: {
    menuItem: {
      key: 'inference',
      icon: <ForkOutlined />,
      label: (
        <NavLink to="/inference">Data Augmentation</NavLink>
      ),
    },
    role: 'admin',
  },
  graph: {
    menuItem: {
      key: 'graph',
      icon: <ShareAltOutlined />,
      label: (
        <NavLink to="/graph">Knowledge Graph</NavLink>
      ),
    },
    role: 'admin',
  },
  chat: {
    menuItem: {
      key: 'chat',
      icon: <CommentOutlined />,
      label: (
        <NavLink to="/chat">Chat Assistant</NavLink>
      ),
    },
    role: 'admin',
  },
  search: {
    menuItem: {
      key: 'search',
      icon: <SearchOutlined />,
      label: (
        <NavLink to="/search">Knowledge Search</NavLink>
      ),
    },
    role: 'admin',
  },
  notebooks: {
    menuItem: {
      key: 'notebooks',
      icon: <PaperClipOutlined />,
      label: (
        <NavLink to="/notebooks">Casebooks</NavLink>
      ),
    },
    role: 'admin',
  },
  artefacts: {
    menuItem: {
      key: 'artefacts',
      icon: <FormOutlined />,
      label: (
        <NavLink to="/artefacts">Artefacts</NavLink>
      ),
    },
    role: 'admin',
    edge: true,
  },
  sections: {
    menuItem: {
      key: 'sections',
      icon: <ApartmentOutlined />,
      label: (
        <NavLink to="/sections">Sections</NavLink>
      ),
    },
    role: 'admin',
    edge: true,
  },
  metrics: {
    menuItem: {
      key: 'metrics',
      icon: <FundOutlined />,
      label: (
        <NavLink to="/metrics">Metrics</NavLink>
      ),
    },
    role: 'admin',
    edge: true,
  },
  metricSets: {
    menuItem: {
      key: 'metricSets',
      icon: <AppstoreOutlined />,
      label: (
        <NavLink to="/metric-sets">Metric Sets</NavLink>
      ),
    },
    role: 'admin',
    edge: true,
  },
  report: {
    menuItem: {
      key: 'report',
      icon: <AlertOutlined />,
      label: (
        <NavLink to="/report">Report</NavLink>
      ),
    },
    role: 'admin',
    edge: true,
  },
  interview: {
    menuItem: {
      key: 'interview',
      icon: <UserOutlined />,
      label: (
        <NavLink to="/interviews">Interview</NavLink>
      ),
    },
    role: 'admin',
    edge: true,
  },
  users: {
    menuItem: {
      key: 'users',
      icon: <UserOutlined />,
      label: (
        <NavLink to="/users">Users</NavLink>
      ),
    },
    role: 'admin',
  },
  ontologies: {
    menuItem: {
      key: 'ontologies',
      icon: <ApartmentOutlined />,
      label: (
        <NavLink to="/ontologies">Ontologies</NavLink>
      ),
    },
    role: 'admin',
  },
  settings: {
    menuItem: {
      key: 'settings',
      icon: <SettingOutlined />,
      label: (
        <NavLink to="/settings">Settings</NavLink>
      ),
    },
    role: 'admin',
  },
  maintenance: {
    menuItem: {
      key: 'maintenance',
      icon: <DesktopOutlined />,
      label: (
        <NavLink to="/admin">Maintenance</NavLink>
      ),
    },
    role: 'admin',
  },
  tutor: {
    menuItem: {
      key: 'tutor',
      icon: <BulbOutlined />,
      label: (
        <NavLink to="/tutor">Academy</NavLink>
      ),
    },
  },
  inputs: {
    menuItem: {
      key: 'inputs',
      icon: <DatabaseOutlined />,
      label: "Inputs",
    },
    role: 'admin',
  },
  analysis: {
    menuItem: {
      key: 'analysis',
      icon: <ShareAltOutlined />,
      label: "Analysis",
    },
    role: 'admin',
  },
  documentation: {
    menuItem: {
      key: 'documentation',
      icon: <FormOutlined />,
      label: "Artefacts",
    },
    role: 'admin',
    edge: true,
  },
  artefactsParent: {
    menuItem: {
      key: 'artefactsParent',
      icon: <FormOutlined />,
      label: "Artefacts",
    },
    role: 'admin',
    edge: true,
  },
  metricsParent: {
    menuItem: {
      key: 'metricsParent',
      icon: <FundOutlined />,
      label: 'Metrics',
    },
    role: 'admin',
    edge: true,
  },
  adminParent: {
    menuItem: {
      key: 'adminParent',
      icon: <ControlOutlined />,
      label: 'Admin',
    },
    role: 'admin',
  },
  complianceOps: {
    menuItem: {
      key: 'complianceOps',
      icon: <SafetyCertificateOutlined />,
      label: (
        <NavLink to="/hub">Compliance Ops Hub</NavLink>
      ),
    },
  },
  dataCenter: {
    menuItem: {
      key: 'dataCenter',
      icon: <DatabaseOutlined />,
      label: 'Data Center',
    },
    role: 'admin',
  },
  analysisCenter: {
    menuItem: {
      key: 'analysisCenter',
      icon: <DotChartOutlined />,
      label: 'Analysis Center',
    },
  },
  opsDashboard: {
    menuItem: {
      key: 'opsDashboard',
      icon: <AppstoreOutlined />,
      label: (
        <NavLink to="/">Ops Dashboard</NavLink>
      ),
    },
    role: 'admin',
  },
  complianceDesigner: {
    menuItem: {
      key: 'complianceDesigner',
      // icon: <SafetyCertificateOutlined />,
      icon: <ShareAltOutlined />,
      label: 'Compliance Designer',
    },
    role: 'admin',
  },
  opsQualityAssurance: {
    menuItem: {
      key: 'opsQualityAssurance',
      icon: <CheckCircleOutlined />,
      label: 'Ops Quality Assurance',
    },
    role: 'admin',
  },
  tasksBoard: {
    menuItem: {
      key: 'tasksBoard',
      icon: <ProjectOutlined />,
      label: (
        <NavLink to="/board">Active Tasks</NavLink>
      ),
    },
    role: 'admin',
  },
  tasks: {
    menuItem: {
      key: 'tasks',
      icon: <BarsOutlined />,
      label: (
        <NavLink to="/tasks">All Tasks</NavLink>
      ),
    },
    role: 'admin',
  },
};

const getModule = (modules, isAdmin, isEdge, item) => {
  const getMod = (item) => {
    let module, children, xtra;
    if (typeof item === 'string') {
      module = item;
      xtra = {};
    } else {
      module = item.module;
      children = item.children;
      xtra = omit(item, ['module', 'children']);
    }
    const mod = modules[module];
    if (mod.role === 'admin' && !isAdmin) {
      return null;
    }
    if (mod.edge && !isEdge) {
      return null;
    }
    if (children) {
      return {
        ...mod.menuItem,
        ...xtra,
        children: children.map(getMod),
      };
    } else {
      return {
        ...mod.menuItem,
        ...xtra,
      };
    }
  };

  return getMod(item);
};

const getMenuItems = (modules, menuConfig, currentUser, isAdmin, isEdge) => {
  return menuConfig.map(item => getModule(modules, isAdmin, isEdge, item)).filter(v => v);
}

export default SideMenu;
