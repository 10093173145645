import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Menu, Space } from 'antd';
import {
  InboxOutlined,
  RightOutlined,
  SafetyCertificateOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import UserContext from '../../contexts/UserContext';
import { MyIcon } from '../ontology/icons1';
import {
  getOntologiesAsync,
  selectOntologies,
} from '../ontology/ontologiesSlice';

import {
  getTasksAsync,
  selectTasks,
} from '../tasks/tasksSlice';

export function ComplianceOpsHub() {

  const [ready, setReady] = useState(false);

  const ontologies = useSelector(selectOntologies);
  const tasks = useSelector(selectTasks);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);
  const { currentUser } = useContext(UserContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Compliance Ops Hub',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getOntologiesAsync({ workspaceId }));
      dispatch(getTasksAsync({ workspaceId }));
      setReady(true);
    }
  }, [selectedWorkspace]);

  const CardTitle = ({ icon, title }) => (
    <div className="card-title">
      {icon}
      <div>{title}</div>
    </div>
  );

  const handleItemClick = ({ key }) => {
    let ontology = selectedWorkspace.defaultOntology;
    if (!ontology) {
      ontology = Object.values(ontologies)[0].id;
    }
    switch (key) {
      case 'regulatory':
        navigate(`/graph/SubMandate?title=Regulatory+Frameworks&ontology=${ontology}`);
        break;

      case 'mandates':
        navigate(`/graph/Mandate?title=Mandates&ontology=${ontology}`);
        break;

      case 'sub-mandates':
        navigate(`/graph/SubMandate?title=SubMandates&ontology=${ontology}`);
        break;

      case 'obligations':
        navigate(`/graph/Obligation?title=Obligations&ontology=${ontology}`);
        break;

      case 'organization':
        navigate(`/graph/OrganizationUnit?title=Organization+Units&ontology=${ontology}`);
        break;

      case 'risk':
        navigate(`/graph/L1RiskCategory?title=Risks&ontology=${ontology}`);
        break;

      case 'control':
        navigate(`/graph/ControlType?title=Controls&ontology=${ontology}`);
        break;

      case 'process':
        navigate(`/graph/BusinessFunction?title=Processes&ontology=${ontology}`);
        break;

      case 'product':
        navigate(`/graph/BusinessFunction?title=Processes&ontology=${ontology}`);
        break;

      case 'scenario':
        navigate(`/graph/L2RiskScenario?title=Risk+Scenarios&ontology=${ontology}`);
        break;

      case 'document':
        navigate('/uploads');
        break;

      case 'role':
        navigate('/defined?title=Roles+%26+Responsibilities&types=OrganizationUnit');
        break;

      case 'system':
        navigate('/');
        break;

    }
  };

  return (
    <div id="home">
      <div style={{ display: 'flex', justifyContent: 'left', marginTop: 20, marginLeft: 0 }}>
        {ready ?
          <Space align="start" size="large">
            <Space align="start" direction="vertical" size={24}>
              <Space align="start" size="large" wrap={true} style={{ justifyContent: 'center', maxWidth: 1200 }}>
                <Card
                  className="menu-card secondary"
                  title={
                    <CardTitle
                      icon={<SafetyCertificateOutlined style={{ fontSize: '28px' }} />}
                      title="Compliance Operations Management"
                    />
                  }
                  style={{ minHeight: 257, width: 873 }}
                >
                  <Menu
                    items={[
                      {
                        key: 'compliance',
                        icon: <MyIcon type="compliance" />,
                        label: 'Compliance Obligations and Requirements',
                        children: [
                          // {
                          //   key: 'regulatory',
                          //   label: 'Regulatory Frameworks',
                          // },
                          {
                            key: 'mandates',
                            label: 'Mandates',
                          },
                          {
                            key: 'sub-mandates',
                            label: 'Sub mandates',
                          },
                          {
                            key: 'obligations',
                            label: 'Obligations',
                          },
                          {
                            key: 'organization',
                            label: 'Organization Units',
                          },
                          {
                            key: 'risk',
                            label: 'Risks',
                          },
                          {
                            key: 'control',
                            label: 'Controls',
                          },
                          {
                            key: 'process',
                            label: 'Processes',
                          },
                          {
                            key: 'product',
                            label: 'Products',
                          },
                          {
                            key: 'scenario',
                            label: 'Risk Scenarios',
                          },
                        ]
                      },
                      {
                        key: 'change',
                        icon: <MyIcon type="loopingArrows" />,
                        label: 'Changes & Monitoring',
                      },
                    ]}
                    mode="inline"
                    onClick={handleItemClick}
                    style={{ width: '100%' }}
                    openKeys={['compliance']}
                  />
                </Card>
              </Space>
              <Space align="start" size="large" wrap={true} style={{ justifyContent: 'center', maxWidth: 1200 }}>
                <Card
                  className="menu-card secondary"
                  title={
                    <CardTitle
                      icon={<ShareAltOutlined style={{ fontSize: '28px' }} />}
                      title="Compliance Knowledge Repository"
                    />
                  }
                  style={{ minHeight: 326, width: 873 }}
                >
                  <Menu
                    items={[
                      {
                        key: 'document',
                        icon: <MyIcon type="document" />,
                        label: 'Document Catalog',
                      },
                      {
                        key: 'role',
                        icon: <MyIcon type="userEngagement" />,
                        label: 'Roles & Responsibilities',
                      },
                      {
                        key: 'system',
                        icon: <MyIcon type="webSettings" />,
                        label: 'Systems and Tools',
                      },
                    ]}
                    mode="inline"
                    onClick={handleItemClick}
                    style={{ width: '100%' }}
                  />
                </Card>
              </Space>
            </Space>
            <Space align="start" size="large" wrap={true} style={{ justifyContent: 'center', maxWidth: 1200 }}>
              <Card
                className="secondary"
                title={
                  <CardTitle
                    icon={<InboxOutlined style={{ fontSize: '28px' }} />}
                    title="Actions"
                  />
                }
                style={{ height: 607, minHeight: 607 }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', height: 464 }}>
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 8 }}>
                    {Object.values(tasks).map(task => (
                      <Link key={task.id} to={`/tasks/${task.id}`}>{task.name}</Link>
                    ))}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      type="text"
                      icon={<RightOutlined />}
                      onClick={() => navigate('/board')}
                      style={{ background: '#EEE' }}
                    />
                  </div>
                </div>
              </Card>
            </Space>
          </Space>
          :
          <div style={{ color: '#fff', textAlign: 'center' }}>
            To get started, select a Workspace from the top-right menu (next to profile).
          </div>
        }
      </div>
    </div>
  );
}
