import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { http } from '../../http';
import { pollStatus } from '../../utils';

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState: {
    loaded: false,
    loading: false,
    running: false,
    sections: {},
    result: null,
  },
  reducers: {
    removeSections: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.sections[id];
      }
    },
    resetSections: (state) => {
      state.sections = {};
    },
    setResult: (state, action) => {
      state.result = action.payload.result;
    },
    setSections: (state, action) => {
      for (const section of action.payload.sections) {
        state.sections[section.id] = section;
      }
      state.loaded = true;
      state.loading = false;
      state.running = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
    startRun: (state) => {
      state.running = true;
    },
  }
});

export const {
  removeSections,
  resetSections,
  setResult,
  setSections,
  startLoad,
  startRun,
} = sectionsSlice.actions;

export const getSectionsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/sections?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setSections({ sections: res.data }));
};

export const getSectionAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/sections/${id}`;
  const res = await http.get(url);
  dispatch(setSections({ sections: [res.data] }));
};

export const createSectionAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/sections';
  const res = await http.post(url, values);
  dispatch(setSections({ sections: [{ ...res.data, correlationId }] }));
};

export const updateSectionAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/sections/${id}`;
  const res = await http.put(url, values);
  dispatch(setSections({ sections: [{ ...res.data, correlationId }] }));
};

export const deleteSectionsAsync = (ids) => async (dispatch) => {
  const url = `/api/sections?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeSections({ ids }));
};

export const runSummaryAsync = ({ clientId, key, nodeType, workspaceId, domain }) => async (dispatch) => {
  const url = '/api/activities/summarize';
  await http.post(url, { clientId, key, nodeType, workspaceId, domain });
};

export const extractIdeasAsync = ({ clientId, key, nodeType, workspaceId, domain }) => async (dispatch) => {
  const url = '/api/activities/extract-ideas';
  await http.post(url, { clientId, key, nodeType, workspaceId, domain });
};

export const runToolAsync = ({ clientId, key, section }) => async (dispatch) => {
  const url = '/api/education-activities/run-tool';
  const res = await http.post(url, {
    clientId,
    key,
    section,
  });
  dispatch(setResult({ result: res.data }));
};

export const getAssignmentReviewAsync = ({ section }) => async (dispatch) => {
  dispatch(startRun());
  const url = '/api/education-activities/assignment-review?student_id=6e2fd5919eeb0ab94e33ad70dd2551a5&assignment_id=6fd75c8a53965a417cf6d11a29475f37&workspace_id=b742b29e-12b1-4607-ba9a-fa8b458de42a';
  const res = await http.get(url);
  const newSection = {
    ...section,
    content_versions: [{ content: res.data }],
  };
  dispatch(setSections({ sections: [newSection] }));
};

export const selectLoaded = (state) => state.sections.loaded;

export const selectLoading = (state) => state.sections.loading;

export const selectResult = (state) => state.sections.result;

export const selectRunning = (state) => state.sections.running;

export const selectSections = (state) => state.sections.sections;

export default sectionsSlice.reducer;
