import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState: {
    correlationId: null,
    loaded: false,
    loading: false,
    assessments: {},
  },
  reducers: {
    removeAssessments: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.assessments[id];
      }
    },
    resetAssessments: (state) => {
      state.assessments = {};
    },
    setCorrelationId: (state, action) => {
      state.correlationId = action.payload.correlationId;
    },
    setAssessments: (state, action) => {
      for (const assessment of action.payload.assessments) {
        state.assessments[assessment.id] = assessment;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeAssessments,
  resetAssessments,
  setCorrelationId,
  setAssessments,
  startLoad,
} = assessmentsSlice.actions;

export const getAssessmentsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/assessments?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setAssessments({ assessments: res.data }));
};

export const getAssessmentAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/assessments/${id}`;
  const res = await http.get(url);
  dispatch(setAssessments({ assessments: [res.data] }));
};

export const createAssessmentAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/assessments';
  const res = await http.post(url, values);
  dispatch(setAssessments({ assessments: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const updateAssessmentAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/assessments/${id}`;
  const res = await http.put(url, values);
  dispatch(setAssessments({ assessments: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const deleteAssessmentsAsync = (ids) => async (dispatch) => {
  const url = `/api/assessments?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeAssessments({ ids }));
};

export const selectAssessments = (state) => state.assessments.assessments;

export const selectLoaded = (state) => state.assessments.loaded;

export const selectLoading = (state) => state.assessments.loading;

export const selectCorrelationId = (state) => state.assessments.correlationId;

export default assessmentsSlice.reducer;
