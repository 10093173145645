import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { md5 } from 'js-md5';
import { v4 as uuidv4 } from 'uuid';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import pluralize from 'pluralize';

import { GraphView2 } from '../../components/GraphView2';
import NavbarContext from '../../contexts/NavbarContext';
import UserContext from '../../contexts/UserContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  filterGraph,
  getDataSource,
  getDefaultColumns,
  getEdgeMappingColumns,
  getEdges,
  getSelectedTypes,
} from '../../utils/graphUtils';

import {
  getOntologiesAsync,
  selectLoaded as selectOntologiesLoaded,
  selectLoading as selectOntologiesLoading,
  selectOntologies,
} from '../ontology/ontologiesSlice';
import { MyImage } from '../ontology/icons1';

import {
  createGraphSessionAsync,
  deleteGraphSessionsAsync,
  getGraphSessionsAsync,
  selectGraphSessions,
  selectLoaded as selectSessionsLoaded,
  selectLoading as selectSessionsLoading,
} from './graphSessionsSlice';
import {
  createNodeAsync,
  deleteNodeAsync,
  deleteRelationshipAsync,
  createRelationshipAsync,
  getGraphFromNodeAsync,
  getNodesMetadataAsync,
  getNodeOptionsAsync,
  getRelationshipsMetadataAsync,
  mergeNodesAsync,
  selectLoading as selectGraphLoading,
  selectNodesMetadata,
  selectNodeOptions,
  selectRelationshipsMetadata,
  runGraphQuery,
  selectGraphs,
} from './graphSlice';

const excludeFields = [
  'dataSource',
  'defaultColumns',
  'graph',
  'legend',
  'selectAll',
  'total',
];

const LAST_GRAPH_SESSION = process.env.REACT_APP_LAST_GRAPH_SESSION;

const includeFields = [
  'connected',
  'cypherQuery',
  'fetchType',
  'filters',
  'layout',
  'pageSize',
  'page',
  'params',
  // 'queries',
  'query',
  'searchChunks',
  'searchQuery',
  'selectedNodeInstances',
  'selectedTypes',
  'sort',
  // 'tags',
];

const hyphenated = (name) => {
  return name.replace(/([a-z])([A-Z])/g, m => `${m[0]} ${m[1].toLowerCase()}`)
};

export function Graph2() {

  const initialValue = {
    fetchType: 'search',
    graph: { nodes: [], relatonships: [], legend: {} },
    labelType: 'auto',
    layout: 'graph',
    nodeStyle: 'color',
    pageSize: 500,
    page: 1,
    searchChunks: false,
    selectedNodeInstances: {},
    tableView: 'nodes',
  };

  const dispatch = useDispatch();
  const location = useLocation();

  const nodeType = location.pathname.match(/\/graph(\/(.*))?/)[2];
  const params = new URLSearchParams(location.search);
  const title = params.get('title') || 'Knowledge Graph';

  if (nodeType) {
    initialValue.selectedNodes = [nodeType];
    initialValue.layout = 'table';
    initialValue.pageSize = 6;
  }

  const [selectedSession, setSelectedSession] = useState(null);
  const [value, setValue] = useState(initialValue);

  const { setNavbarState } = useContext(NavbarContext);
  const { currentUser } = useContext(UserContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const correlationIdRef = useRef(null);
  const initialized = useRef(false);

  const graphLoading = useSelector(selectGraphLoading);
  const graphs = useSelector(selectGraphs);
  const nodeOptions = useSelector(selectNodeOptions);
  const nodesMetadata = useSelector(selectNodesMetadata);
  const ontologiesLoaded = useSelector(selectOntologiesLoaded);
  const ontologiesLoading = useSelector(selectOntologiesLoading);
  const ontologies = useSelector(selectOntologies);
  const relationshipsMetadata = useSelector(selectRelationshipsMetadata);
  const sessionsLoaded = useSelector(selectSessionsLoaded);
  const sessionsLoading = useSelector(selectSessionsLoading);
  const sessions = useSelector(selectGraphSessions);

  const ontologyOptions = useMemo(() => {
    const list = Object.values(ontologies).map(o => ({
      label: o.domain,
      value: o.id,
    }));
    list.sort((a, b) => a.label < b.label ? -1 : 1);
    return list;
  }, [ontologies]);

  const sessionData = useMemo(() => {
    const list = Object.values(sessions).map(s => ({
      key: s.id,
      name: s.name,
    }));
    list.sort((a, b) => a.modified > b.modified ? -1 : 1);
    return list;
  }, [sessions]);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title,
    }));
  }, []);

  useEffect(() => {
    if (nodeType && selectedWorkspace && Object.keys(ontologies).length) {
      let ontology = selectedWorkspace.defaultOntology;
      if (!ontology) {
        ontology = Object.values(ontologies)[0].id;
      }
      setValue(cur => ({ ...cur, ontology }));
    }
  }, [ontologies, selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getNodeOptionsAsync({ workspaceId }));
      dispatch(getOntologiesAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace && currentUser?.email) {
      dispatch(getGraphSessionsAsync({
        type: 'graph',
        username: currentUser.email,
        workspaceId: selectedWorkspace.id,
      }));
    }
  }, [selectedWorkspace, currentUser]);

  useEffect(() => {
    if (value.ontology) {
      const workspaceId = selectedWorkspace.id;
      console.log('workspaceId:', workspaceId);
      console.log('value.ontology:', value.ontology);
      console.log('ontologies:', ontologies);
      const domain = ontologies[value.ontology].domain;
      dispatch(getNodesMetadataAsync({ workspaceId, domain }));
      dispatch(getRelationshipsMetadataAsync({ workspaceId, domain }));
      const correlationId = uuidv4();
      let req;
      if (value.selectedNodes) {
        req = { ...value, selectedTypes: value.selectedNodes, connected: true };
      } else if (value.selectedTypes) {
        const selectedTypes = Object.entries(value.selectedTypes)
          .filter(([_, v]) => v)
          .map(([k, _]) => k);

        req = { ...value, selectedTypes };
      } else {
        req = value;
      }
      if (nodeType) {
        req = { ...value, selectedTypes: [nodeType], sort: 'code', connected: true };
      }
      dispatch(runGraphQuery({
        ...pick(req, includeFields),
        correlationId,
        domain,
        workspaceId,
      }));
      correlationIdRef.current = correlationId;
      setValue(cur => ({ ...cur, ...value }));
    }
  }, [value.ontology]);

  useEffect(() => {
    const graph = graphs[correlationIdRef.current];
    if (graph) {
      let g;
      let selectedTypes;

      if (value.selectedTypes) {
        const types = getSelectedTypes(graph, value.selectedTypes);
        g = filterGraph(graph, types);
        selectedTypes = types;
      } else {
        g = graph;
        selectedTypes = Object.keys(graph.legend).reduce((a, k) => {
          a[k] = true;
          return a;
        }, {});
      }

      const nodes = [];
      for (const node of g.nodes) {
        if (value.nodeStyle === 'icon') {
          if (node.icon) {
            nodes.push({ ...node, icon: <MyImage type={node.icon} /> });
          } else if (node.type === 'Document') {
            nodes.push({ ...node, icon: <MyImage type="document" /> });
          } else if (node.type === 'Chunk') {
            nodes.push({ ...node, icon: <MyImage type="chunk" /> });
          } else {
            nodes.push({ ...node, icon: null });
          }
        } else {
          nodes.push({ ...node, icon: null });
        }
      }

      g = { ...g, nodes };

      let dataSource, defaultColumns;
      if (value.tableView === 'nodes') {
        const isAdjacency = value.tableType === 'adjacency-matrix';
        dataSource = getDataSource(g, isAdjacency);
        defaultColumns = getDefaultColumns(g, isAdjacency);
      } else {
        dataSource = getEdges(g);
        defaultColumns = getEdgeMappingColumns(g, value.tableParams);
      }

      const legend = Object.entries(graph.legend).map(([k, v]) => {
        const icon = value.nodeStyle === 'icon' ? v.icon : null;
        return [k, v.color, icon];
      });

      setValue(cur => ({
        ...cur,
        dataSource,
        defaultColumns,
        graph: g,
        legend,
        selectedTypes,
        total: graph.graphSize,
      }));
    }
  }, [graphs]);

  useEffect(() => {
    if (!nodeType && sessionsLoaded && !initialized.current) {
      initialized.current = true;
      const session = Object.values(sessions).find(s => s.name === LAST_GRAPH_SESSION);
      if (session) {
        setSelectedSession(session);
        handleChange(session);
      }
    }
  }, [sessionsLoaded]);

  const handleChange = (changes) => {
    const workspaceId = selectedWorkspace.id;
    const domain = changes.domain || ontologies[value.ontology]?.domain;

    let updates = { ...changes };
    if (!('page' in changes)) {
      updates.page = 1;
    }
    if ('domain' in changes) {
      const ontology = Object.values(ontologies).find(o => o.domain === changes.domain);
      if (ontology) {
        updates.ontology = ontology.id;
      }
    }
    if ('selectAll' in changes) {
      let selectedTypes;
      if (changes.selectAll) {
        selectedTypes = value.legend.reduce((a, [label, _]) => {
          a[label] = true;
          return a;
        }, {});
      } else {
        selectedTypes = value.legend.reduce((a, [label, _]) => {
          a[label] = false;
          return a;
        }, {});
      }
      updates.selectedTypes = selectedTypes;
    }
    if ('layout' in changes) {
      updates.page = 1;
      updates.pageSize = changes.layout === 'graph' ? 500 : 6;
    }

    let req = { ...value, ...updates };
    if (req.selectedNodes) {
      req = { ...req, selectedTypes: req.selectedNodes, connected: true };
    } else if (req.selectedTypes) {
      const selectedTypes = Object.entries(req.selectedTypes)
        .filter(([_, v]) => v)
        .map(([k, _]) => k);

      req = { ...req, selectedTypes };
    }
    // if ('query' in changes) {
    //   req.tags = changes.query.state.tags;
    // }

    if (nodeType) {
      req = { ...req, sort: 'code', connected: true };
    }

    if (domain) {
      const correlationId = uuidv4();
      dispatch(runGraphQuery({
        ...pick(req, includeFields),
        correlationId,
        domain,
        workspaceId,
      }));
      correlationIdRef.current = correlationId;
    }
    setValue({ ...value, ...updates });
  };

  const handleCreateEdge = ({ relType, source, target }) => {
    dispatch(createRelationshipAsync({
      correlationId: correlationIdRef.current,
      relationship: {
        type: relType,
        source,
        target,
        inferred: false,
      },
      workspaceId: selectedWorkspace.id,
    }))
  };

  const handleCreateSession = (values) => {
    dispatch(createGraphSessionAsync({
      values: {
        ...values,
        options: omit(value, excludeFields),
        type: 'graph',
        workspaceId: selectedWorkspace.id,
      },
    }));
  };

  const handleDeleteEdge = ({ relType, id }) => {
    dispatch(deleteRelationshipAsync({
      correlationId: correlationIdRef.current,
      type: relType,
      id,
      workspaceId: selectedWorkspace.id,
    }))
  };

  const handleDeleteNode = (values) => {
    dispatch(deleteNodeAsync({
      correlationId: correlationIdRef.current,
      id: values.id,
      type: values.type,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const handleDeleteSession = (ids) => {
    dispatch(deleteGraphSessionsAsync({ ids }));
  };

  const handleExpandNode = (values) => {
    dispatch(getGraphFromNodeAsync({
      ...values,
      append: true,
      correlationId: correlationIdRef.current,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const handleMergeNodes = (values) => {
    const [_, fill, icon] = value.legend.find(l => l[0] === values.nodeType);
    dispatch(mergeNodesAsync({
      ...values,
      correlationId: correlationIdRef.current,
      fill,
      icon,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const handleReset = () => {
    handleChange({
      ...initialValue,
      ontology: value.ontology,
      selectedTypes: null,
    });
  };

  const handleSelectSession = (id) => {
    const session = sessions[id];
    if (session) {
      setSelectedSession(session);
      handleChange(session);
    }
  };

  const handleSaveNode = ({ values, anchorNode, direction, relType }) => {
    const node = {
      ...values,
      id: values.id || md5(`${values.type}:${values.name.toLowerCase()}`),
      inferred: false,
    };

    // TODO value.legend has only the current node set
    const [_, fill, icon] = value.legend.find(l => l[0] === values.type) || [null, null, null];

    dispatch(createNodeAsync({
      correlationId: correlationIdRef.current,
      fill,
      icon,
      node,
      anchorNode,
      direction,
      relType,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const breadcrumbs = [];
  if (value.selectedNodes?.length) {
    breadcrumbs.push(pluralize(hyphenated(value.selectedNodes[0])));
  }

  if (!ontologiesLoaded) {
    return <div>Loading...</div>
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: <Link to={'/'}>Home</Link>,
          },
          ...breadcrumbs.map(title => ({ title })),
        ]}
        style={{ marginBottom: 16 }}
      />
      <GraphView2
        graphLoading={graphLoading}
        nodeOptions={nodeOptions}
        nodesMetadata={nodesMetadata}
        relationshipsMetadata={relationshipsMetadata}
        onChange={handleChange}
        value={value}
        onCreateEdge={handleCreateEdge}
        onCreateSession={handleCreateSession}
        onDeleteEdge={handleDeleteEdge}
        onDeleteNode={handleDeleteNode}
        onDeleteSession={handleDeleteSession}
        onExpandNode={handleExpandNode}
        onMergeNodes={handleMergeNodes}
        onReset={handleReset}
        onSaveNode={handleSaveNode}
        onSelectSession={handleSelectSession}
        ontologiesLoading={ontologiesLoading}
        ontologyOptions={ontologyOptions}
        selectedOntology={ontologies[value.ontology]}
        selectedSession={selectedSession}
        sessionsLoading={sessionsLoading}
        sessionData={sessionData}
        nodeType={nodeType}
        title={title}
      />
    </div>
  );
}