import { useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space, Upload, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  getOntologiesAsync,
  selectLoading as selectOntologiesLoading,
  selectOntologies,
} from '../ontology/ontologiesSlice';

import {
  createMetricAsync,
  fileUploadAsync,
  getMetricAsync,
  selectMetrics,
  selectLoaded,
  selectUploading,
  updateMetricAsync,
} from './metricsSlice';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

export function MetricForm() {

  const loaded = useSelector(selectLoaded);
  const metrics = useSelector(selectMetrics);
  const ontologiesLoading = useSelector(selectOntologiesLoading);
  const ontologies = useSelector(selectOntologies);
  const uploading = useSelector(selectUploading);

  const [form] = Form.useForm();
  const domainValue = Form.useWatch('domain', form);

  const correlationIdRef = useRef();
  const taskDoneCallbackRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  const id = location.pathname.match(/\/metrics\/(.*)/)[1];
  const isNew = id === 'new';
  const metric = metrics[id];

  const domainOptions = useMemo(() => {
    const list = Object.values(ontologies).map(o => ({
      label: o.domain,
      value: o.id,
    }));
    list.sort((a, b) => a.label < b.label ? -1 : 1);
    return list;
  }, [ontologies]);

  const entityOptions = useMemo(() => {
    let ontology;
    if (domainValue && (ontology = ontologies[domainValue])) {
      const list = (ontology?.nodes || []).map(n => ({
        label: n.data.type,
        value: n.id,
      }));
      list.sort((a, b) => a.label < b.label ? -1 : 1);
      return list;
    }
    return [];
  }, [domainValue, ontologies]);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Metric',
    }));
    if (!isNew) {
      dispatch(getMetricAsync(id));
    }
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getOntologiesAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (correlationIdRef.current) {
      const metric = Object.values(metrics).find(a => a.correlationId === correlationIdRef.current);
      if (metric) {
        if (taskDoneCallbackRef.current) {
          taskDoneCallbackRef.current();
          taskDoneCallbackRef.current = null;
        }
        correlationIdRef.current = null;
      }
    }
  }, [metrics]);

  useEffect(() => {
    if (location.state && location.state.message) {
      messageApi.info({
        content: location.state.message,
        duration: 5,
      });
    }
  }, [location]);

  const returnToList = () => {
    navigate('/metrics');
  };

  const onFinish = (values) => {
    const correlationId = uuidv4()
    if (isNew) {
      dispatch(createMetricAsync({
        correlationId,
        values: { ...values, workspaceId: selectedWorkspace.id },
      }));
    } else {
      dispatch(updateMetricAsync({ correlationId, id, values }));
    }
    correlationIdRef.current = correlationId;
    taskDoneCallbackRef.current = returnToList;
  };

  const onUpload = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      const correlationId = uuidv4();
      dispatch(fileUploadAsync({
        correlationId,
        file: info.file,
        workspaceId: selectedWorkspace.id,
      }));
    }
  };

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Upload
            name="upload"
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={onUpload}
          >
            <Button
              type="text" loading={uploading}
            >
              Import
            </Button>
          </Upload>
        </div>
        <div style={{ marginTop: 20 }}>
          <Form
            form={form}
            {...layout}
            autoComplete="off"
            onFinish={onFinish}
            initialValues={metric}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter a metric name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
            >
              <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
            </Form.Item>
            <Form.Item
              label="Domain"
              name="domain"
              rules={[
                {
                  required: true,
                  message: "Please select the domain",
                },
              ]}
            >
              <Select allowClear
                loading={ontologiesLoading}
                options={domainOptions}
                optionFilterProp="label"
                placeholder="Select domain"
              />
            </Form.Item>
            <Form.Item
              label="Entity"
              name="entity"
              rules={[
                {
                  required: true,
                  message: "Please select the entity. You must first select the domain.",
                },
              ]}
            >
              <Select allowClear
                options={entityOptions}
                optionFilterProp="label"
                placeholder="Select entity"
              />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select the type",
                },
              ]}
            >
              <Select allowClear
                options={[
                  {
                    label: 'Cypher',
                    value: 'cypher',
                  },
                  {
                    label: 'Derived',
                    value: 'derived',
                  },
                ]}
                optionFilterProp="label"
                placeholder="Select type"
              />
            </Form.Item>
            <Form.Item
              label="Aggregation Type"
              name="aggregateType"
              rules={[
                {
                  required: true,
                  message: "Please select the aggregation type",
                },
              ]}
            >
              <Select allowClear
                options={[
                  {
                    label: 'INT64',
                    value: 'INT64',
                  },
                  {
                    label: 'STRING',
                    value: 'STRING',
                  },
                  {
                    label: 'BOOLEAN',
                    value: 'BOOLEAN',
                  },
                ]}
                optionFilterProp="label"
                placeholder="Select type"
              />
            </Form.Item>
            <Form.Item
              label="Definition"
              name="definition"
              rules={[
                {
                  required: true,
                  message: "Please enter the definition",
                },
              ]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 14 }} />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
              <Space>
                <Button type="default" onClick={returnToList}>Cancel</Button>
                <Button type="primary" htmlType="submit">Save</Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

const beforeUpload = (file) => {
  // console.log('file:', file);

  const isYAML = file.type === 'text/yaml';

  if (!isYAML) {
    message.error('You may only upload a YAML file.');
  }

  const isLt2M = file.size / 1024 / 1024 < 100;

  if (!isLt2M) {
    message.error('File must be smaller than 100MB.');
  }

  return isYAML && isLt2M;
};

// https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 20);
};
