import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    loaded: false,
    loading: false,
    messages: [],
    previousQuery: null,
    questions: [],
    quizLoading: false,
    quizLoaded: false,
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload.messages;
      state.loaded = true;
      state.loading = false;
    },
    setPreviousQuery: (state, action) => {
      state.previousQuery = action.payload.previousQuery;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload.questions;
      state.quizLoaded = true;
      state.quizLoading = false;
    },
    startLoad: (state, action) => {
      state.loaded = false;
      state.loading = true;
    },
    startQuizLoad: (state, action) => {
      state.quizLoaded = false;
      state.quizLoading = true;
    },
  }
});

export const {
  setMessages,
  setPreviousQuery,
  setQuestions,
  startLoad,
  startQuizLoad,
} = chatSlice.actions;

// TODO - do server-side
const cleanHistory = (messages) => {
  return messages
    .map(m => {
      if (!m.content || m.content === 'undefined') {
        return null;
      }
      if (Array.isArray(m.content)) {
        if (!m.content[0].content) {
          return null;
        }
        return {
          role: m.role,
          content: String(m.content[0].content),
        };
      }
      return {
        role: m.role,
        content: String(m.content),
      };
    })
    .filter(v => v);
}

export const queryAsync = ({ messages, q, workspaceId, domain }) => async (dispatch, getState) => {
  dispatch(startLoad());
  const url = '/api/graph/query';
  let history;
  if (messages.length > 1) {
    history = cleanHistory(messages.slice(0, -1));
  }
  const previousQuery = getState().chat.previousQuery;
  const res = await http.post(url, { q, history, previousQuery, workspaceId, domain });
  const { query, message, system_messages } = res.data;
  const msgs = [...(system_messages || []), ...messages, message]
  dispatch(setPreviousQuery({ previousQuery: query }));
  dispatch(setMessages({ messages: msgs }));
};

export const getTutorResponseAsync = ({ messages, ...rest }) => async (dispatch, getState) => {
  dispatch(startLoad());
  const url = '/api/documents/tutor';
  let history;
  if (messages.length > 1) {
    // history = cleanHistory(messages.slice(0, -1));
    history = messages.slice(0, -1);
  }
  const previousQuery = getState().chat.previousQuery;
  const res = await http.post(url, { ...rest, history });
  const msgs = [...messages, ...res.data.messages];
  dispatch(setPreviousQuery({ previousQuery: res.data.query }));
  dispatch(setMessages({ messages: msgs }));
};

export const getQuizAsync = ({
  messages,
  minNumberQuestions,
  maxNumberQuestions,
  allowedTopics,
  workspaceId,
}) => async (dispatch) => {
  // console.log('messages:', messages);
  // console.log('minNumberQuestions:', minNumberQuestions);
  // console.log('maxNumberQuestions:', maxNumberQuestions);
  // console.log('allowedTopics:', allowedTopics);
  // console.log('workspaceId:', workspaceId);
  dispatch(startQuizLoad());
  const url = '/api/pq/quiz';
  let history;
  if (messages.length > 1) {
    history = cleanHistory(messages);
  }
  const res = await http.post(url, {
    history,
    minNumberQuestions,
    maxNumberQuestions,
    allowedTopics,
    workspaceId,
  });
  dispatch(setQuestions({ questions: res.data['questions'] }));
};

export const selectLoaded = (state) => state.chat.loaded;

export const selectLoading = (state) => state.chat.loading;

export const selectMessages = (state) => state.chat.messages;

export const selectPreviousQuery = (state) => state.chat.previousQuery;

export const selectQuestions = (state) => state.chat.questions;

export const selectQuizLoading = (state) => state.chat.quizLoading;

export default chatSlice.reducer;
